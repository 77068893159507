import React from 'react';

import { Box, Container , Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import ContactUsForm from './ContactUsForm';

import HomeCarousel from '../Home/HomeCarousel';
import HomeLocateUs from '../Home/HomeLocateUs';

const NewContactUs = () => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Box>
            <HomeCarousel data={[]} />
            <Container className="app-container" maxWidth="lg">
                <Box style={{ margin: 20 }}>
                    <p style={{ textAlign: "center", fontSize: 16, color: theme.palette.primary.main }}>
                        ENQUIRY
                    </p>
                    <Typography variant="h5" className={classes.titleStyle}>
                        How can we help you?
                    </Typography>
                    <p className={classes.textStyle}>
                        If you have any questions or comments, please feel free to share them here. We genuinely appreciate your feedback, and we will try our best to get back to you within 48 hours. Thank you!
                    </p>
                </Box>
                <Box>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Contact Us Form
                    </Typography>
                </Box>
                <ContactUsForm />
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}

export default NewContactUs

const useStyles = makeStyles((theme) => ({
    textStyle: {
        marginBottom: 20,
        textAlign: "center",
        fontSize: 24
    },
    titleStyle: {
        textTransform: "capitalize",
        fontFamily:'OregonLdo !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
}))