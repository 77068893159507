import React, { useState, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Button, Dialog, TextField, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { postUrl, getUrl } from '@helpers/api';
import { useToasts } from 'react-toast-notifications';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';

const HomeNewsletter = () => {
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [announcementState, setAnnouncementState] = useState({
        announcements: [],
    });
    const userData = useSelector(state => state.user);
    const isLogin = useSelector(state => state.general);

    useEffect(() => {
        getMemberAnnouncements();
    }, [isLogin.isLogin]);

    const getMemberAnnouncements = () => {
        {
            isLogin.isLogin == true ?
            getUrl(`/member-announcements`).then(result => {
                if (result.status) {
                    setAnnouncementState({
                        ...announcementState,
                        announcements: result.data,
                    })
                }
            }).catch((error) => {
                addToast('', error.message || t('error.contactSupport'), 'error', '');
            })
            : getUrl(`/public-announcements`).then(result => {
                if (result.status) {
                    setAnnouncementState({
                        ...announcementState,
                        announcements: result.data,
                    })
                }
            }).catch((error) => {
                addToast('', error.message || t('error.contactSupport'), 'error', '');
            })
        }
    }

    return (
        <div>
            {_.size(announcementState) && _.map(announcementState.announcements, (row, index) => (
                <PopupModal key={index} data={row} isLogin={isLogin} userData={userData}/>
            ))}
        </div>
    )
};
export default HomeNewsletter;

const PopupModal = memo((props) => {
    const classes = useStyles();
    // const [state, setState] = useState({
    //     email: '',
    // });
    // const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const { addToast } = useToasts();
    const navigate = useNavigate();
    const [state] = useState({
        user_id: props.userData.id,
    });
    const onCloseModal = () => {
        setOpen(false);
        updateAnnouncementRead()
    };
    // hardcode english ONLY
    i18n.language = 'en';

    const updateAnnouncementRead = () => {
        setLoading(true);
        {
            props.isLogin.isLogin == true ?
            getUrl(`/member-announcements/${props.data.id}`, state).then(result => {
                setLoading(false);
            }).catch((error) => {
                setLoading(false);
                addToast('', error.message || t('error.contactSupport'), 'error', '')
            })
            : setLoading(false);
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(true);
        }, 3000); // 3second
        return () => clearTimeout(timer);
    }, []);

    // const handleChange = ({ target }) => {
    //     let { name, value } = target;
    //     setState({ ...state, [name]: value });
    // };

    const submitData = () => {
        setLoading(true);
        {
            props.isLogin.isLogin == true ?
            // setInputErrors({});
            postUrl(`/member-announcements/subscribe-newsletter/${props.data.id}`, state).then(response => {
                // if(response.status) {
                //     setInputErrors({});
                // } else {
                //     setInputErrors(response.errors);
                // }
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
                if (response.status) {
                    onCloseModal();
                }
            }).catch(err => {
                setLoading(false);
                addToast(t('general.error'), { appearance: 'error' });
                // navigate('/sign-in');
            })
            :
            navigate('/sign-in');
        }
    }

    return (
        <Dialog
            open={open}
            onClose={onCloseModal}
            fullWidth={true}
            maxWidth={'md'}
        >
            <Box display="flex" flexDirection="row">
                <Grid container direction="column" >
                    {props.data.image ?
                        <img src={props.data.image.file_name} className={classes.imageStyle} alt="announcement" />
                        : null
                    }
                </Grid>
                <Grid container direction="column">
                    <div className={classes.headerPaddingStyle}>
                        <CloseIcon className={classes.subtitleStyle} style={{ position: 'absolute', right: 5, cursor: 'pointer' }} onClick={() => onCloseModal()} />
                        <Typography variant="body1" component="p" className={classes.titleStyle} >
                            {props.data.title[i18n.language] ? props.data.title[i18n.language] : ""}
                        </Typography>
                        <Typography variant="body1" component="span" className={classes.subtitleStyle} >
                            {props.data.content[i18n.language] ?
                                <div dangerouslySetInnerHTML={{ __html: props.data.content[i18n.language] }} />
                                : null
                            }
                        </Typography>
                    </div>
                    <Grid>
                        <div /*className={classes.formStyle}*/>
                            {/* <TextField
                                fullWidth
                                variant="standard"
                                name="email"
                                type="email"
                                autoComplete="email"
                                defaultValue="Email"
                                onChange={handleChange}
                                error={inputErrors && inputErrors.email ? true : false}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                FormHelperTextProps={{ className: classes.input }}
                                inputProps={{style: { textAlign: 'center' }}}
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.input
                                }}     
                                className={classes.textField}           
                            /> */}
                            {props.data.ntype === 'newsletter' ?
                                <Button size="small" variant="contained" className={classes.roundedButtonStyle} onClick={() => { submitData() }} fullWidth>
                                    {
                                        loading ?
                                            <CircularProgress size={20} style={{ color: 'white' }} />
                                            :
                                            <Typography className={classes.buttonTextStyle} >{t('general.subscribe')}</Typography>
                                    }
                                </Button>
                                : ''
                            }
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
});

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "uppercase",
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem !important',
            paddingTop: '5px',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
            paddingTop: '20px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem !important',
            paddingTop: '20px',
        },
    },
    subtitleStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.4rem !important',
        },
    },
    headerPaddingStyle: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '5px !important',
            paddingRight: '5px !important',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '10px !important',
            paddingRight: '20px !important',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '10px !important',
            paddingRight: '20px !important',
        },
    },
    formStyle: {
        [theme.breakpoints.up('xs')]: {
            paddingTop: '5px !important',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '50px !important',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '50px !important',

        },
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    imageStyle: {
        width: '95%',
        objectFit: 'cover',
        maxWidth: '95%',
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        color: '#353535',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '5px !important',
        cursor: 'pointer',
        maxWidth: '80%',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
        [theme.breakpoints.up('xs')]: {
            marginTop: "10px !important",
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: "50px !important",
            marginBottom: "10px !important",
        },
    },
    // textField: {
    //     color: '#00ff7f',
    //     borderRadius: '5px !important',
    //     background: '#E0E0E0',
    //     maxWidth: '80%',
    //     [theme.breakpoints.up('xs')]: {
    //         fontSize: '0.5rem !important',
    //     },
    //     [theme.breakpoints.up('sm')]: {
    //         fontSize: '1rem !important',
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         fontSize: '1.4rem !important',
    //     },
    // },
    // input: {
    //     color: '#00ff7f',
    //     borderRadius: '5px !important',
    //     background: '#E0E0E0',
    //     [theme.breakpoints.up('xs')]: {
    //         fontSize: '0.5rem !important',
    //     },
    //     [theme.breakpoints.up('sm')]: {
    //         fontSize: '1rem !important',
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         fontSize: '1.3rem !important',
    //     },
    // }
}))