import React, { memo } from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import _ from 'lodash';

const LocateUsInfo = memo(({ data }) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" alignItems="center" marginTop={4}>
            {
                _.size(data.text) ?
                    <>
                        <div dangerouslySetInnerHTML={{ __html: data.text }} />
                    </>
                    :
                    <>
                        <div className={classes.containerStyle}>
                            <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                                <div className={classes.infoStyle}>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle} style={{ fontWeight: 'bold' }}>BlackBixon Cafe</Typography>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>Owned By </Typography>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>BLACKBIXON2GO SDN. BHD. </Typography>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>202101010309 (1410608-V)</Typography>
                                    <Typography variant="body1" component="p" className={classes.timeStyle}>17&17A, Jalan Inai, </Typography>
                                    <Typography variant="body1" component="p" className={classes.timeStyle}>Off Jalan Imbi, 55100, </Typography>
                                    <Typography variant="body1" component="p" className={classes.timeStyle}>Kuala Lumpur, Malaysia  </Typography>
                                </div>
                                <div className={classes.infoStyle}>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>Open Daily</Typography>
                                    <Typography variant="h6" className={classes.timeStyle}>8AM - 10PM</Typography>
                                </div>
                                <div className={classes.infoStyle}>
                                    {/* <Link rel="noopener noreferrer" href="https://wa.me/60173950321" target="_blank" underline="none"> */}
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>017-395 0321 - Call/Reservation</Typography>
                                    <Typography variant="body1" component="p" className={classes.subtitleStyle}>blackbixoncafe@gmail.com</Typography>
                                    {/* </Link> */}
                                </div>
                            </Box>
                        </div>
                    </>
            }
        </Box>
    )
});

export default LocateUsInfo;

const useStyles = makeStyles((theme) => ({
    subtitleStyle: {
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
        },
    },
    timeStyle: {
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.1rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.4rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.9rem !important',
        },
    },
    imageContainerStyle: {
        flex: 1,
        padding: 10
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center'
    },
    imageStyle: {
        width: '100%',
        objectFit: 'contain',
        maxWidth: 400,
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    }
}))