import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import NumberFormat from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartStepper from '@components/CartStepper';

import { getUrl } from '@helpers/api';
import _ from 'lodash';

// MUI
import { Box, Button, Hidden, Typography, LinearProgress, Link } from '@mui/material';

import CartItem from './CartItem';

export default function CartList() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [checkout, setCheckout] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const [cartList, setCartList] = useState({});
    const [sumTotal, setSumTotal] = useState(0);
    const { accessToken} = useSelector(state => state.general);

    useEffect(() => {
        refreshCheckoutButton(cartList);
        // eslint-disable-next-line
    }, [cartList]);


    useEffect(() => {
        getApi();
        // eslint-disable-next-line
    }, [accessToken]);

    const getApi = () => {
        setLoading(true);
        getUrl(`/carts`, { store_id: 1 }).then(response => {
            setLoading(false);
            if (response.status) {
                setCartList(response.data.listing);
                // setPaymentMethodList(response.data.payment_method);
                calculateSumTotal(response.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addToast(JSON.stringify(error), { appearance: 'error' });
        });
    }

    const calculateSumTotal = (listing) => {
        let sum = 0;
        let sub_price = 0;
        if (_.size(listing) > 0) {
            _.map(listing, list => {
                sub_price = 0;
                if (_.size(list.product_options) > 0) {
                    _.map(list.product_options, main => {
                        let main_option = _.find(list.product.product_options, { id: parseInt(main.id) });
                        if (_.size(main.option) > 0 && main_option) {
                            _.map(main.option, child => {
                                let child_option = _.find(list.product.options[main_option.option_code], { id: parseInt(child) });
                                if (parseFloat(child_option.price) && child_option) {
                                    sub_price = sub_price + ((parseFloat(child_option.price) + parseFloat(list.product.price)) * parseFloat(list.quantity));
                                }
                            })
                        }
                    })
                } else {
                    sub_price = sub_price + ((parseFloat(list.product.price)) * parseFloat(list.quantity));
                }

                sum += sub_price;
            })
        }
        setSumTotal(parseFloat(sum).toFixed(2));
    }

    const refreshCheckoutButton = (listing) => {
        let sum = 0;
        let sub_price = 0;
        let valid = false;
        if (_.size(listing) > 0) {
            _.map(listing, list => {
                sub_price = 0;
                if (list?.product?.status === 1 && list?.product?.quantity > 0) {
                    if (_.size(list.product_options) > 0) {
                        _.map(list.product_options, main => {
                            let main_option = _.find(list.product.product_options, { id: parseInt(main.id) });
                            if (_.size(main.option) > 0 && main_option) {
                                _.map(main.option, child => {
                                    let child_option = _.find(list.product.options[main_option.option_code], { id: parseInt(child) });
                                    if (parseFloat(child_option.price) && child_option) {
                                        sub_price = sub_price + ((parseFloat(child_option.price) + parseFloat(list.product.price)) * parseFloat(list.quantity));
                                    }
                                })
                            }
                        })
                    } else {
                        sub_price = sub_price + ((parseFloat(list.product.price)) * parseFloat(list.quantity));
                    }
                    sum += sub_price;
                }
            })
        }

        // console.log("sum checkout", sum);
        // console.log("cart total", sumTotal);
        if (parseFloat(sum).toFixed(2) === parseFloat(sumTotal).toFixed(2)) {
            valid = true
        }
        setCheckout(valid);
    }

    return (
        <Box sx={{ width: '100%' }}>
            {/* PRODUCT ORDER */}
            <div style={{ padding: 20 }}>
                {/* HEADER AND DRAWER */}
                <div className={`head ${classes.flexMiddle} ${classes.spaceBetween}`} >
                    <Typography variant="h2" className={classes.titleStyle}>{t('title.cart')}</Typography>
                </div>
                <div>
                    {/* CART STEPPER */}
                    <CartStepper activeStep={0} />
                </div>
                {
                    loading ?
                        <LinearProgress />
                        :
                        <>
                            {
                                _.size(cartList) ?
                                    <>
                                        {/* LISTING */}
                                        <div style={{ paddingTop: 25 }}>
                                            {
                                                _.map(cartList, cartItem => {
                                                    return (
                                                        <div key={cartItem.id} >
                                                            <CartItem data={cartItem} reloadCart={() => getApi()} />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {/* SUM TOTAL */}
                                        <Hidden only={'xs'}>
                                            <div style={{ paddingTop: 25 }} className={`head ${classes.flexMiddle} ${classes.spaceBetween}`}>

                                                <Typography style={{ color: theme.palette.secondary.main }}>
                                                    <span style={{ color: theme.palette.gray.ltext, paddingRight: 15 }}>{t('product.totalPrice') + ': '}</span>
                                                    <b><NumberFormat thousandSeparator={true} prefix="RM " value={sumTotal} displayType="text" /></b>
                                                </Typography>
                                            </div>
                                        </Hidden>
                                        <Hidden smUp>
                                            <div className={`head ${classes.flexMiddle} ${classes.spaceBetween}`} style={{ padding: '40px 0 50px' }}>
                                                <Typography style={{ color: theme.palette.gray.ltext }}>{t('product.totalPrice') + ': '}</Typography>
                                                <Typography style={{ color: theme.palette.secondary.main }}>
                                                    <b><NumberFormat thousandSeparator={true} prefix="RM " value={sumTotal} displayType="text" /></b>
                                                </Typography>
                                            </div>
                                        </Hidden>
                                        {/* BUTTON */}
                                        {
                                            checkout &&
                                            <div className={`head ${classes.flexMiddle} ${classes.spaceBetween}`}>
                                                <div style={{ margin: '25px auto' }} >
                                                    <Link component={RouterLink} to="/checkout" underline="none">
                                                        <Button variant="contained" className={classes.roundedButtonStyle}  >
                                                            <Typography className={classes.buttonTextStyle} style={{ paddingLeft: 10, paddingRight: 10 }}>{t('general.checkout')}</Typography>
                                                        </Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        }
                                    </>
                                    :
                                    <div style={{ display: 'grid', justifyContent: 'center' }}>
                                        <Typography style={{ margin: 10, textAlign: "flex-start", }} className={classes.subTitleStyle}>{t('error.noCart')}</Typography>
                                        <div className={`head ${classes.flexMiddle} ${classes.justifyCenter}`}>
                                            <Link component={RouterLink} to="/menu" underline="none">
                                                <Button variant="contained" className={classes.roundedButtonStyle}>
                                                    <Typography className={classes.buttonTextStyle}>Go to menu</Typography>
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                            }
                        </>
                }
            </div>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    spaceBetween: {
        justifyContent: 'space-between'
    },
    justifyCenter: {
        justifyContent: 'center'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    flexBottom: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    titleStyle: {
        textTransform: "capitalize",
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
    subTitleStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem !important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        cursor: 'pointer',
        [theme.breakpoints.up('xs')]: {
            maxWidth: 120,
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 200,
            marginTop: "10px !important",
            marginBottom: "10px !important",
        },
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    priceStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.45rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
        marginRight: '20px !important'
    },
    totalPriceStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85 rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.95rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
}));