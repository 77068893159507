import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation } from 'react-i18next';

import { Backdrop, Box, CircularProgress, Dialog, DialogActions, Button, DialogContent, DialogContentText, DialogTitle, Typography, Container } from '@mui/material';

import CartStepper from '@components/CartStepper';

const PaymentCallback = () => {
    const classes = useStyles();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    let { payment_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const isMountedRef = useRef(null);
    const [dialog, setDialog] = useState({
        open: false,
        content: "",
        successPayment: false,
    });
    const navigate = useNavigate();
    const { t } = useTranslation();
    const source = searchParams.get("source");

    useEffect(() => {
        isMountedRef.current = true;
        if (isMountedRef) {
            if (payment_id && source) {
                setLoading(true);
                getUrl(`/validate/payment/${payment_id}/${source}`).then(response => {
                    setLoading(false);
                    if (response.status) {
                        let status = response.payment_status === 40 ? 'success' : (response.payment_status === 20 ? 'pending' : 'fail');
                        setDialog({
                            open: true,
                            content: t('payment.' + status + 'Content') + (response.order_tracking_message),
                            title: t('payment.' + status),
                            successPayment: status === 'fail' ? false : true,
                        });
                    } else {
                        addToast(response.message, { appearance: 'error' });
                    }
                }).catch(err => {
                    setLoading(false);
                    addToast(JSON.stringify(err), { appearance: 'error' });
                })
            }
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [payment_id, source]);

    const handleOkButtonClick = () => {
        navigate(`/order`, { replace: true });
    }


    return (
        <Box className={classes.paper}>
            <Container maxWidth="lg">
                {/* CART STEPPER */}
                <CartStepper activeStep={2} />
                <div style={{ display: 'grid', justifyContent: 'center' , marginTop:'20px' }}>
                    <Typography>Loading ....</Typography>
                </div>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Dialog open={dialog.open}>
                    <DialogTitle>{dialog.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{dialog.content}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button className={classes.buttonStyle} onClick={handleOkButtonClick}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    )
}

export default PaymentCallback;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    }
}))