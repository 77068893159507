import React from 'react';
import { Box, Container } from '@mui/material';
import PolicyInfo from './PolicyInfo';

const index = () => {
    return (
        <Box>
            <Container className="app-container" maxWidth="lg">
                <PolicyInfo />
            </Container>
        </Box>
    )
}
export default index