import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import _ from 'lodash'; 

import { Alert, Link, Container, Card, CardContent, Grid, Box, Button, TextField, CircularProgress, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useToasts } from 'react-toast-notifications';

import { postUrl } from '@helpers/api';

const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [passwordField, setPasswordField] = useState({
        email: "",
        password: "",
        password_confirmation: "",
    })

    const [searchParams, setSearchParams] = useSearchParams();
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const handleChange = (event) => {
        setPasswordField({ ...passwordField, [event.target.name]: event.target.value });
    };

    const resetPasswordClicked = () => {
        if(passwordField.password !== passwordField.password_confirmation) {
            setInputErrors({ ...inputErrors, password: t('resetPassword.errors.notSame') })
            // addToast(t('resetPassword.errors.notSame'), { appearance: 'error' });
        } else {
            setLoading(true);

            const postData = {
                token: searchParams.get("token"),
                email: passwordField.email,
                password: passwordField.password,
                password_confirmation: passwordField.password_confirmation
            };
    
            postUrl('reset-password', postData).then(response => {
                if(!response.status) {
                    if(response.data && response?.data?.status) {
                        addToast(response.data.status, { appearance: 'error' });
                    }
                    if(_.hasIn(response, 'errors')) {
                        setInputErrors(response.errors);
                    }
                } else {
                    setPasswordField({
                        email: "",
                        password: "",
                        password_confirmation: "",
                    });
                    setSuccess(true);
                }
    
                if(response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }

    return (
        <Container component="main" maxWidth="md">
            <Card className={classes.root} variant="elevation" elevation={3}>
                <CardContent className={classes.cardContentRoot}>
                    <Typography component="h1" variant="h5">
                        { t('title.resetPassword') }
                    </Typography>
                    <div className={classes.form}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                { 
                                    success && 
                                    <Alert 
                                        severity="success"
                                        action={
                                            <Button 
                                                color="primary" 
                                                size="small"
                                                variant="contained"
                                                // style={{ fontFamily:'OregonLdoRegular'}}
                                            >
                                                <Link component={RouterLink} to={"/sign-in"}>
                                                    <Typography color="white">{ t('resetPassword.loginNow') }</Typography>
                                                </Link>
                                            </Button>
                                        }
                                    >
                                        { t('resetPassword.resetSuccess') }
                                    </Alert> 
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    // ref={emailRef}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={ t('resetPassword.email') }
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={passwordField.email}
                                    onChange={ handleChange }
                                    required
                                    error={inputErrors?.email ? true : false }
                                    helperText={inputErrors?.email || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    // ref={passwordRef}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={ t('resetPassword.password') }
                                    name="password"
                                    type="password"
                                    value={passwordField.password}
                                    onChange={ handleChange }
                                    required
                                    error={inputErrors?.password ? true : false }
                                    helperText={inputErrors?.password || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    // ref={password_confirmationRef}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={ t('resetPassword.password_confirmation') }
                                    name="password_confirmation"
                                    type="password"
                                    value={passwordField.password_confirmation}
                                    onChange={ handleChange }
                                    required
                                    error={inputErrors?.password_confirmation ? true : false }
                                    helperText={inputErrors?.password_confirmation || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        type="submit"
                                        disabled={ loading ? true : false }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={ resetPasswordClicked }
                                        // style={{ fontFamily:'OregonLdoRegular'}}
                                    >
                                        {
                                            loading ? 
                                            <CircularProgress size={20} />
                                            : t('general.submit')
                                        }
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}

export default ResetPassword;

const useStyles = makeStyles((theme) => ({
    cardContentRoot: {
        padding: theme.spacing(4)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    wordPadding: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    }
}));