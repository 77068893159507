import { getUrl, postUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { changeCartState } from '@actions/generalActions';
import moment from 'moment';
import { ENV } from '@configs/config';
import { useTranslation } from 'react-i18next';

import { Box, Button, CardMedia, Divider, Grid, Link, TextField, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

const max_distance = '2000'; // M not KM

const CartItemCard = (props) => {
    const { carts, buyerInfo, loading, setLoading, inputErrors, setInputErrors, deliveryFeeInfo, orderInfo, refreshCart, setDeliveryFeeInfo, setPaymentLoading, storeTime } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const { addToast } = useToasts();
    const { accessToken, uuid } = useSelector(state => state.general);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [sumTotal, setSumTotal] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [remarks, setRemarks] = useState({});
    const [sumActiveItem, setSumActiveItem] = useState(0);

    //ipay88
    const [ipay88Url, setIpay88Url] = useState("");
    const [ipay88PaymentInput, setIpay88PaymentInput] = useState([]);
    const ipay88PaymentForm = useRef();
    const [order, setOrder] = useState();

    useEffect(() => {
        calculateSumTotal(carts);
        calculateSumTotalActiveItem(carts);
        // eslint-disable-next-line
    }, [carts, deliveryFeeInfo]);

    useEffect(() => {
        if (ipay88Url && _.size(ipay88PaymentInput) > 0) {
            setPaymentLoading(true);
            let postData = {
                ipay88PaymentInput,
                url: ipay88Url,
            }
            // console.log("paymentUrl",paymentUrl);
            // console.log("paymentInfo", postData);
            setIpay88PaymentInput(postData);
            if (ENV === 'dev') {
                getUrl(`/success-payment/${order}`).then(response => {
                    setLoading(false);
                    if (response.status) {
                        navigate(`/paymentresult/${order}?source=ipay88`, { replace: true });
                    }
                }).catch(err => {
                    setLoading(false);
                    addToast(JSON.stringify(err), { appearance: 'error' });
                })
            } else {
                ipay88PaymentForm.current.submit();
            }
            //  setPaymentLoading(true);
            // await setLoading(true);
        }
        // eslint-disable-next-line
    }, [ipay88Url]);

    const calculateSumTotal = (listing) => {
        let sum = 0;
        let sub_price = 0;
        let item_quantity = 0;
        if (_.size(listing) > 0) {
            _.map(listing, list => {
                sub_price = 0;
                if (_.size(list.product_options) > 0) {
                    _.map(list.product_options, main => {
                        let main_option = _.find(list.product.product_options, { id: parseInt(main.id) });
                        if (_.size(main.option) > 0 && main_option) {
                            _.map(main.option, child => {
                                let child_option = _.find(list.product.options[main_option.option_code], { id: parseInt(child) });
                                if (parseFloat(child_option.price) && child_option) {
                                    sub_price = sub_price + ((parseFloat(child_option.price) + parseFloat(list.product.price)) * parseFloat(list.quantity));
                                    item_quantity = item_quantity + list.quantity;
                                }
                            })
                        }
                    })
                } else {
                    sub_price = sub_price + ((parseFloat(list.product.price)) * parseFloat(list.quantity));
                    item_quantity = item_quantity + list.quantity;
                }

                sum += sub_price;
            })
        }
        setSumTotal(parseFloat(sum).toFixed(2));
        setTotalQuantity(item_quantity);
    }

    const calculateSumTotalActiveItem = (listing) => {
        let sum = 0;
        let sub_price = 0;
        let item_quantity = 0;
        if (_.size(listing) > 0) {
            _.map(listing, list => {
                sub_price = 0;
                if (list?.product?.status === 1 && list?.product?.quantity > 0) {
                    if (_.size(list.product_options) > 0) {
                        _.map(list.product_options, main => {
                            let main_option = _.find(list.product.product_options, { id: parseInt(main.id) });
                            if (_.size(main.option) > 0 && main_option) {
                                _.map(main.option, child => {
                                    let child_option = _.find(list.product.options[main_option.option_code], { id: parseInt(child) });
                                    if (parseFloat(child_option.price) && child_option) {
                                        sub_price = sub_price + ((parseFloat(child_option.price) + parseFloat(list.product.price)) * parseFloat(list.quantity));
                                        item_quantity = item_quantity + list.quantity;
                                    }
                                })
                            }
                        })
                    } else {
                        sub_price = sub_price + ((parseFloat(list.product.price)) * parseFloat(list.quantity));
                        item_quantity = item_quantity + list.quantity;
                    }
                }

                sum += sub_price;
            })
        }

        if (_.size(deliveryFeeInfo) > 0) {
            let delivery_fee = deliveryFeeInfo?.price?.grand_total ? parseFloat(deliveryFeeInfo?.price?.grand_total.replace('RM', '')) : 0;
            sum = sum + delivery_fee;
        }
        setSumActiveItem(parseFloat(sum).toFixed(2));
    }

    const checkDistance = () => {
        setLoading(true);
        setInputErrors({});
        let postDistance = {
            address: buyerInfo.address_id ? buyerInfo.address_id : '',
            type: orderInfo.type,
        };
        // check distance when place order for delivery
        if (postDistance.type === 'delivery') {
            getUrl(`/get-distance`, postDistance).then(response => {
                if (response.status) {
                    const { data } = response.data;
                    if (data.value <= max_distance) {
                        // place order here after checking distance for delivery
                        placeOrder();
                    } else {
                        setLoading(false);
                        addToast(t('error.maxDistance'), { appearance: 'error' });
                    }
                } else {
                    setLoading(false);
                    setInputErrors(response.errors);
                    addToast(response.message, { appearance: 'error' });
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        } else if(postDistance.type === 'pickup'){
            // pickup no need check distance so direct proceed to place order
            placeOrder();
        }
    }

    const placeOrder = () => {
        setLoading(true);
        let postData = {
            name: buyerInfo.name,
            email: buyerInfo.email,
            mobile: buyerInfo.mobile,
            address_id: buyerInfo.address_id ? buyerInfo.address_id : '',
            store_id: 1,
            type: orderInfo.type,
            delivery_datetime: moment(moment(new Date(), 'hh:mm A')).isAfter(moment(storeTime?.time?.start, 'hh:mm A')) && moment(moment(new Date(), 'hh:mm A')).isBefore(moment(storeTime?.time?.end, 'hh:mm A')) ? moment(orderInfo?.dateTime).format() : ""
        };

        // if (orderInfo?.time_error === '') {
        //     postData = {
        //         ...postData,
        //         delivery_datetime: moment(moment(new Date(), 'hh:mm A')).isAfter(moment(open_time, 'hh:mm A')) && moment(moment(new Date(), 'hh:mm A')).isBefore(moment(close_time, 'hh:mm A')) ? moment(orderInfo?.dateTime).format() : "",
        //     }
        // }

        if (_.size(deliveryFeeInfo) > 0) {
            postData = {
                ...postData,
                delivery_distance: deliveryFeeInfo?.distance ? (deliveryFeeInfo?.distance) : '',
                delivery_price: {
                    total: deliveryFeeInfo?.price?.total ? (parseFloat((deliveryFeeInfo?.price?.total).replace('RM', '')).toFixed(2)) : 0,
                    tax: deliveryFeeInfo?.price?.tax ? (parseFloat((deliveryFeeInfo?.price?.tax).replace('RM', '')).toFixed(2)) : 0,
                    grand_total: deliveryFeeInfo?.price?.grand_total ? (parseFloat((deliveryFeeInfo?.price?.grand_total).replace('RM', '')).toFixed(2)) : 0,
                },
                delivery_id: deliveryFeeInfo.quotationId,
            }
        }

        console.log('postData', postData);
        postUrl("/orders", postData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(changeCartState(0));
                setInputErrors({});
                refreshCart();
                setDeliveryFeeInfo({});
                if (_.size(response?.data?.order_payments) > 0) {
                    let info = response?.data?.order_payments;
                    // console.log("response payment", info);
                    setOrder(response?.data?.id);
                    _.map(info, list => {
                        if (list.status === 20) {
                            setIpay88Url(response.url);
                            setIpay88PaymentInput(response?.data?.order_payments[0]?.payment_details);
                        }
                    })
                    // ipay88payment(info);
                }
            } else {
                setInputErrors(response.errors);
                addToast(response.message, { appearance: 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const cartItemBox = (cartItem) => {
        let mainOption = cartItem.product.product_options;
        let childOption = cartItem.product.options;

        const displayPrice = () => {
            let price = cartItem.product.price ? parseFloat(cartItem.product.price) : 0;

            if (_.size(cartItem.product_options) > 0 && price > 0) {
                _.map(cartItem.product_options, main => {
                    let main_option = _.find(mainOption, { id: parseInt(main.id) });
                    if (_.size(main.option) > 0 && main_option) {
                        _.map(main.option, child => {
                            let child_option = _.find(childOption[main_option.option_code], { id: parseInt(child) })
                            if (parseFloat(child_option.price) && child_option) {
                                price = price + (parseFloat(child_option.price));
                            }
                        })
                    }
                })
            }

            //add quantity
            price = price * parseFloat(cartItem.quantity);

            return <NumberFormat prefix="RM " value={parseFloat(price).toFixed(2)} displayType="text" fixedDecimalScale={2} />;
        }

        if (cartItem) {
            return (
                <Grid container spacing={2} className={classes.packageBox} key={cartItem.id} style={{ backgroundColor: '#ffffff', opacity: cartItem?.product?.quantity > 0 && cartItem?.product.status === 1 ? 1 : 0.3 }}>
                    <Grid item xs={2} sm={2} md={2} className={classes.gridItemBoxCenter}>
                        {
                            cartItem.product?.images[0] &&
                            <CardMedia
                                component="img"
                                image={cartItem.product.images[0]?.file_name}
                                sx={{ flex: 1, width: 50 }}
                                alt={cartItem.product.name}
                                className={classes.imgStyle}
                            />
                        }
                    </Grid>
                    <Grid item xs={8} sm={6} md={4} className={classes.gridItemBoxLeft}>
                        <Typography variant="h6">{cartItem.product.name} (Basic Price:{parseFloat(cartItem.product.price).toFixed(2)})</Typography>
                        {
                            inputErrors['cart.' + cartItem.id] ?
                                <Typography variant="caption" color="error">{inputErrors['cart.' + cartItem.id]}</Typography>
                                : null
                        }
                        {
                            _.size(cartItem.product_options) > 0 &&
                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start" paddingTop={2} paddingLeft={2}>
                                {
                                    _.map(cartItem.product_options, optionItem => {
                                        let list_info = _.find(mainOption, { id: parseInt(optionItem.id) });
                                        return (
                                            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" key={optionItem.id}>
                                                <Grid item>
                                                    <Typography variant="body2" className={classes.mainOptionTextStyle}>{list_info.option_code}</Typography>
                                                    {
                                                        _.map(optionItem.option, child => {
                                                            let info = _.find(childOption[list_info.option_code], { id: parseInt(child) })
                                                            return (
                                                                <Grid item container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" className={classes.subOptionTextStyle} key={child} paddingLeft={1}>
                                                                    <Grid item><Typography variant="subtitle2" className={classes.subOptionTextStyle}>{info.value} {parseFloat(info.price) ? `( + RM ${parseFloat(info.price).toFixed(2)} )` : ''}</Typography></Grid>
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        }
                    </Grid>
                    <Grid item xs={6} sm={2} md={3} className={classes.gridItemBoxRight}>
                        <Typography>{"x" + cartItem.quantity}</Typography>
                    </Grid>
                    <Grid item xs={6} sm={2} md={3} className={classes.gridItemBoxRight}>
                        {displayPrice()}
                    </Grid>
                </Grid>
            )
        }
    }

    const totalBox = () => {
        return (
            <Box className={classes.totalBox1}>
                <Grid container spacing={2} className={classes.totalBox2}>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{`${totalQuantity} item(s), Subtotal :`}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{'RM ' + parseFloat(sumTotal).toFixed(2)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {
                        orderInfo.type === 'delivery' && _.size(deliveryFeeInfo) > 0 ?
                            <>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{`Delivery Fee :`}</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{'RM ' + parseFloat((deliveryFeeInfo?.price?.total).replace('RM', '')).toFixed(2)}</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{`Tax :`}</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{'RM ' + parseFloat((deliveryFeeInfo?.price?.tax).replace('RM', '')).toFixed(2)}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </>
                            : null
                    }
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{'Total Sum :'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography className={classes.finalPriceStyle}>{'RM ' + parseFloat(sumActiveItem).toFixed(2)}</Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const bottomActionRow = () => {
        let delivery_fee = deliveryFeeInfo?.price?.grand_total ? parseFloat(deliveryFeeInfo?.price?.grand_total.replace('RM', '')) : 0;
        return (
            <Box className={classes.bottomActionStyle}>
                {console.log("orderInfo", orderInfo)}
                <Grid container spacing={2} className={classes.bottomActionBox}>
                    <Grid item xs={12} className={classes.gridItemBoxRight}>
                        <Button variant="contained" onClick={checkDistance} disabled={(accessToken && !loading && _.size(carts) > 0 && (parseFloat(sumActiveItem) === (parseFloat(parseFloat((parseFloat(sumTotal)) + parseFloat(parseFloat(delivery_fee))).toFixed(2))))) && orderInfo.type !== '' && deliveryFeeInfo?.biker_availablity !== 0 && orderInfo.dateTime !=='' ? false : true}>
                            Place Order
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    return (
        <Box className={classes.outlineBox}>
            <Typography variant="h6" style={{ marginBottom: 10 }}>Checkout Item</Typography>
            {
                _.size(carts) > 0
                    ?
                    <>
                        {
                            _.map(carts, (cartList, cartIndex) => {
                                return (
                                    <Box className={classes.cartContainer} key={cartIndex}>
                                        <Box>
                                            {
                                                cartItemBox(cartList)
                                            }
                                        </Box>
                                        <Box style={{ padding: "5px 16px" }}>
                                            <TextField
                                                size="small"
                                                variant="outlined"
                                                margin="normal"
                                                fullWidth
                                                label="Remark"
                                                name="remark"
                                                value={cartList.remarks || ""}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Box>
                                    </Box>
                                )
                            })
                        }
                    </>
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>No selected cart</Typography>
                        <Link component={RouterLink} to={`/cart`} underline="none">
                            <Button variant="contained" className={classes.buttonStyle}>
                                <Typography className={classes.buttonTextStyle}>Go to cart</Typography>
                            </Button>
                        </Link>
                    </Box>
            }
            {totalBox()}
            {bottomActionRow()}
            {/* ipay88 */}
            {
                ipay88Url !== "" ?
                    <form ref={ipay88PaymentForm} action={ipay88Url} method="POST">
                        {ipay88PaymentInput ?
                            _.map(ipay88PaymentInput, (paymentValue, paymentIndex) => {
                                return (
                                    <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                                )
                            })
                            : null}
                    </form>
                    : null
            }
        </Box>
    )
}

export default CartItemCard;

const useStyles = makeStyles((theme) => ({
    cartContainer: {
        borderRadius: 20,
        borderColor: theme.palette.primary.main,
        overflow: 'hidden',
        boxShadow: 'rgb(223 223 223) 0px 0px 14px 0px',
        marginBottom: 15,
        border: '1px  #efefefef'
    },
    packageBox: {
        padding: "16px",
        borderBottom: "solid 1px " + theme.palette.primary.main,
    },
    imgStyle: {
        objectFit: "contain !important"
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    bottomActionStyle: {
        backgroundColor: 'transparent !important',
        margin: "50px 0px 20px 0px !important",
        borderRadius: 5,
    },
    bottomActionBox: {
        padding: "0px 16px 16px 16px",
        color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
    },
    buttonStyle: {
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    totalBox1: {
        marginTop: "20px !important",
        border: 'none',
        borderRadius: 20,
        overflow: 'hidden',
        boxShadow: 'rgb(223 223 223) 0px 0px 14px 0px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    totalBox2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "550px !important",
        maxWidth: '100%',
        padding: 16
    },
    finalPriceStyle: {
        color: theme.palette.primary.main,
        fontWeight: "bold !important",
        fontSize: "1.2rem !important"
    },
    outlineBox: {
        margin: "20px !important"
    },
    mainOptionTextStyle: {
        textTransform: "capitalize",
        textDecoration: 'underline !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.75rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.95rem !important',
        },
    },
    subOptionTextStyle: {
        textTransform: "capitalize",
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.55rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
    },
}))
