import React from 'react';

import { Box, Typography, Grid, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CoffeePackageHeader = ({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box paddingTop={4}>
            <Grid container spacing={2}>
                {/* {_.size(data) > 0 && _.size(data.text) ?
                    <Grid item xs={12} md={7}>
                        <div dangerouslySetInnerHTML={{ __html: data.text }} />
                        <div style={{ marginTop: 10, textAlign: "left" }}>
                            <Link component={RouterLink} to="/career" underline="none">
                                <Button variant="outlined" className={classes.roundedButtonStyle} color="primary" endIcon={<ArrowForwardIcon />}>
                                    <Typography className={classes.buttonTextStyle}>{_.size(data) > 0 && _.size(data.button) ? data.button : `Apply Now`}</Typography>
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                    : */}
                    <Grid item xs={12} md={7} container direction="column" justifyContent="center" className={classes.alignStyle}>
                        <Typography variant="h4" className={classes.titleStyle}>We're Hiring</Typography>
                        <div style={{ paddingTop: 10, paddingBottom: 10 , paddingRight:50 }} className={classes.textAlignStyle}>
                            <Typography variant="h5" >Our humble bakery cafe is looking to grow our family! We're  building a team of exceptional individuals seeking an exciting & rewarding career in the F&B / Hospitality industry , all set to grow along with us!</Typography>
                        </div>
                        <div style={{ marginTop: 10 }}>
                            <Link component={RouterLink} to="/career" underline="none">
                                <Button variant="outlined" className={classes.roundedButtonStyle} color="primary" endIcon={<ArrowForwardIcon />}>
                                    <Typography className={classes.buttonTextStyle}>{_.size(data) > 0 && _.size(data.button) ? data.button : `Apply Now`}</Typography>
                                </Button>
                            </Link>
                        </div>
                    </Grid>
                {/* } */}
                <Grid item xs={12} md={5}>
                    <div className={classes.imageContainerStyle}>
                        {/* {_.size(data) > 0 && _.size(data.image) ?
                            <img className={classes.imageStyle} alt="Black Bixon Cafe" src={data.image} />
                            : */}
                            <img className={classes.imageStyle} alt="Black Bixon Cafe" src="../images/home/home-infos/pastry-chef.png" />
                        {/* } */}
                    </div>
                </Grid>
            </Grid>
            {/* <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center">
                    <Grid container direction="column" justifyContent="center" alignItems="center" >
                        <Typography variant="body1" component="p" className={classes.subtitleStyle}>We grind & brew every day.</Typography>
                        <Typography variant="h6" className={classes.timeStyle}>8AM - 10PM</Typography>
                        <Button size="small" variant="outlined" className={classes.roundedOutlineButtonStyle} href="/menu">
                            <Typography className={classes.buttonTextStyle}>{t('general.orderNow')}</Typography>
                        </Button>
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle} href="/menu">
                            <Typography className={classes.buttonTextStyle} style={{paddingLeft:15, paddingRight:15}}>{t('general.dineIn')}</Typography>
                        </Button>
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle} href="/menu">
                            <Typography className={classes.buttonTextStyle} style={{paddingLeft:15, paddingRight:15}}>{t('general.delivery')}</Typography>
                        </Button>
                    </Grid>
                </Box> */}
        </Box>
    )
}

export default CoffeePackageHeader;

const useStyles = makeStyles((theme) => ({
    imageContainerStyle: {
        flex: 1,
        padding: 10
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'end',
    },
    imageStyle: {
        width: '100%',
        objectFit: 'contain',
        maxWidth: 400,
    },
    titleStyle: {
        fontFamily:'OregonLdo !important',
        fontWeight: 'bold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '2.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem !important',
        },
    },
    uppercaseStyle: {
        textTransform: "uppercase",
    },
    colorTextStyle: {
        color: theme.palette.secondary.main,
    },
    boxStyle: {
        display: 'flex',
        justifyContent: 'flex-end !important',
        flexDirection: 'column !important',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row !important',
            justifyContent: 'flex-start !important',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end !important',
        },
    },
    alignStyle: {
        alignItems: 'flex-start !important',
        [theme.breakpoints.up('xs')]: {
            alignItems: 'flex-start !important',
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start !important',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-start !important',
        },
    },
    textAlignStyle: {
        textAlign: 'justify',
        [theme.breakpoints.up('xs')]: {
            textAlign: 'justify',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'justify',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'justify',
        },
    },
    roundedButtonStyle: {
        borderRadius: '20px !important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
            color:'white !important'
        },
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
    },
}))