import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getUrl, putUrl } from '@helpers/api';
import { Box, Button, Typography, Grid, CircularProgress, Dialog, DialogContent, DialogActions, DialogTitle } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

const QueueInfo = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({});
    const [update, setUpdate] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        getUrl(`/queues/${searchParams.get('qcode')}/status`)
            .then((response) => {
                if (response.status) {
                    setState(response.data);
                    // console.log(response.data);
                } else {
                    addToast("", t("error.contactSupport"), "error", "");
                }
            }).catch((error) => {
                addToast("", error.message || t("error.contactSupport"), "error", "");
            });
        // eslint-disable-next-line
    }, [update]);

    const cancelQueue = () => {
        setLoading(true);
        putUrl(`/queues/${searchParams.get('qcode')}/status`)
            .then(response => {
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                if (response.status) {
                    setUpdate(true);
                }
                setLoading(false);
                setOpen(false);
            }).catch(error => {
                setLoading(false);
                addToast("", error.message || t("error.contactSupport"), "error", "");
            })
    }

    const refresh = () => {
        setLoading(true);
        window.location.reload(false);
    }

    return (
        <Box marginTop={4}>
            <Typography variant="h1" className={classes.titleStyle} justifyContent="center" textAlign="center">{t('title.queue')}</Typography>
            <Grid item className={classes.containerStyle} flexDirection="column" justifyContent="center" alignItems="center" >
                {
                    [state.status].includes('canceled') || [state.status].includes('rejected') ?
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main, textTransform: "capitalize" }}><b>{state.status}</b></Typography>
                        </Grid>
                        : null
                }
                {
                    [state.status].includes('called') ?
                        <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" >
                            <Grid item xs={12}>
                                <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main, textTransform: "capitalize" }}><b>{t('queue.called')}</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    loading ?
                                        <CircularProgress size={40} />
                                        : <Button variant="contained" className={classes.roundedButtonStyle} onClick={() => refresh()}>
                                            <Typography className={classes.buttonTextStyle}>{t('queue.refresh')}</Typography>
                                        </Button>
                                }&nbsp;
                                {
                                    loading ?
                                        null
                                        : <Button variant="contained" className={classes.roundedButtonStyle} onClick={() => setOpen(true)}>
                                            <Typography className={classes.buttonTextStyle}>{t('queue.cancel')}</Typography>
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                        : null
                }
                {
                    [state.status].includes('pending') ?
                        <Grid display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Grid item xs={12}>
                                <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main }}><b>{t('queue.position')}</b></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h2" component="p" className={classes.titleStyle}>{state.position}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    loading ?
                                        <CircularProgress size={40} />
                                        : <Button variant="contained" className={classes.roundedButtonStyle} onClick={() => refresh()}>
                                            <Typography className={classes.buttonTextStyle}>{t('queue.refresh')}</Typography>
                                        </Button>
                                }&nbsp;
                                {
                                    loading ?
                                        null
                                        : <Button variant="contained" className={classes.roundedButtonStyle} onClick={() => setOpen(true)}>
                                            <Typography className={classes.buttonTextStyle}>{t('queue.cancel')}</Typography>
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                        : null
                }
                {
                    [state.status].includes('completed') ?
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main, textTransform: "capitalize" }}><b>{state.status}</b></Typography>
                        </Grid>
                        : null
                }

            </Grid><br />
            <Grid item className={classes.containerStyle} flexDirection="column" justifyContent="center" alignItems="flex-start" >
                <Grid item display="flex" flexDirection="column" textAlign="flex-start" style={{ paddingLeft: 15 }}>
                    <div className={classes.infoStyle} >
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main }}>{t('queue.name')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle}><b>{state.name}</b></Typography>
                        </Grid>
                    </div>
                    <div className={classes.infoStyle} >
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main }}>{t('queue.email')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle}><b>{state.email}</b></Typography>
                        </Grid>
                    </div>
                    <div className={classes.infoStyle} >
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main }}>{t('queue.mobile')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle}><b>{state.mobile}</b></Typography>
                        </Grid>
                    </div>
                    <div className={classes.infoStyle} >
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle} style={{ color: theme.palette.button.main }}>{t('queue.size')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" component="p" className={classes.textStyle}><b>{state.size}</b></Typography>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                maxWidth={'sm'}
            >
                <DialogTitle>{t("queue.cancel")}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1" component="p" className={classes.textStyle} >
                        {t("queue.cancel_queue")}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button size="small" variant="contained" className={classes.roundedButtonStyle} onClick={() => { cancelQueue() }} fullWidth>
                        <Typography className={classes.buttonTextStyle} >{t('queue.yes')}</Typography>
                    </Button>
                    <Button size="small" variant="contained" className={classes.roundedButtonStyle} onClick={() => { setOpen(false) }} fullWidth>
                        <Typography className={classes.buttonTextStyle} >{t('queue.no')}</Typography>
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default QueueInfo;

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textAlign: 'left !important',
        display: 'inline !important',
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
        },
    },
    subtitleStyle: {
        textAlign: 'left !important',
        // fontFamily: 'OregonLdoBold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.8rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem !important',
        },
    },
    titleStyle: {
        // fontFamily: 'OregonLdoDemibold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '3.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem !important',
        },
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '5px 5px 4px #888888',
        border: '1px solid #808080',
        borderRadius: '15px',
    },

    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 10,
        marginBottom: 10,
    },
}))