import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import _ from "lodash";

import { postUrl } from '@helpers/api';
import { Box, Button, CircularProgress, FormLabel, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const ContactUsForm = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const today = new Date();
    const [state, setState] = useState({
        full_name: '',
        contact_number: '',
        email_address: '',
        title:'',
        detail: '',
        date: today.getDate() + "/" + (today.getMonth() + 1) + "/" + today.getFullYear()
    });
    const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'contact_number') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        postUrl("/contact-us", state).then(response => {
            if(response.status) {
                setInputErrors({});
            } else {
                setInputErrors(response.errors);
            }

            if(response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
    }


    return (
        <Box paddingTop={4}>
            {/* <div>
                <Typography variant="h2" className={classes.titleStyle}>{t('title.contactUs')}</Typography>
            </div>
            <div>
                <Typography variant="h5" className={classes.subtitleStyle}>{t('contactUs.subtitle')}</Typography>
            </div> */}
            <div className={classes.containerStyle}>
                <Grid container justifyContent="center" alignItems="center" style={{ padding: 20 }} spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel className={classes.textStyle}>{t('contactUs.fullName')+'*'}</FormLabel>
                        <TextField
                            fullWidth
                            name="full_name"
                            autoComplete="full_name"
                            onChange={handleChange}
                            value={state.full_name || ''}
                            error={inputErrors && inputErrors.full_name ? true : false}
                            helperText={inputErrors && inputErrors.full_name ? inputErrors.full_name : ''}
                            FormHelperTextProps={{ style: { fontSize: '1.2rem' } }}
                            placeholder={t('contactUs.fullName')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel className={classes.textStyle}>{t('contactUs.contactNumber')}</FormLabel>
                        <TextField
                            fullWidth
                            name="contact_number"
                            autoComplete="contact_number"
                            onChange={handleChange}
                            value={state.contact_number || ''}
                            error={inputErrors && inputErrors.contact_number ? true : false}
                            helperText={inputErrors && inputErrors.contact_number ? inputErrors.contact_number : ''}
                            FormHelperTextProps={{ style: { fontSize: '1.2rem' } }}
                            placeholder={t('contactUs.contactNumber')}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel className={classes.textStyle}>{t('contactUs.email')}</FormLabel>
                        <TextField
                            fullWidth
                            name="email"
                            type="email"
                            autoComplete="email"
                            onChange={handleChange}
                            value={state.email || ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            FormHelperTextProps={{ style: { fontSize: '1.2rem' } }}
                            placeholder={t('contactUs.email')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel className={classes.textStyle}>{t('contactUs.title')}</FormLabel>
                        <TextField
                            fullWidth
                            name="title"
                            autoComplete="title"
                            onChange={handleChange}
                            value={state.title || ''}
                            error={inputErrors && inputErrors.title ? true : false}
                            helperText={inputErrors && inputErrors.title ? inputErrors.title : ''}
                            FormHelperTextProps={{ style: { fontSize: '1.2rem' } }}
                            select
                        >
                        {
                            _.map(["Event Enquiry", "Career Opportunity", "Booking Reservation", "Feedback"], label => {
                                return (
                                    <MenuItem key={label} value={label}>
                                        {label}
                                    </MenuItem>
                                )
                            })
                        }
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel className={classes.textStyle}>Date</FormLabel>
                        <TextField
                            fullWidth
                            name="date"
                            value={state.date}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel className={classes.textStyle}>{t('contactUs.detailTitle')}</FormLabel>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            name="detail"
                            onChange={handleChange}
                            value={state.detail || ''}
                            error={inputErrors && inputErrors.detail ? true : false}
                            helperText={inputErrors && inputErrors.detail ? inputErrors.detail : ''}
                            FormHelperTextProps={{ style: { fontSize: '1.2rem' } }}
                            placeholder={t('contactUs.detail')}
                        />
                    </Grid>
                    <Grid container item direction="column" justifyContent="center" alignItems="center" paddingTop={5}>
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                            {
                                loading ?
                                    <CircularProgress size={20} style={{color:'white'}}  />
                                    : <Typography className={classes.buttonTextStyle} onClick={() => submitData()}>SEND</Typography>
                            }
                        </Button>
                    </Grid>
                </Grid>
            </div>
            {/* <Box display="flex" flexDirection="column" className={classes.infoStyle} justifyContent="center" alignItems="center">
                <Link component={RouterLink} to="/menu" underline="none">
                    <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                        <Typography className={classes.buttonTextStyle}>{t('general.seeMenu')}</Typography>
                    </Button>
                </Link>
                <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                    <Typography className={classes.buttonTextStyle}>{t('general.orderNowThroughDeliveries')}</Typography>
                </Button>
            </Box> */}
        </Box>
    )
}

export default ContactUsForm;

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        position: 'relative !important',
        width: '100% !important',
        overflow: 'hidden !important',
        // border: 'solid 5px',
        // borderRadius: 10,
        // borderColor: theme.palette.primary.main,
    },
    titleStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoDemibold !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitleStyle: {
        // fontFamily: 'OregonLdoRegular !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        textAlign: 'center',
    },
    textStyle: {
        color: '#000000 !important',
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '45px !important',
        paddingRight: '45px !important',
        paddingTop: '2px !important',
        paddingBottom: '2px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main+'!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main+'!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    }
}))