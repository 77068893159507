import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, Box, IconButton, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsFacebook, BsInstagram, BsWhatsapp } from 'react-icons/bs';

import { APP_NAME } from '@configs/config';

const AppFooter = () => {
    const classes = useStyles();
    return (
        <>
            <FooterMenu />
            <footer className={classes.footer}>
                <Typography variant="body2" color="textSecondary" >
                    {`© ${new Date().getFullYear()} ${APP_NAME}. All right reserved.`}
                </Typography>
            </footer>
        </>
    )
}

const FooterMenu = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" paddingTop={5}>
            <Box display="flex" flexDirection="row" alignItems="flex-start" justifyContent="center">
                <div className={classes.containerStyle}>
                    <Link component={RouterLink} to="/menu" underline='none'>
                        <Typography variant="body2" >{t('footer.menu')}</Typography>
                    </Link>
                    {/* <Link component={RouterLink} to="/reservation" underline='none'>
                        <Typography variant="body2">{t('footer.reservation')}</Typography>
                    </Link>
                    <Link component={RouterLink} to="/career" underline='none'>
                        <Typography variant="body2">{t('footer.career')}</Typography>
                    </Link> */}
                </div>
                <div className={classes.containerStyle} style={{ alignItems: 'flex-start' }}>
                    <Typography variant="body2"  style={{ textAlign: 'center' }}>{t('footer.followUs')}</Typography>
                    <Box display="flex" flexDirection="row" >
                        <Link rel="noopener noreferrer" href="https://www.facebook.com/blackbixoncafe/" target="_blank" underline="none">
                            <IconButton>
                                <BsFacebook fontSize="small" />
                            </IconButton>
                        </Link>
                        <Link rel="noopener noreferrer" href="https://www.instagram.com/blackbixoncafe/" target="_blank" underline="none">
                            <IconButton>
                                <BsInstagram fontSize="small" />
                            </IconButton>
                        </Link>
                        <Link rel="noopener noreferrer" href="https://wa.me/60173950321" target="_blank" underline="none">
                            <IconButton>
                                <BsWhatsapp fontSize="small" />
                            </IconButton>
                        </Link>
                    </Box>
                </div>
                <div style={{padding:10}}>
                <Link component={RouterLink} to="/contact-us" underline='none'>
                        <Typography variant="body2">{t('title.contactUs')}</Typography>
                    </Link>
                    {/* <Link component={RouterLink} to="/tnc" underline='none'>
                        <Typography variant="body2">{t('footer.tnc')}</Typography>
                    </Link>
                    <Link component={RouterLink} to="/policy" underline='none'>
                        <Typography variant="body2">{t('footer.policy')}</Typography>
                    </Link> */}
                    <Link component={RouterLink} to="/faq" underline='none'>
                        <Typography variant="body2">{t('footer.faq')}</Typography>
                    </Link>
                </div>
                {/* <div className={classes.containerStyle}>
                    <Link component={RouterLink} to="/policy" underline='none'>
                        <Typography variant="body2">{t('footer.policy')}</Typography>
                    </Link>
                </div> */}
            </Box>
        </Box>
    )
}

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        flex: 1,
        padding: 10
    },
    followUsStyle: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: 25
        }
    },
    footer: {
        // borderTop: '1px solid',
        // borderTopColor: theme.palette.grey[200],
        padding: theme.spacing(3, 2),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
}));

export default AppFooter;