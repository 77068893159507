import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';

const HomeOperationHour = memo(({data}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box paddingTop={4}>
            <div className="slide-img" style={{ width: '100%', position: 'relative' }}>
                {/* {_.size(data) > 0 && _.size(data.background) ?
                    <img alt="Operation Hour Black Bixon" className={classes.imageStyle} src={data.background} />
                : */}
                    <img alt="Operation Hour Black Bixon" className={classes.imageStyle} src="../images/home/home-operation-hour/brown-bg.webp" />
                {/* } */}
                <div className={classes.containerStyle}>
                    <div  style={{ display:'flex' , flexDirection:'row', justifyContent:'center' , alignItems:'center'}} className={classes.contentStyle} >
                        <div className={classes.logoContainerStyle}>
                            <img className={classes.logoStyle} alt="BlackBixon Logo" src="../images/home/home-operation-hour/home-logo.png" />
                        </div>
                        {/* {_.size(data) > 0 && _.size(data.text) ?
                            <div dangerouslySetInnerHTML={{ __html: data.text }} />
                        : */}
                            <>
                                <Typography className={classes.subtitleStyle}>We grind & brew every day</Typography>
                                <Typography className={classes.timeStyle}>8am - 10pm</Typography>
                            </>
                        {/* } */}
                    </div>
                </div>
            </div>
        </Box>
    )
})

export default HomeOperationHour;

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        position: 'absolute',
        zIndex: 1,
        top: '20%',
        left: '15%',
        marginLeft: -20,
        marginTop: -20,
        [theme.breakpoints.up('sm')]: {
            left: '15%',
            top: '40%',
        },
        [theme.breakpoints.up('md')]: {
            left: '30%',
            top: '40%',
        },
    },
    imageStyle: {
        objectFit: 'cover',
        minHeight: '125px !important',
        maxHeight: '145px !important',
        width: '100%'
    },
    subtitleStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
        },
        color: '#ffffff !important'
    },
    timeStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
        },
        color: '#ffffff !important',
        fontWeight: 'bold !important',
        paddingLeft: 10,
        marginBottom:5
    },
    logoStyle: {
        objectFit: 'contain !important',
        height: '100% !important',
    },
    logoContainerStyle: {
        marginRight: 10,
        height: '40px !important',
        [theme.breakpoints.up('xs')]: {
            height: '30px !important',
        },
        [theme.breakpoints.up('sm')]: {
            height: '30px !important',
        },
        [theme.breakpoints.up('md')]: {
            height: '40px !important',
        },
        width:'40px !important',
    },
    contentStyle:{
        paddingTop:'0px !important',
        [theme.breakpoints.up('xs')]: {
            paddingTop:'40px !important',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop:'20px !important',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop:'0px !important',
        },
    }
}))
