import React, { useEffect, useState } from 'react';

import { Box, Container } from '@mui/material';
import HomeAboutUs from './HomeAboutUs';
import HomeCarousel from './HomeCarousel';
import HomeInfo from './HomeInfo';
import HomeInfo2 from './HomeInfo2';
import HomeLocateUs from './HomeLocateUs';
import HomeMenu from './HomeMenu';
import HomeNewsletter from './HomeNewsletter';
import HomeOperationHour from './HomeOperationHour';
import HomeReview from './HomeReview';

import { getUrl } from '@helpers/api';
import _ from 'lodash';
import HomeArticle from './HomeArticle';

const Home = () => {
    const [cms, setCms] = useState({});
    
    useEffect(() => {
        getUrl(`/settings/cms/layout`, {"code": "home-page"}).then(result => {
            setCms(result.data.value.data);
        }).catch((error) => {
            // addToast('', error.message || t('error.contactSupport'), 'error', '');
        })
        // eslint-disable-next-line
    }, [])

    const renderPage = (page) => {
        switch(page.section){
            case "carousel":
                return <HomeCarousel key={page.section} data={page}/>;
            case "about-us": 
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeAboutUs data={page} />
                    </Container>
                )
            case "menu": 
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeMenu data={page} />
                    </Container>
                )
            case "operation-hour": 
                return <HomeOperationHour data={page} key={page.section}/>
            case "info": 
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeInfo data={page} />
                    </Container>
                )
            case "info2":
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeInfo2 data={page} />
                    </Container>
                )
            case "insta-photos": 
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeReview data={page} />
                    </Container>
                )
            // case "locate-us": 
            //     return <HomeLocateUs data={page} key={page.section} />
            case "article":
                return (
                    <Container className="app-container" maxWidth="lg" key={page.section}>
                        <HomeArticle data={page} />
                    </Container>
                )
            default: 
                return null
        }
    }
    return (
        <Box>
            {
                _.size(cms) > 0 && 
                _.map(cms, page => (
                    renderPage(page)
                ))
            }
            {/* <HomeCarousel />
            <Container className="app-container" maxWidth="lg">
                <HomeAboutUs data={cms} />
                <HomeMenu />
            </Container>
            <HomeOperationHour />
            <Container className="app-container" maxWidth="lg">
                <HomeInfo />
                <HomeReview />
            </Container>
            <HomeLocateUs /> */}
            <HomeNewsletter />
        </Box>
    )
}
export default Home;