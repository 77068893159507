import React, { memo } from 'react';
import _ from 'lodash';

import { Box, Button, Typography, Grid, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const CoffeePackageCapsules = memo(({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box paddingTop={15}>
            <div>
                {
                    _.size(data.title) ?
                        <div dangerouslySetInnerHTML={{ __html: data.title }} />
                        :
                        <Typography variant="h2" className={classes.titleStyle}>{t('coffeePackage.coffeeCapsules')}</Typography>
                }
            </div>
            <div>
                {
                    _.size(data.subtitle) ?
                        <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
                        :
                        null
                }
            </div>
            <div style={{ paddingTop: 20 }}>
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                    {
                        (_.size(data) > 0 && _.size(data.images) > 0 && _.size(data.images[0].image)) ?
                            <>
                                {
                                    _.map(data.images, (item, index) => {
                                        if (_.size(item?.image)) {
                                            return (
                                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6} key={index}>
                                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                                        <div className={classes.imageContainerStyle}>
                                                            <img className={classes.imageStyle} alt="BlackBixon Capsule" src={item?.image}  width={400} height={568} />
                                                        </div>
                                                        <Link rel="noopener noreferrer" href={item?.link ? item?.link:"/"} target="_blank" underline="none">
                                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                                <Typography className={classes.buttonTextStyle}>{_.size(data?.button_title) ? data.button_title : t('general.purchase')}</Typography>
                                                            </Button>
                                                        </Link>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    })
                                }
                            </>
                            :
                            <>
                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsules Arabic Capsule" src="../images/coffee-package/coffee-package-capsules/capsules-arabic-capsule.jpg" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/detail/4" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsules Choco Capsule" src="../images/coffee-package/coffee-package-capsules/capsules-choco-capsule.jpg" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/detail/17" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsules Energy Capsule" src="../images/coffee-package/coffee-package-capsules/capsules-energy-capsule.jpg" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/detail/2" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marign: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsule Nutritional Capsule" src="../images/coffee-package/coffee-package-capsules/capsule-nutritional-capsule.jpg" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/detail/3" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsules V-creamer Capsule" src="../images/coffee-package/coffee-package-capsules/capsules-v-creamer-capsule.png" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/detail/8" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Grid item style={{ marignTop: 20, marginBottom: 20 }} xs={6}>
                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Capsules Bixon Cup" src="./images/coffee-package/coffee-package-capsules/capsules-bixon-cup.png" />
                                        </div>
                                        <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/category/8" target="_blank" underline="none">
                                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                                <Typography className={classes.buttonTextStyle}>{t('general.purchase')}</Typography>
                                            </Button>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </>
                    }
                </Grid>
            </div>
        </Box>
    )
});

export default CoffeePackageCapsules;

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "capitalize",
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    imageStyle: {
        width: '100%',
        objectFit: 'cover',
        maxWidth: 400,
    },
    roundedButtonStyle: {
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main + '!important',
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily:'OregonLdoBold !important',
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    imageContainerStyle: {
        flex: 1,
        padding: 10,
    },
}))