import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Typography, Link, Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container maxWidth="md">
            <div className={classes.containerStyle}>
                <Typography variant="h2" className={classes.textStyle}>{ t('general.notFound') }</Typography>
                <Link to="/" component={RouterLink}>
                    <Button 
                        variant="contained"
                        color="primary"
                        className={classes.buttonContainerStyle}
                    >
                        <Typography variant="button">{ t('general.goHome') }</Typography>
                    </Button>
                </Link>
            </div>
        </Container>
    )
};

const useStyles = makeStyles((theme) => ({
    textStyle: {
        fontWeight: 'bold !important'
    },
    containerStyle: {
        paddingTop: theme.spacing(22),
        paddingBottom: theme.spacing(22),
    },
    buttonContainerStyle: {
        marginTop: theme.spacing(6),
    }
}))

export default NotFound;