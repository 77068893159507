import React, { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Grid, TextField, Typography, FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { BsChevronDown } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';
import Geocode from "react-geocode";
import { GOOGLE_MAP_TOKEN } from '../../../../configs/config';

import { putUrl, deleteUrl } from '@helpers/api';
import { updateAddresses } from '@actions/userActions';

Geocode.setApiKey(GOOGLE_MAP_TOKEN);
// Geocode.enableDebug();

const AddressAccordion = memo((props) => {
    const { id, name, full_address } = props;

    const [edit, setEdit] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [addressField, setAddressField] = useState({
        name: '',
        unit: '',
        street: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
        coordinate: '',
        username:'',
        email:'',
        mobile:'',
    });
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } =  useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    useEffect(() => {
        setAddressField({ 
            name: props.name || "",
            unit: props.unit || "",
            street: props.street || "",
            address1: props.unit+' '+props.street || "",
            address2: props.address2 || "",
            zip: props.zip || "",
            city: props.city || "",
            state: props.state || "",
            country: props.country || "",
            username :props.username || "",
            email :props.email || "",
            mobile : props.mobile || "",
            coordinate : props.coordinate || "",
        })
    }, [props]);

    const toggleEdit = () => {
        setEdit(!edit)
    }

    const toggleExpandable = () => {
        setExpandable(!expandable)
    }

    const updateAddressBtnClicked = () => {
        setLoading(true);
        let address = addressField.unit + ' ' + addressField.street + ' ' + addressField.address2 + ' ' + addressField.zip + ' ' + addressField.city + ' ' + addressField.state + ' ' + addressField.country;
        Geocode.fromAddress(address).then(
            (response) => {
                const coordinate = '{"lon":'+parseFloat(response.results[0].geometry.location.lng)+',"lat":'+parseFloat(response.results[0].geometry.location.lat)+'}';
                const { lat, lng } = response.results[0].geometry.location;
                setAddressField({ ...addressField, coordinate: coordinate});
                let postData = {
                    name: addressField.name,
                    unit: addressField.unit,
                    street: addressField.street,
                    // address1: addressField.address1,
                    address2: addressField.address2,
                    zip: addressField.zip,
                    city: addressField.city,
                    state: addressField.state,
                    country: addressField.country,
                    coordinate: {
                        lon: lng,
                        lat: lat,
                    },
                    username: addressField.username,
                    email: addressField.email,
                    mobile: addressField.mobile,
                }
                putUrl(`/address/${id}`, postData).then(response => {
                    if(response.status) {
                        setInputErrors({});
                        dispatch(updateAddresses(response.data));
                    } else {
                        setInputErrors(response.errors);
                    }

                    if(response.message) {
                        addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                    }
                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                    addToast(JSON.stringify(err), { appearance: 'error' });
                })
            },
            (error) => {
                setLoading(false);
                addToast(JSON.stringify(error), { appearance: 'error' });
            }
        );
    }

    const deleteAddress = () => {
        deleteUrl(`/address/${id}`).then(response => {
            if(response.status) {
                setInputErrors({});
                dispatch(updateAddresses(response.data));
            } else {
                setInputErrors(response.errors);
            }

            if(response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleChange = ({ target }) => {
        setAddressField({ ...addressField, [target.name]: target.value });
    }

    // console.log("addressField", addressField);

    return (
        <Accordion expanded={expandable} onChange={toggleExpandable}>
            <AccordionSummary
                expandIcon={<BsChevronDown />}
                aria-controls={`address-${id}-content`}
                id={`address-${id}-header`}
                // className={classes.summaryContainer}
                classes={{ content: classes.summaryContainer }}
            >
                <Typography sx={{ width: '20%', flexShrink: 0}}>
                    { name }
                </Typography>
                <Typography sx={{ width: '70%', color: 'text.secondary' }} noWrap>{ full_address }</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.formContainer} noValidate>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Label"
                                value={addressField.name}
                                autoComplete="name"
                                name="name"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                            <FormHelperText>You can write your label as Home or Office.</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Recipient Name"
                                value={addressField.username}
                                autoComplete="name"
                                name="username"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.username ? true : false}
                                helperText={inputErrors?.username || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Recipient Email"
                                value={addressField.email}
                                autoComplete="email"
                                name="email"
                                type="email"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Recipient Mobile"
                                value={addressField.mobile}
                                autoComplete="mobile"
                                name="mobile"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.mobile ? true : false}
                                helperText={inputErrors?.mobile || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.unit') }
                                value={addressField.unit}
                                autoComplete="unit"
                                name="unit"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.unit ? true : false }
                                helperText={inputErrors?.unit || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.street') }
                                value={addressField.street}
                                autoComplete="street"
                                name="street"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.street ? true : false }
                                helperText={inputErrors?.street || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rows={2}
                                multiline
                                label={ t('address.address1') }
                                value={addressField.address1}
                                autoComplete="address-line1"
                                name="address1"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.address1 ? true : false }
                                helperText={inputErrors?.address1 || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rows={2}
                                multiline
                                label={ t('address.address2') }
                                value={addressField.address2}
                                autoComplete="address-line2"
                                name="address2"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.address2 ? true : false }
                                helperText={inputErrors?.address2 || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.zip') }
                                value={addressField.zip}
                                autoComplete="postal-code"
                                name="zip"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.zip ? true : false }
                                helperText={inputErrors?.zip || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.city') }
                                value={addressField.city}
                                autoComplete="address-level2"
                                name="city"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.city ? true : false }
                                helperText={inputErrors?.city || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.state') }
                                value={addressField.state}
                                autoComplete="address-level1"
                                name="state"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.state ? true : false }
                                helperText={inputErrors?.state || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('address.country') }
                                value={addressField.country}
                                autoComplete="country-name"
                                name="country"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.country ? true : false }
                                helperText={inputErrors?.country || ''}
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                { edit ?
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={ toggleEdit }
                                        >
                                            { t('general.cancel') }
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={ updateAddressBtnClicked }
                                            disabled={ loading ? true : false }
                                        >
                                            { loading ? 
                                                <CircularProgress size={20} />
                                            : t('general.update') }
                                        </Button>
                                    </>
                                    
                                : <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                                    <Button
                                        variant="contained"
                                        color="error"
                                        size="large"
                                        onClick={ deleteAddress }
                                    >
                                        { loading ? 
                                            <CircularProgress size={20} />
                                        : t('general.delete') }
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        className={classes.submit}
                                        onClick={ toggleEdit }
                                    >
                                        { t('general.edit') }
                                    </Button>
                                </Box> }
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    )
});

export default AddressAccordion;

const useStyles = makeStyles((theme) => ({
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginLeft: "10px !important",
    },
    summaryContainer: {
        width: '100%',
        paddingRight: 20,
        marginRight: "-20px !important",
    }
}))