import React, { PureComponent } from "react";

class Scenario extends PureComponent {
  openModal = () => {
    const { content, handleOpenModal, title, image } = this.props;
    handleOpenModal({ content, title, image });
  };

  render = () => (
      <div className="scenario" onClick={this.openModal} style={{cursor:'pointer', display: "flex", justifyContent: "center"}}>
        <img alt="Black Bixon LatestNews" src={this.props.image.file_name} style={{/* width: "80%" */ width: 240, height: 328}}/>
      </div>
  );
}

export default Scenario;