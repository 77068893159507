import React from 'react'

import { Box, Container } from '@mui/material'
import AccountSection from './AccountSection';
import AddressSection from './AddressSection';
import PasswordSection from './PasswordSection';

const Profile = () => {
    return (
        <Box padding={2}>
            <Container maxWidth="lg">
                <AccountSection />
                <PasswordSection />
                <AddressSection />
            </Container>
        </Box>
    )
}

export default Profile;