import React, { memo } from 'react';

import { Box, Button, Typography, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const LocateUsButton = memo(({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" className={classes.infoStyle} justifyContent="center" alignItems="center">
            {
                _.size(data) > 0 && _.size(data.buttons) > 0 && _.size(data.buttons[0].text) ?
                    <>
                        {
                            _.map(data.buttons, (item, index) => {
                                if (!_.size(item.text)) return false;
                                return (
                                    <Link component={RouterLink} to={item?.link ? item?.link:"/"} underline="none" key={index}>
                                        <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                            <Typography className={classes.buttonTextStyle}>{item.text}</Typography>
                                        </Button>
                                    </Link>
                                )
                            })
                        }
                    </>
                    :
                    <>
                        <Link component={RouterLink} to="/menu" underline="none">
                            <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                <Typography className={classes.buttonTextStyle}>{t('general.seeMenu')}</Typography>
                            </Button>
                        </Link>
                        {/* <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                            <Typography className={classes.buttonTextStyle}>{t('general.orderNowThroughDeliveries')}</Typography>
                        </Button> */}
                    </>
            }
        </Box>
    )
});

export default LocateUsButton;

const useStyles = makeStyles((theme) => ({
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom:'5px !important',
        backgroundColor: theme.palette.button.main+'!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main+'!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    }
}))