import { getUrl } from '@helpers/api';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box, Link, Typography, Grid, TextField, MenuItem, InputAdornment, IconButton,Container , Backdrop, CircularProgress } from '@mui/material';
import { FiArrowLeft,FiRefreshCw } from "react-icons/fi";
import RefreshIcon from '@mui/icons-material/Refresh';
import styled from "styled-components";

import CartStepper from '@components/CartStepper';
import CartItemCard from './CartItemCard';
import CustomerInfo from './CustomerInfo';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

const Checkout = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const { accessToken } = useSelector(state => state.general);
    const { name, email, mobile, addresses } = useSelector(state => state.user);
    const [loading, setLoading] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    const [carts, setCarts] = useState({});
    const [buyerInfo, setBuyerInfo] = useState({
        name: name ? name : '',
        email: email ? email : '',
        mobile: mobile ? mobile : '',
        address_id: _.size(addresses) > 0 ? addresses[0].id : '',
    });
    const [orderInfo, setOrderInfo] = useState({
        type: 'pickup',
        time: moment(),
        dateTime:'',
        // time_error: '',
    });
    const [timeSlots, setTimeSlots] = useState({});
    const [deliveryFeeInfo, setDeliveryFeeInfo] = useState({});
    const [paymentLoading , setPaymentLoading]=useState(false);
    const [storeTime, setStoreTime]=useState({
        storeId:1,
        time:'',
    })

    const type = useMemo(() => ([
        // { code: 'delivery', label: "Delivery" },
        { code: 'pickup', label: "Pick up" },
    ]), []);

    useEffect(() => {
        setLoading(true);
        refreshCart();
        // eslint-disable-next-line
    }, [accessToken]);

    useEffect(() => {
        if (buyerInfo.email !== email) {
            setBuyerInfo({
                ...buyerInfo,
                name: name ? name : '',
                email: email ? email : '',
                mobile: mobile ? mobile : '',
                address_id: _.size(addresses) > 0 ? addresses[0].id : '',
            })
        }
        // eslint-disable-next-line
    }, [buyerInfo, email]);

    // useEffect(() => {
    //     const interval = setInterval(() => setOrderInfo(prevState => ({ ...prevState, time: moment().format('YYYY-MM-DD hh:mm A') })), 20000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, []);

    useEffect(() => {
        setLoading(true);
        getUrl(`/member-get-store-time/${storeTime.storeId}`).then(response => {
            setLoading(false);
            if (response.status) {
                setStoreTime({...storeTime, time:response.data.operation_hours});
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);

    const refreshCart = () => {
        getUrl(`/carts`, { store_id: 1 }).then(response => {
            setLoading(false);
            if (response.status) {
                setCarts(response.data.listing);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const createTimeSlot = (fromTime, toTime) => {
        let startTime = moment(fromTime, 'HH:mm');
        let endTime = moment(toTime, 'HH:mm');
        if (endTime.isBefore(startTime)) {
            endTime.add(1, 'day');
        }
        let arr = [];
        while (startTime <= endTime) {
            arr.push(new moment(startTime).format('HH:mm'));
            startTime.add(15, 'minutes');
        }
        return arr;
    }

    useEffect(() => {
        if(_.size(storeTime.time)> 0){
            setTimeSlots(createTimeSlot(storeTime?.time?.start, storeTime?.time?.end))
        }
    }, [storeTime.time]);

    useEffect(() => {
        if (_.size(timeSlots)) {
            validateTime();
        }
    }, [timeSlots]);

    // const handleTimeChange = (target) => {
    //     console.log("target",target)
    //     let endTime=moment(storeTime.time.end, 'HH:mm');
    //     let startTime=moment(storeTime.time.end, 'HH:mm');
    //     let now=moment().format('LT');
    //     let currentTime=moment(now, 'HH:mm');
    //     let value=moment((target.format('HH:mm')), 'HH:mm');

    //     console.log("value",value)
        
    //     if (!value.isAfter(startTime) && !(moment(value).isBetween(currentTime, endTime))) {
    //         addToast(t('error.invalidTime'), { appearance: 'error' });
    //     } else {
    //         let dateTime = (moment().format('ll')) + " " + moment(target, ["HH:mm"]).format("hh:mm a");
    //         setOrderInfo({ ...orderInfo, time: target, dateTime: dateTime });
    //     }
    // }

    const validateTime = (data)=>{
        let current=  _.size(data) > 0? data.format('HH:mm'):moment().format('HH:mm');
        let current_date =moment().format('L');
        let initial_value={};
        // console.log("current time", current)
        // console.log("time slot", timeSlots)
        if (_.size(timeSlots)) {
            let validTime = timeSlots.filter((v) => (current <= v))

            // console.log("selectedtime", validTime)
            // console.log("current date", moment().format('L'))

            initial_value = current_date;
            // console.log("initial value", initial_value);
            if (_.size(validTime) > 0) {
                let now = moment().format('HH:mm');
                if(moment(moment(new Date(), 'hh:mm A')).isAfter(moment(storeTime?.time?.start, 'hh:mm A')) && moment(moment(new Date(), 'hh:mm A')).isBefore(moment(storeTime?.time?.end, 'hh:mm A'))){
                    if (validTime[0] > now) {
                        initial_value = current_date + ' ' + validTime[0]
                        setOrderInfo({ ...orderInfo, time: validTime[0], dateTime: initial_value });
                    } else {
                        addToast(t('error.invalidTime'), { appearance: 'error' });
                        setOrderInfo({ ...orderInfo, time: '', dateTime: '' });
                    }
                }
            } 
            // else{
            //     initial_value=moment().clone().add(1, 'days').format('L')+' '+timeSlots[0];
            // }
            // console.log("initial value after add time", initial_value);
        }
    }


    return (
        <Box className={classes.paper}>
            <Container maxWidth="md">
                <Link underline='none' component={RouterLink} to={`/cart`}>
                    <div className={classes.flexMiddle} style={{ paddingBottom: 30 }}>
                        <FiArrowLeft className={classes.backToArrowStyle} />
                        <Typography className={classes.backToStyle} >Back To Cart</Typography>
                    </div>
                </Link>
                {/* CART STEPPER */}
                <CartStepper activeStep={1} />
                {/* ERROR DISPLAY */}
                {
                    _.size(inputErrors) > 0
                        ?
                        <Box className={classes.inputErrorBox}>
                            {
                                _.map(inputErrors, (err, key) => {
                                    return (
                                        <Typography key={key} color="error">{err}</Typography>
                                    )
                                })
                            }
                        </Box>
                        :
                        null
                }
                <Box className={classes.outlineBox}>
                    <Typography variant="h6" style={{ marginBottom: 10 }}>Order Info</Typography>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item xs={12} md={4}>
                        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item xs={2}>
                                    <Typography variant="caption">Type :</Typography>
                                </Grid>
                                <Grid item xs={10}>
                                <TextField
                                select
                                // label="Type"
                                value={orderInfo.type || ''}
                                fullWidth
                                name="type"
                                onChange={(event) => setOrderInfo({ ...orderInfo, [event.target.name]: event.target.value })}
                                required
                            >
                                {
                                    type.map(menuItem => {
                                        return (
                                            <MenuItem key={menuItem.code} value={menuItem.code}>{menuItem.label}</MenuItem>
                                        )
                                    })
                                }
                            </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {
                                storeTime?.time &&
                                <div>
                                    {
                                        moment(moment(new Date(), 'hh:mm A')).isAfter(moment(storeTime.time.start, 'hh:mm A')) && moment(moment(new Date(), 'hh:mm A')).isBefore(moment(storeTime.time.end, 'hh:mm A')) ?
                                            // <TextField
                                            //     label="Time"
                                            //     variant="outlined"
                                            //     value={orderInfo.time}
                                            //     InputLabelProps={{ shrink: true }}
                                            //     // helperText={inputErrors && inputErrors['operation_hours.start'] ? inputErrors['operation_hours.start'] : ''}
                                            //     // error={inputErrors && inputErrors['operation_hours.start'] ? true : false}
                                            //     // onChange={({ target }) => setOrderInfo({ ...orderInfo, time:target.value })}
                                            //     onChange={handleTimeChange}
                                            //     type="time"
                                            //     inputProps={{
                                            //         step: 900, // 15 min
                                            //     }}
                                            //     required
                                            //     fullWidth
                                            // /> 
                                            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                                <Grid item xs={2}>
                                                    <Typography variant="caption">Time :</Typography>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TimePicker
                                                        showSecond={false}
                                                        minuteStep={15}
                                                        onChange={validateTime}
                                                        value={moment(orderInfo.time, 'HH:mm')}
                                                        clearText="Clear"
                                                        style={{ width: '100%' }}
                                                        popupClassName={classes.popup}
                                                        className={classes.inputTime}
                                                        name="Time"
                                                    />
                                                </Grid>
                                            </Grid>
                                            :
                                            <Typography variant="body2" style={{ coloe: theme.palette.primary.main }}>Cafe had been closed. Please place order in operation hour</Typography>
                                    }
                                </div>
                            }
                            {/* {
                                moment(moment(new Date(), 'hh:mm A')).isAfter(moment(open_time, 'hh:mm A')) && moment(moment(new Date(), 'hh:mm A')).isBefore(moment(close_time, 'hh:mm A')) ?
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <MobileDateTimePicker
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    required
                                                    fullWidth
                                                    InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => validateTime()}
                                                                edge="end"
                                                            >
                                                                {_.size(orderInfo.time) ? <RefreshIcon /> : ""}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    }}
                                                    error={_.size(orderInfo.time_error) ? true : false}
                                                    helperText={_.size(orderInfo.time_error) ? orderInfo.time_error : ""}
                                                />}
                                            label="Time"
                                            value={orderInfo.time || null}
                                            onChange={handleTimeChange}
                                            minutesStep={15}
                                            disablePast
                                            mask='__/__/____ __:__ _M'
                                            // minTime={moment(new Date(0, 0, 0, 8), 'HH:mm')}
                                            // maxTime={moment(new Date(0, 0, 0, 22), 'HH:mm')}
                                            minDateTime={moment(new Date(),'L HH:mm')}
                                            DialogProps={{
                                                style:{
                                                    zIndex:999,
                                                }

                                            }}
                                            onError={(reason,value)=>{
                                                // console.log("time eror", reason)
                                                // reason is the error , which it will be displayed as
                                                // default error message ,you can also  pass your own error
                                                // message depending on the error
                                                if(reason){
                                                    setOrderInfo({...orderInfo, time_error:'The provided time is unavailable for now.' })
                                                    addToast('The provided time is unavailable for now.', { appearance: 'error' });
                                                }else{
                                                    setOrderInfo({...orderInfo, time_error:''})
                                                }
                                            }}
                                            shouldDisableTime={(timeValue, clockType)=>{
                                                if (clockType === "hours"){
                                                    return clockType === "hours" &&  (timeValue <=7 || timeValue >=22) ;
                                                }
                                            }}
                                            sx={{
                                                '& .MuiPickersToolbar-penIconButton': { display: 'none' },
                                            }}
                                            // readOnly
                                        />
                                    </LocalizationProvider>
                                    :
                                    <Typography variant="body2" style={{ coloe: theme.palette.primary.main }}>Cafe had been closed. Please place order in operation hour</Typography>
                            } */}
                        </Grid>
                    </Grid>
                </Box>
                {/* BUYER INFO & ADDRESS */}
                <CustomerInfo buyerInfo={buyerInfo} setBuyerInfo={setBuyerInfo} addresses={addresses} loading={loading} setLoading={setLoading} accessToken={accessToken} carts={carts} orderInfo={orderInfo} setDeliveryFeeInfo={setDeliveryFeeInfo} />
                {/* CART ITEM */}
                <CartItemCard carts={carts} setCarts={setCarts} buyerInfo={buyerInfo} loading={loading} setLoading={setLoading} inputErrors={inputErrors} setInputErrors={setInputErrors} deliveryFeeInfo={deliveryFeeInfo} orderInfo={orderInfo} refreshCart={refreshCart} setDeliveryFeeInfo={setDeliveryFeeInfo}  setPaymentLoading={setPaymentLoading} storeTime={storeTime} />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={paymentLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        </Box>
    )
}

export default Checkout;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    backToArrowStyle: {
        color: theme.palette.gray.main,
        fontSize: "16px !important",
        marginRight: 10
    },
    backToStyle: {
        color: theme.palette.gray.main,
        fontSize: "16px !important",
        textTransform: 'uppercase'
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
    inputErrorBox: {
        margin: 30
    },
    outlineBox: {
        margin: "20px !important"
    },
    popup:{
        "&.rc-time-picker-panel":{
            zIndex:"990 !important",
        },
        "& .rc-time-picker-panel-select-option-selected":{
            background:theme.palette.primary.main+"! important",
            color:"white !important"
        },
        "& .rc-time-picker-panel-select li:hover":{
            background:theme.palette.secondary.main+"!important",
        },
        "& .rc-time-picker-panel-input":{
            cursor:"pointer !important",
            color:"black !important",
        },
    },
    inputTime:{
        "& .rc-time-picker-input":{
            color:"black !important",
            paddingLeft:"16.5px !important",
            paddingRight:"16.5px !important",
            paddingTop:"25px !important",
            paddingBottom:"25px !important",
            fontSize:'16px !important',
        },
        "& .rc-time-picker-clear-icon:after":{
            color:"black !important"
        },
        "& .rc-time-picker-clear":{
            paddingLeft:"16.5px !important",
            paddingRight:"16.5px !important",
            paddingTop:"10px !important",
            paddingBottom:"20px !important",
            fontSize:'16px !important',
        }
    }
}))
