import React, { memo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Alert, Container, Box, Button, Card, Grid, CardContent, TextField, Typography, CircularProgress } from '@mui/material';
import { useToasts } from 'react-toast-notifications';
import { makeStyles } from '@mui/styles';

import { requestForgotPassword } from '@actions/generalActions';
import { postUrl } from '@helpers/api';

const ForgotPassword = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [forgotPasswordField, setForgotPasswordField] = useState({
        email: ''
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const emailRef = useRef();

    const forgotPasswordClicked = () => {
        if(_.size(forgotPasswordField.email) <= 0) {
            emailRef.current.focus();
            addToast(t('forgotPassword.required.email'), { appearance: 'error' });
        } else {
            setLoading(true);
            postUrl('forgot-password', forgotPasswordField).then(response => {
                if(!response.status) {
                    if(_.hasIn(response, 'errors')) {
                        setInputErrors(response.errors);
                    }
                } else {
                    setForgotPasswordField({ email: '' });
                    setSuccess(true);
                }
    
                if(response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }

    const handleChange = ({ target }) => {
        setForgotPasswordField({ ...forgotPasswordField, [target.name]: target.value });
    };

    return (
        <Container component="main" maxWidth="md">
            <Card className={classes.root} variant="elevation" elevation={3}>
                <CardContent className={classes.cardContentRoot}>
                    <Typography component="h1" variant="h5">
                        { t('title.forgotPassword') }
                    </Typography>
                    <div className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                { success && <Alert severity="success">{ t('forgotPassword.sendSuccess') }</Alert> }
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    ref={emailRef}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={ t('forgotPassword.email') }
                                    placeholder={ t('forgotPassword.placeholder') }
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={forgotPasswordField.email}
                                    onChange={handleChange}
                                    error={inputErrors?.email ? true : false }
                                    helperText={inputErrors?.email || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="flex-end">
                                    <Button
                                        type="submit"
                                        disabled={ loading ? true : false }
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={ forgotPasswordClicked }
                                        // style={{ fontFamily:'OregonLdoRegular'}}
                                    >
                                        {
                                            loading ? 
                                            <CircularProgress size={20} />
                                            : t('general.submit')
                                        }
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}

export default ForgotPassword;

const useStyles = makeStyles((theme) => ({
    cardContentRoot: {
        padding: theme.spacing(4)
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}));