import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Box, Link, Container, Card, CardContent, Avatar, Typography, TextField, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LockRounded } from '@mui/icons-material';
import { useToasts } from 'react-toast-notifications';

import { changeLoginState } from '@actions/generalActions';
import { postUrl } from '@helpers/api';

const INITIAL_STATE = {
    email: '',
    password: '',
    type: 'web'
}

const SignIn = () => {
    const [loginField, setLoginField] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const loginBtnClicked = () => {
        if(_.size(loginField.email) <= 0) {
            emailRef.current.focus();
            addToast(t('auth.required.email'), { appearance: 'error' });
        } else if(_.size(loginField.password) <= 0) {
            passwordRef.current.focus();
            addToast(t('auth.required.password'), { appearance: 'error' });
        } else {
            setLoading(true);
            postUrl('login', loginField).then(response => {
                setLoading(false);
                let message = response.message;
                if(!response.status) {
                    if(_.hasIn(response, 'errors')) {
                        _.map(response.errors, item => {
                            addToast(item, { appearance: 'error' });
                        })
                    }
                } else {
                    dispatch(changeLoginState(response.data));
                    setLoginField(INITIAL_STATE);
                }

                if(message) {
                    addToast(message, { appearance: response.status ? 'success' : 'error' });
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    };

    const handleChange = (event) => {
        setLoginField({ ...loginField, [event.target.name]: event.target.value });
    };

    const passwordKeyPress = e => {
        if(e.keyCode === 13){
            loginBtnClicked();
        }
    }

    const emailRef = useRef();
    const passwordRef = useRef();

    return (
        <Container component="main" maxWidth="sm">
            <Card className={classes.root} variant="elevation" elevation={3}>
                <CardContent className={classes.cardContentRoot}>
                    <div className="paper-container">
                        <Avatar className={classes.avatar}>
                            <LockRounded />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            { t('auth.signIn') }
                        </Typography>
                        <div className={classes.form} noValidate>
                            <TextField
                                ref={emailRef}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={ t('auth.email') }
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={loginField.email}
                                onChange={ handleChange }
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                            <TextField
                                ref={passwordRef}
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                name="password"
                                label={ t('auth.password') }
                                type="password"
                                autoComplete="current-password"
                                value={loginField.password}
                                onChange={ handleChange }
                                onKeyDown={ passwordKeyPress }
                                // InputLabelProps={{
                                //     style: { fontFamily: 'OregonLdoDemibold' }
                                // }}
                            />
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label={ t('auth.rememberMe') }
                            /> */}
                            <Box paddingY={2}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.submit}
                                    onClick={ loginBtnClicked }
                                    disabled={ loading ? true : false }
                                    // style={{ fontFamily:'OregonLdoRegular'}}
                                >
                                    {
                                        loading ? 
                                        <CircularProgress size={20} />
                                        : t('auth.signIn')
                                    }
                                </Button>
                            </Box>
                            <Box paddingY={2} display="flex" justifyContent="space-between">
                                <Link component={RouterLink} to="/forgot-password">
                                    { t('auth.forgotPassword') }?
                                </Link>
                                <Link component={RouterLink} to="/sign-up">
                                    { t('auth.noAccountSignUp') }
                                </Link>
                            </Box>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}

export default SignIn;

const useStyles = makeStyles((theme) => ({
    cardContentRoot: {
        padding: theme.spacing(4)
    },
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(4, 0, 4),
    },
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));