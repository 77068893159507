import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react'
import _ from 'lodash';

const HomeAboutUs = memo(({data}) => {
    const classes = useStyles();

    return (
        // _.size(data) >0 &&  (data.text1 || data.text2) ?
        //     <Box display="flex" flexDirection="row" padding={2} paddingTop={4} component='div'>
        //         <Box flex={1}>
        //             <div  dangerouslySetInnerHTML={{ __html: data.text1 }} />
        //         </Box>
        //         <Box flex={2}>
        //             <div dangerouslySetInnerHTML={{ __html: data.text2 }} />
        //         </Box>
        //     </Box>
        // :
            <Box display="flex" flexDirection="row" padding={2} paddingTop={4}>
                <Box flex={1}>
                    <Typography variant="h4" className={classes.titleStyle}>Beyond</Typography>
                    <Typography variant="h4" className={classes.titleStyle}>your</Typography>
                    <Typography variant="h4" className={classes.titleStyle}>typical</Typography>
                    <Typography variant="h4" className={classes.titleStyle}>café</Typography>
                </Box>
                <Box flex={2}>
                    <Typography paragraph={true} variant="body1" className={classes.paragraphStyle}>Here at BlackBixon Café & Restaurant, we are a call to warmth, a space that brings people together, the ritual of sitting down in solitude, and the excuse for one to slow down.</Typography>
                    <Typography paragraph={true} variant="body1" className={classes.paragraphStyle}>That's the magic of what BlackBixon Café can do.</Typography>
                    <Typography paragraph={true} variant="body1" className={classes.paragraphStyle}>From our everyday Menu, we serve freshly brewed coffee, delectable Danish pastries to burgers, pastas & food that makes you feel good.</Typography>
                </Box>
            </Box>
    )
});

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        fontFamily:'OregonLdo !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.8rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem !important',
        },
    },
    paragraphStyle: {
        // fontFamily: 'NuezeitGroteskLight !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.9rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
    }
}));

export default HomeAboutUs;
