import * as actionType from '@types/generalType';

const INITIAL_STATE = {
    isLogin: false,
    accessToken: null,
    userId: null,
    role: null,
    permissions: null,
    defaultLanguage: "en",
    cartTotal: 0,
    uuid:'',
};

export default function authReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionType.LOGOUT_USER:
            return { ...INITIAL_STATE, defaultLanguage: state.defaultLanguage };
        case actionType.CHANGE_LOGIN_STATE:
            const { access_token, permissions, role } = action.payload;
            return {
                ...state,
                accessToken: access_token,
                role,
                permissions,
                isLogin: true,
            };
        case actionType.CHANGE_DEFAULT_LANGUAGE:
            return { ...state, defaultLanguage: action.payload };
        case actionType.CHANGE_CART_STATE:
            return { ...state, cartTotal: action.payload };
        case actionType.STORE_UUID:
            return { ...state, uuid: action.payload };
        case actionType.REMOVE_UUID:
            return { ...state, uuid: '' };
        default:
            return state;
    }
}