const english = {
    header: {
        language: "Language",
    },
    footer:{
        menu:"Menu",
        reservation:"Reservation",
        career:"Career",
        followUs:"Follow Us",
        tnc:"Website Accessibility Statement",
        policy:"Cancellation & Refund Policy",
        faq:'FAQ',
    },
    slider:{
        sliderText1:"BlackBixon Flagship Cafe in Malaysia",
        sliderText2:"Once you Bite, You'll be back.",
        sliderText3:"Great Coffee, Food & Good Vibes",
    },
    auth: {
        signIn: "Sign In",
        signOut: "Sign Out",
        signUp: "Sign Up",
        username: "Username",
        email: "Email",
        name: "Name",
        ref: "Referral",
        password: "Password",
        password_confirmation: "Password Confirmation",
        securityPassword: "Security Password",
        confirmSecurityPassword: "Re-type Security Password",
        
        rememberMe: "Remember Me",
        forgotPassword: "Forgot Password",
        noAccountSignUp: "Don't have an account? Sign Up",
        required: {
            email: "Please enter your email",
            password: "Please enter your password",
        }
    },
    forgotPassword: {
        placeholder: "Please insert your email",
        email: "Email",
        required: {
            email: "Please insert your email"
        },
        sendSuccess: "We've sent you your reset password link, please check your inbox and reset your password within 60 minutes."
    },
    resetPassword: {
        email: "Email",
        password: "New Password",
        password_confirmation: "Re-type New Password",
        required: {
            password: "Please insert your new password",
            password_confirmation: "Please insert your confirm new password",
        },
        errors: {
            notSame: "Your password does not match with your confirmation password"
        },
        loginNow: "Login Now",
        notValid: "Your reset password link doesn't meet our server credential or already expired, please try to request a reset password link again.",
        resetSuccess: "Your password is successfully changed, you may now proceed login with your new password."
    },
    title: {
        home: "Home",
        profile: "Profile",
        forgotPassword: "Forgot Password",
        resetPassword: "Reset Password",
        ourStory:"Our Story",
        locateUs:"Locate Us",
        coffeePackage:"Coffee Package",
        contactUs:"Contact Us",
        menu: "Menu",
        reservation: "Reservation",
        gallery: "Gallery",
        latestNews: "Latest News",
        career:"Career",
        cart:"Cart",
        faq:"BLACKBIXON CAFÉ  FAQs",
        tnc: "Website Accessibility Statement",
        policy: "Cancellation & Refund Policy",
        order:"Order",
        queue:"Queue",
        faqShort: "FAQ",
        cafeTitle: "BLACKBIXON CAFÉ & RESTAURANT",
    },
    user: {
        profile: "Profile",
        username: "Username",
        name: "Name",
        email: "Email",
        mobile: "Mobile",
        ic: "IC",
        password: "Password",
        security_password: "Security Password",
        old: "Old {{field}}",
        new: "New {{field}}",
        confirm: "Re-type new {{field}}",
        addresses: "Addresses"
    },
    address: {
        name: "Name",
        address1: "Address 1",
        address2: "Address 2",
        zip: "Zip",
        state: "State",
        city: "City",
        country: "Country",
        unit: "Floor/Unit",
        street: "Street"
    },
    general: {
        goHome: "Go Home",
        notFound: "404 - Not Found!",
        submit: "Submit",
        update: "Update",
        error: "There's an error in the process.",
        copiedToClipboard: "Copied to clipboard",
        pleaseSelect: "Please Select",
        purchase:"Purchase",
        seeMenu:"See Menu",
        orderNowThroughDeliveries:"Order Now Through Deliveries",
        itemNotFound: "Item Not Found",
        edit: "Edit",
        cancel: "Cancel",
        create: "Create",
        delete: "Delete",
        applyHere:"Apply Now",
        seeOurMenu:"See Our Menu",
        orderDelivery:"Order Delivery",
        visitUs:"Visit Us",
        ourMenu:"Our Menu",
        orderNow:"Order Now",
        dineIn:"Dine-In",
        delivery:"Delivery",
        viewMore:"View More",
        viewLess:"View Less",
        readMore:"Read More",
        readLess:"Read Less",
        addToCart:"Add To Cart",
        updateCart:"Update Cart",
        checkout:"Checkout",
        deleteDescription:"Are you sure want to remove this ?",
        confirm:"Confirm",
        all:"All",
        subscribe:"Subscribe",
        reservation:"Reservation",
    },
    error:{
        noCareer:"No Career.",
        optionMax:"This option reached maximum number.",
        noCart:"Opps, no product in cart~",
        maxDistance:"Sorry, only for delivery within 2KM",
        invalidTime:"Unable to place order at the selected time."
    },
    profile: {
        changeProfile: "Change Profile",
        changePassword: "Change Password",
        changeSecurityPassword: "Change Security Password",
    },
    transaction: {
        receivedFrom: "Received from",
        transferTo: "Transfer to",
        transactionDate: "Transaction Date",
        transactionDetail: "Transaction Detail",
        amount: "Amount",
        balance: "Balance",
        remark: "Remark",
    },
    datatable: {
        noMatch: "Sorry, no matching records found",
        next: "Next",
        previous: "Previous",
        rowsPerPage: "Rows per page",
        displayRows: "of",
    },
    home: {
        walletWeeklyBalance: "Your {{wallet}} balance for past week"
    },
    contactUs:{
        subtitle:"For enquiries and/or feedback",
        fullName:"Name",
        contactNumber:"Phone",
        email:"Email",
        title:"What would you like to contact us about?",
        detailTitle:"Message",
        detail:"Please leave your message here",
        address:"Address",
        googleMap:"Google Maps",
        waze:"Waze"
    },
    coffeePackage:{
        promotionPackage:"Promotion Package",
        starterPackage:"Starter Package",
        outrightPackage:"Outright Package",
        coffeeCapsules:"Coffee Capsules"
    },
    product:{
        bbSignature:"BB signature",
        mustTry:"Must Try",
        vegetarian:"Vegetarian",
        basicPrice:"Basic Price",
        remark:"Remark",
        remarkText:"Add your request",
        quantity:"Quantity",
        totalPrice:"Total Price",
        max: "Max {{value}}",
        outOfStock:"Out Of Stock",
    },
    payment: {
        success: "Payment Successful",
        pending: "Payment Pending",
        fail: "Payment Failed",
        successContent: "Your order has been added to the list~",
        pendingContent: "The system will add the order to the list as soon as the payment is completed.",
        failContent: "There was a problem with the payment, please contact customer service for more details.",
    },
    queue:{
        name:"Name",
        position:"Position",
        size:"Size",
        email:"Email",
        mobile:"Mobile",
        cancel:"Cancel Queue",
        refresh:"Refresh Status",
        submit:"Submit",
        yes:"Yes",
        no:"No",
        cancel_queue:"Are you SURE you want to cancel the queue?",
        called:"Its your turn now! Please come to our restaurant in 15 minutes or you will have to re-queue!",
    }
};

export default english;