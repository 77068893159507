import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import HomeCarousel from '../Home/HomeCarousel';
import HomeLocateUs from '../Home/HomeLocateUs';

const NewCarrer = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box>
            <HomeCarousel data={[]}/>
            <Container className="app-container" maxWidth="lg">
                <Box style={{ margin: 30 }}>
                    <p className={classes.textStyle}>
                        We're on the hunt for individuals who are ready to spice up their careers in the world of F&B and Hospitality. At BlackBixon, we're all about assembling a team of passionate, energetic, and downright awesome team members ready to craft unforgettable dining experiences and spread some serious joy.
                    </p>
                    <p className={classes.textStyle}>
                        If you've got that fire in your body to make people's days brighter, a thirst for knowledge, and a knack for creating magical moments, we want to hear from you!
                    </p>
                    <p className={classes.textStyle}>
                        Drop us a line with your CV at blackbixoncafe@gmail.com, or better yet, just click on that photo below to fill out our quick and easy form. Oh, and if you have any career questions, hit us on WhatsApp - we're here to chat!
                    </p>
                </Box>
                <Box style={{ margin: 30 }}>
                    <Grid container direction="row" spacing={2}>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Baker</p>
                                <img alt="bakers" src={`../images/career/bakers.jpg`} width="100%" loading='lazy' />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Front House</p>
                                <img alt="front-of-house" src={`../images/career/front-of-house.jpg`} width="100%" loading='lazy' />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Waiter</p>
                                <img alt="hot-kitchen" src={`../images/career/hot-kitchen.jpg`} width="100%" />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Barista</p>
                                <img alt="barista" src={`../images/career/barista.jpg`} width="100%" loading='lazy' />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Pastry Chef</p>
                                <img alt="pastry-chef" src={`../images/career/pastry-chef.jpg`} width="100%" loading='lazy' />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Box style={{ position: "relative" }}>
                                <p className={classes.imageTextStyle}>Headquarters</p>
                                <img alt="headquarters" src={`../images/career/headquarters.jpg`} width="100%" loading='lazy' />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ margin: 30 }}>
                    <p className={classes.textStyle}>
                        Become a part of the BlackBixon magic and embark on an extraordinary adventure. Your path to awesomeness starts here!
                    </p>
                    <p className={classes.textStyle}>
                        P.S. Can't find the perfect job on our menu? No worries! Shoot us an email at blackbixoncafe@gmail.com, and we'll whip up something special, just for you!
                    </p>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.infoStyle} justifyContent="center" alignItems="center">
                    <Link rel="noopener noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSeojgduscyR05XmC1qHc4pIAMbMXXFPVWwNKv-NeqyP14zleA/viewform" target="_blank" underline="none">
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                            <Typography className={classes.buttonTextStyle}>{t('general.applyHere')}</Typography>
                        </Button>
                    </Link>
                </Box>
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}

export default NewCarrer

const useStyles = makeStyles((theme) => ({
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    },
    textStyle: {
        marginBottom: 20,
        textAlign: "center",
        [theme.breakpoints.up('xs')]: {
            fontSize: '.9rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
    },
    imageTextStyle: {
        color: "#fff",
        position: "absolute",
        [theme.breakpoints.up('xs')]: {
            fontSize: '.9rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        fontWeight: "bold",
        bottom: 20,
        textAlign: "center",
        width: "100%"
    }
}))