import _ from 'lodash';
import React, { memo } from 'react';

import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

//slider
import 'swiper/css';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Microlink from '@microlink/react';


const NEWS = [
    'https://www.malaysianflavours.com/2022/09/blackbixon-imbi-kl-latte-croissant-in-black.html',
    'https://worldofbuzz.com/this-cafe-called-blackbixon-in-kl-is-serving-giant-croissant-daily/',
    'https://www.dagangnews.com/article/blackbixon-cafe-restaurant-dibuka-secara-rasmi-tawar-hidangan-klasik-barat-19013',
    'https://themalaysianreserve.com/2022/10/04/a-coffee-haven-in-the-heart-of-kl/',
    'https://thesmartlocal.my/giant-croissants/',
    'https://purpledurian.my/makan/dapatkan-croissant-gergasi-pertama-di-malaysia-hanya-di-blackbixon-cafe-restaurant/?expand_article=1',
    'https://discoverkl.com/2022/12/29/review-blackbixon-cafe-food-price-jalan-imbi-kuala-lumpur/',
    'https://says.com/my/makan/kl-cafe-serves-the-biggest-croissant-in-malaysia'
]

const HomeArticle = memo(() => {
    const classes = useStyles();

    return (
        <Box paddingTop={4}>
            <div style={{ paddingBottom:10}}>
                <Typography variant="h5" className={classes.titleStyle}>News & Article</Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', justifyContent: 'center' }}>
                <Swiper
                    spaceBetween={30}
                    slidesPerGroup={1}
                    loop={false}
                    pagination={{
                        clickable: true,
                        el: '.article-swiper',
                    }}
                    navigation={false}
                    modules={[Navigation, Pagination , Autoplay]}
                    slidesPerView={3}
                    breakpoints={{
                        300: {
                            slidesPerView: 1,
                        },
                        600: {
                            slidesPerView: 2,
                        },
                        900: {
                            slidesPerView: 3,
                        },
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                    }}
                    style={{ width: '-webkit-fill-available', padding: '0 20px' , marginBottom:20 }}
                >
                    {
                        _.map(NEWS, (data, dataIndex) => {
                            return (
                                <SwiperSlide key={dataIndex}>
                                    <Microlink url={data} size='large' lazy media={['image', 'logo']} />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
                <div className="article-swiper"/>
            </div>
        </Box>
    );
});

export default HomeArticle;

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "capitalize",
        fontFamily:'OregonLdo !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
}))