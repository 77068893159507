import React, { memo, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Box, Typography, Button, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getUrl } from '@helpers/api';

const HomeLocateUs = memo(({data}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [detail , setDetail] = useState({});

    useEffect(() => {
        if(_.size(data) === 0){
            getUrl(`/settings/cms/layout`, {"code": "home-page"}).then(result => {
                let temp_list = _.find(result.data.value.data , {'section':'locate-us'});
                setDetail(temp_list);
            }).catch((error) => {
                // addToast('', error.message || t('error.contactSupport'), 'error', '');
            })
        }else{
            setDetail(data)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Box paddingTop={4}>
            <div className="slide-img" style={{ width: '100%', position: 'relative' }}>
                {/* {_.size(detail) > 0 && _.size(detail.background) ?
                    <img className={classes.imageStyle} alt="Locate Us Black Bixon" src={data.background} />
                : */}
                    <img alt="Locate Us Black Bixon" className={classes.imageStyle} src="../images/home/home-locateus/locate-us.jpeg" />
                {/* } */}
                <div className={classes.buttonContainerStyle}>
                    <Link component={RouterLink} to="/locate-us" underline="none">
                        <Button variant="contained" className={classes.roundedButtonStyle}>
                            {/* <Typography className={classes.buttonTextStyle}>{_.size(detail) > 0 && _.size(detail.button) ? detail.button : t('title.locateUs')}</Typography> */}
                            <Typography className={classes.buttonTextStyle}>{t('title.locateUs')}</Typography>
                        </Button>
                    </Link>
                </div>
            </div>
        </Box>
    )
})

export default HomeLocateUs;

const useStyles = makeStyles((theme) => ({
    buttonContainerStyle: {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        left: '50%',
        marginLeft: -52,
        marginTop: -20,
    },
    imageStyle: {
        objectFit: 'cover',
        minHeight: '200px !important',
        maxHeight: '300px !important',
        width: '100%'
    },
    roundedButtonStyle: {
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main+'!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main+'!important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft:'5px !important',
        paddingRight:'5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
}))

