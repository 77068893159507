import { getUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import NumberFormat from 'react-number-format';

import { Box, Button, FormControl, Grid, InputLabel, LinearProgress, Link, MenuItem, Select, TextField, Typography,Container } from '@mui/material';

import { BsArrowRight } from "react-icons/bs";

const Order = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const [orders, setOrders] = useState({});
    const [statusList, setStatusList] = useState({});
    const [typeList, setTypeList] = useState({});
    const [refreshData, setRefreshData] = useState(false);

    let order_no = searchParams.get('order_no');
    let type = searchParams.get('type');
    let status = searchParams.get('status');
    const [searchField, setSearchField] = useState({
        order_no: order_no ? order_no : '',
        type: type ? type : 0,
        status: status ? status : 0,
    });

    useEffect(() => {
        setLoading(true);
        getUrl("/member-orders", searchField).then(response => {
            setLoading(false);
            if (response.status) {
                setOrders(response.data.listing);
                setStatusList(response.data.status_list);
                setTypeList(response.data.type_list);
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, [order_no, type, status, refreshData]);

    const searchData = () => {
        setSearchParams(searchField);
    }
    const clearSearchData = () => {
        setSearchParams();
        setSearchField({
            order_no: '',
            type: 0,
            status: 0,
        });
    }

    return (
        <Box className={classes.paper}>
            <Container maxWidth="md">
                {/* <BackToPrevious /> */}
                <Typography variant="h5" className={classes.titleStyle}>{t('title.order')}</Typography>
                {/* SEARCH */}
                <Grid container spacing={2} style={{ paddingBottom: 10 }}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Order No"
                                value={searchField.order_no}
                                name="order_no"
                                onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={searchField.type}
                                    label="Type"
                                    name="type"
                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                >
                                    <MenuItem value={0}>Please select type</MenuItem>
                                    {_.map(typeList, resultItem => {
                                        return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={searchField.status}
                                    label="Status"
                                    name="status"
                                    onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                >
                                    <MenuItem value={0}>Please select status</MenuItem>
                                    {_.map(statusList, resultItem => {
                                        return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} style={{ paddingTop:20}}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' } }} >
                            <Button variant="contained"  size="large" className={classes.buttonStyle} onClick={searchData}>Search</Button>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={3} md={1} style={{ paddingTop:20}}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Button variant="contained" size="large" className={classes.buttonStyle} onClick={clearSearchData}>Clear</Button>
                        </Box>
                    </Grid>
                </Grid>
                {/* DATA */}
                {
                    _.size(orders) > 0
                        ?
                        <Box style={{ paddingRight: "30px" }}>
                            {
                                _.map(orders, (order_list, purchase_no) => {
                                    let order_type =_.find(typeList , {code:order_list.type})
                                    let order_status =_.find(statusList , {code:order_list.status})
                                    let order_tracking_price = 0;
                                    if(_.size(order_list.order_trackings) > 0){
                                        order_tracking_price=order_list['order_trackings'][0]['price']['grand_total'];
                                    }
                                    return (
                                        <Grid style={{ marginTop: 15, marginBottom: 15, flex: 1, boxShadow: '10px 10px 8px #888888', border: '1px solid #808080', borderRadius: '15px', backgroundColor: '#ffffff',}} key={purchase_no} >
                                            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                                                <Grid item xs>
                                                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                                                        <Grid item xs style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}>
                                                            {/* <CardContent> */}
                                                            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                                                <Grid item style={{ paddingLeft: '0px', marginTop: '5px' }}>
                                                                    <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                                                        <Grid item>
                                                                            <Typography variant="h5" className={classes.titleStyle}>{order_list.order_no}</Typography>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <Typography variant="subtitle1" >Type: {order_type[i18n.language] ? order_type[i18n.language]:order_type['en'] }</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            {/* </CardContent> */}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item style={{ marginRight: 20 }}>
                                                    <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                                                        <Grid item>
                                                            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Typography variant="body1" className={classes.titleStyle}>{order_list.delivery_datetime}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                <Typography  className={classes.titleStyle}>Status : {order_status[i18n.language] ? order_status[i18n.language]:order_status['en'] }</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography className={classes.titleStyle}>
                                                                        <NumberFormat prefix="RM " value={parseFloat(parseFloat(order_list.total_amount) + parseFloat(order_tracking_price)).toFixed(2)} displayType="text" fixedDecimalScale={2} />
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item>
                                                            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                                                <Grid item>
                                                                    <Link component={RouterLink} to={`/order-detail/${order_list.order_no}`} underline="none">
                                                                    <Typography className={classes.titleStyle}>View More  <BsArrowRight size={10} /></Typography> 
                                                                    </Link>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }
                        </Box>
                        :
                        loading
                            ?
                            <Box style={{ marginTop: 20 }}>
                                <LinearProgress size={20} />
                            </Box>
                            :
                            <Box style={{ marginTop: 20, textAlign: "center" }}>
                                No Data
                            </Box>
                }
            </Container>
        </Box>
    )
}

export default Order;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginLeft: "20px !important",
        marginBottom: "20px !important"
    },
    buttonStyle: {
        backgroundColor: theme.palette.button.main + ' !important'
    },
    itemContainer: {
        margin: "20px !important",
        border: 'none',
        borderRadius: 10,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 1px 6px 3px #0000001f',
    },
}))