import React from 'react';
import { Dialog, DialogActions, Typography, DialogContent, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { BsX } from "react-icons/bs";

const DeleteConfirmationModal = ({ open, closeDeleteDialog,submitData, loading }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={closeDeleteDialog} fullWidth maxWidth="sm" style={{ zIndex: 999 }}>
            <DialogActions>
                <BsX onClick={closeDeleteDialog} style={{ cursor: 'pointer' }} scroll="true" size={30} />
            </DialogActions>
            <DialogContent>
                <Typography variant="h5" className={classes.titleStyle}>{t('general.deleteDescription')}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" className={classes.roundedOutlineButtonStyle} onClick={closeDeleteDialog} style={{ marginRight: 5 }}>
                    <Typography className={classes.buttonTextStyle}>{t('general.cancel')}</Typography>
                </Button>
                <Button variant="container" className={classes.roundedButtonStyle} >
                    {
                        loading ?
                            <CircularProgress size={20} style={{ color: 'white' }} />
                            : <Typography className={classes.buttonTextStyle} onClick={submitData}>{t('general.confirm')}</Typography>
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main + '!important',
        color: '#000000 !important',
        cursor: 'pointer',
        [theme.breakpoints.up('xs')]: {
            maxWidth: 120,
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 200,
            marginTop: "10px !important",
            marginBottom: "10px !important",
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
            color: '#ffffff !important',
        },
    },
    titleStyle: {
        textTransform: 'capitalize  !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.75rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.95rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
        // fontFamily: 'OregonLdoDemibold !important',
    },
    roundedOutlineButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        cursor: 'pointer',
        [theme.breakpoints.up('xs')]: {
            maxWidth: 120,
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 200,
            marginTop: "10px !important",
            marginBottom: "10px !important",
        },
    }
}))

export default DeleteConfirmationModal;