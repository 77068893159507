import _ from 'lodash';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import { changeCartState} from '@actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';

import { CardMedia, Grid, IconButton, TextField, Typography, Button } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiMinus, FiPlus } from "react-icons/fi";
import { IoTrashSharp } from "react-icons/io5";

import { putUrl, deleteUrl , getUrl } from '@helpers/api';
import NumberFormat from 'react-number-format';
import DeleteConfirmationModal from '@components/DeleteConfimationModal';

const CartItem = ({ data, reloadCart }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [childOption, setChildOption] = useState(data.product.options);
    const [mainOption, setMainOption] = useState(data.product.product_options);
    const [inputErrors, setInputErrors] = useState({});
    const [productQuantity, setProductQuantity] = useState(data.quantity);
    const [deleteDialog, setDeleteDialog] = useState({ cartId: '', open: false });
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { uuid, accessToken } = useSelector(state => state.general);
    const { id } = useSelector(state => state.user);
    const [ itemRemark , setItemRemark]=useState(data.remarks);

    const displayPrice = () => {
        let price = data.product.price ? parseFloat(data.product.price) : 0;

        if (_.size(data.product_options) > 0 && price > 0) {
            _.map(data.product_options, main => {
                let main_option = _.find(mainOption, { id: parseInt(main.id) });
                if (_.size(main.option) > 0 && main_option) {
                    _.map(main.option, child => {
                        let child_option = _.find(childOption[main_option.option_code], { id: parseInt(child) })
                        if (parseFloat(child_option.price) && child_option) {
                            price = price + (parseFloat(child_option.price));
                        }
                    })
                }
            })
        }

        //add quantity
        price = price * parseFloat(data.quantity);

        return <NumberFormat prefix="RM " value={parseFloat(price).toFixed(2)} displayType="text" fixedDecimalScale={2} />;
    }

    const updateCartQuantity = (cartId, newQuantity) => {

        if (newQuantity <= 0) {
            setDeleteDialog({ id: cartId, open: true })
        } else {
            putUrl(`/carts/${cartId}`, { quantity: newQuantity}).then(response => {
                if (response.status) {
                    setInputErrors({});
                    setProductQuantity(newQuantity);
                    reloadCart();
                } else {
                    setInputErrors(response.errors);
                }

                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
            }).catch(err => {
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }

    const updateRemark = (cartId, value) => {
        putUrl(`/carts/${cartId}`, { remarks: value , quantity:productQuantity }).then(response => {
            if (response.status) {
                setInputErrors({});
                reloadCart();
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const deleteCart = (cartId) => {
        let params={};
        if(accessToken){
            params={
                user_id:id
            }
        }else{
            params={
                guest_token:uuid
            }
        }

        deleteUrl(`/carts/${cartId}`,params).then(response => {
            if (response.status) {
                setInputErrors({});
                reloadCart();
                dispatch(changeCartState(response.data.cart_total_number));
            } else {
                setInputErrors(response.errors);
            }

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
        }).catch(err => {
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    return (
        <Grid style={{ marginTop: 15, marginBottom: 15, flex: 1, boxShadow: '10px 10px 8px #888888', border: '1px solid #808080', borderRadius: '15px' , backgroundColor:'#ffffff' , opacity: data?.product?.quantity > 0 && data?.product.status ===1 ? 1:0.3 }} >
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                <Grid item xs>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        {
                            data.product?.images[0] &&
                            <Grid padding={5} xs={6} item>
                                <CardMedia component="img" src={data.product?.images[0]?.file_name} style={{ width: 150, maxWidth: '100%', display: 'flex', }} alt="product_image" />
                            </Grid>
                        }
                        <Grid item xs style={{marginTop:10, marginBottom:10, marginLeft:10}}>
                            {/* <CardContent> */}
                            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item style={{ paddingLeft: '0px', marginTop: '5px' }}>
                                    <Typography variant="h5" className={classes.titleStyle}>{data.product.name} ( {t('product.basicPrice')} : {parseFloat(data.product.price).toFixed(2)} )</Typography>
                                </Grid>
                                {
                                    _.size(data.product_options) > 0 &&
                                    <Grid item xs={6}>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                            {
                                                _.map(data.product_options, list => {
                                                    let list_info = _.find(mainOption, { id: parseInt(list.id) });
                                                    return (
                                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" key={list.id}>
                                                            <Grid item>
                                                                <Typography variant="body2" className={classes.mainOptionTextStyle}>{list_info.option_code}</Typography>
                                                            </Grid>
                                                            {
                                                                _.map(list.option, child => {
                                                                    let info = _.find(childOption[list_info.option_code], { id: parseInt(child) })
                                                                    return (
                                                                        <Grid item container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" className={classes.subOptionTextStyle} key={child}>
                                                                            <Grid item><Typography variant="subtitle2" className={classes.subOptionTextStyle}>{info.value} {parseFloat(info.price) ? `( + RM ${parseFloat(info.price).toFixed(2)} )` : ''}</Typography></Grid>
                                                                        </Grid>
                                                                    )
                                                                })
                                                            }
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            {/* </CardContent> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ marginRight: 20 }}>
                    <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                        <Grid item>
                            <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                                <Grid item xs style={{ textAlign: 'end' }}>
                                    <div style={{ cursor: 'pointer' }} onClick={() => updateCartQuantity(data.id, productQuantity - 1)}>
                                        <FiMinus size={30} style={{ backgroundColor: theme.palette.button.main, color: '#ffffff', borderRadius: 5 }} />
                                    </div>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        value={productQuantity}
                                        name="quantity"
                                        onChange={(event) => updateCartQuantity(data.id, event.target.value)}
                                        error={inputErrors?.quantity ? true : false}
                                        helperText={inputErrors?.quantity || ''}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        inputProps={{ style: { textAlign: 'center' } }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <div style={{ cursor: 'pointer' }} onClick={() => updateCartQuantity(data.id, productQuantity + 1)}>
                                        <FiPlus size={30} style={{ backgroundColor: theme.palette.button.main, color: '#ffffff', borderRadius: 5 }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <IconButton color="primary" onClick={() => setDeleteDialog({ id: data.id, open: true })}>
                                        <IoTrashSharp size={30} />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography className={classes.titleStyle}>{displayPrice()}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <form onSubmit={() => updateRemark(data.id, itemRemark)}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" padding={2}>
                    <Grid item xs={6} sm={10} >
                        <TextField
                            label="Remark"
                            name="remark"
                            fullWidth
                            onChange={(event) => setItemRemark(event.target.value)}
                            value={itemRemark || ""}
                        />
                    </Grid>
                    <Grid item xs={6} sm={2}>
                        <Button variant="contained" size="large" type="submit" >Edit</Button>
                    </Grid>
                </Grid>
            </form>
            <DeleteConfirmationModal open={deleteDialog.open} closeDeleteDialog={() => setDeleteDialog({ id: '', open: false })} submitData={() => deleteCart(data.id)} loading={loading} />
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    titleStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoUltraBlack !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.95rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem !important',
        },
    },
    mainOptionTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'NuezeitGroteskLight !important !important',
        textDecoration: 'underline !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.75rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.95rem !important',
        },
    },
    subOptionTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'NuezeitGroteskLight !important !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.55rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
    },
    priceStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.45rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
        // fontFamily: 'NuezeitGroteskLight !important',
        marginRight: '20px !important'
    },
}));

export default CartItem;