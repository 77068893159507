import * as actionType from '@types/userType';

const INITIAL_STATE = {
    addresses: [],
    email: "",
    ic: null,
    id: null,
    mobile: null,
    name: "",
    profile_picture: null,
    status: null,
};

export default function userReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case actionType.UPDATE_USER_INFO:
            const { addresses, email, ic, id, mobile, name, profile_picture, status } = action.payload;
            return { ...state, addresses, email, ic, id, mobile, name, profile_picture, status };
        case actionType.UPDATE_ADDRESSES:
            return { ...state, addresses: action.payload };
        case actionType.RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}