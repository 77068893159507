import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography, InputAdornment, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useToasts } from 'react-toast-notifications';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

import { putUrl } from '@helpers/api';

const PasswordSection = () => {
    const [showPassword, setShowPassword] = useState({
        current_password: false,
        password: false,
        password_confirmation: false,
    });
    const [inputErrors, setInputErrors] = useState({
        current_password: null,
        password: null,
        password_confirmation: null
    });
    const [passwordField, setPasswordField] = useState({
        current_password: '',
        password: '',
        password_confirmation: '',
    });
    const [loading, setLoading] = useState(false);
    
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const handleChange = ({ target }) => {
        setPasswordField({ ...passwordField, [target.name]: target.value });
    }

    const handleClickShowPassword = (value) => {
        setShowPassword({
            ...showPassword,
            [value]: !showPassword[value]
        });
    };

    const updatePasswordBtnClicked = () => {
        setLoading(true);
        console.log(passwordField);
        putUrl(`/user/password`, passwordField).then(response => {
            setLoading(false);
            if (response.status) {
                setInputErrors({});
            } else {
                setInputErrors(response.errors);
            }

            if(response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        });
    }

    return (
        <Card variant="elevation" elevation={3} className={classes.cardContainerStyle}>
            <CardContent className={classes.cardContentRoot}>
                <div className={classes.paper}>
                    <Typography component="p" variant="h5">
                        { 'Change ' + t('user.password') }
                    </Typography>
                    <div className={classes.formContainer}>
                        <div className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                {/*
                                
                                */}
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ 'Current ' + t('user.password') }
                                        type={showPassword.current_password ? 'text' : 'password'}
                                        value={passwordField.current_password}
                                        autoComplete="current_password"
                                        name="current_password"
                                        onChange={handleChange}
                                        error={inputErrors && inputErrors.current_password ? true : false }
                                        helperText={inputErrors?.current_password || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('current_password')}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword.current_password ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ 'New ' + t('user.password') }
                                        type={showPassword.password ? 'text' : 'password'}
                                        value={passwordField.password}
                                        autoComplete="password"
                                        name="password"
                                        onChange={handleChange}
                                        error={inputErrors && inputErrors.password ? true : false }
                                        helperText={inputErrors?.password || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('password')}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ 'Confirm New ' + t('user.password') }
                                        type={showPassword.password_confirmation ? 'text' : 'password'}
                                        value={passwordField.password_confirmation}
                                        autoComplete="password_confirmation"
                                        name="password_confirmation"
                                        onChange={handleChange}
                                        error={inputErrors && inputErrors.password_confirmation ? true : false }
                                        helperText={inputErrors?.password_confirmation || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword('password_confirmation')}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword.password_confirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={ updatePasswordBtnClicked }
                                            disabled={ loading ? true : false }
                                            // style={{ fontFamily:'OregonLdoRegular'}}
                                        >
                                            {
                                                loading ? 
                                                <CircularProgress size={20} />
                                                : t('general.update') + ' Password'
                                            }
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default PasswordSection;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginBottom: 10,
        marginTop:10,
    },
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(6)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
    }
}))