import React, { useState } from 'react';
import { postUrl } from '@helpers/api';
import { Box, Button, Typography, Grid, TextField, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

const ReservationInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        name: '',
        mobile: '',
        email: '',
        size: '',
        note: '',
    });
    const [inputErrors, setInputErrors] = useState({});

    const handleChange = ({ target }) => {
        const { name, value } = target;
        console.log(name, value);
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        postUrl(`/queues`, state).then(response => {
            if (response.status) {
                setInputErrors({});
            } else {
                setInputErrors(response.errors);
            }
            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
            if (response.status) {
                window.location.href = response.queue_status;
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
            // navigate('/sign-in');
        })
    }

    return (
        <Box marginTop={4}>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="flex-start" style={{ width:"70%" }}>
                    <div className={classes.infoStyle}>
                        <Typography variant="h1" component="p" className={classes.titleStyle}><b>{t('title.reservation')}</b></Typography>
                    </div>
                    <Typography variant="body1" component="span" className={classes.textStyle} >
                        You can now queue remotely instead of physically showing up. Just fill in your personal information and we'll let you know when it's your turn.
                    </Typography>
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <Grid container item style={{ marginTop: 10 }} >
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 10 }} >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="name"
                                    autoComplete="name"
                                    label="Name"
                                    error={inputErrors && inputErrors.name ? true : false}
                                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 10 }} >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    label="Email"
                                    error={inputErrors && inputErrors.email ? true : false}
                                    helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 10 }} >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="mobile"
                                    type="tel"
                                    autoComplete="mobile"
                                    label="Mobile"
                                    error={inputErrors && inputErrors.mobile ? true : false}
                                    helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 10 }} >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="size"
                                    type="number"
                                    autoComplete="size"
                                    label="Size"
                                    error={inputErrors && inputErrors.size ? true : false}
                                    helperText={inputErrors && inputErrors.size ? inputErrors.size : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center" style={{ marginTop: 10 }} >
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    name="note"
                                    autoComplete="note"
                                    label="Note"
                                    multiline
                                    minRows="3"
                                    error={inputErrors && inputErrors.note ? true : false}
                                    helperText={inputErrors && inputErrors.note ? inputErrors.note : ''}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <div className={classes.formStyle} style={{ marginTop: 10, marginBottom: 10 }}>
                                    <Button size="small" variant="contained" className={classes.roundedButtonStyle} onClick={() => { submitData() }} fullWidth>
                                        {
                                            loading ?
                                                <CircularProgress size={30} style={{ color: 'white' }} />
                                                :
                                                <Typography className={classes.buttonTextStyle} >{t('queue.submit')}</Typography>
                                        }
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </Box>
    )
}

export default ReservationInfo;

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textAlign: 'left !important',
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem !important',
        },
    },
    subtitleStyle: {
        textAlign: 'left !important',
        // fontFamily: 'OregonLdoBold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.8rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem !important',
        },
    },
    titleStyle: {
        // fontFamily: 'OregonLdoDemibold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '3.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem !important',
        },
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center'
    },

    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    },
    boxStyle: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'inherit',
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'inherit',
        },
        [theme.breakpoints.up('md')]: {
            flexDirection: 'inherit',
        },
    },
    textField: {
        color: '#00ff7f',
        borderRadius: '5px !important',
        background: '#E0E0E0',
        maxWidth: '80%',
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.4rem !important',
        },
    },
    input: {
        color: '#00ff7f',
        borderRadius: '5px !important',
        background: '#E0E0E0',
        [theme.breakpoints.up('xs')]: {
            fontSize: '0.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.3rem !important',
        },
    }
}))