import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { ChevronLeft, ExitToAppRounded, ExpandLess, ExpandMore, PersonAddRounded } from '@mui/icons-material';
import { Collapse, Divider, Drawer, IconButton, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { BsArrowBarRight, BsBuilding, BsCup, BsGrid3X3Gap, BsImages, BsJournalCheck, BsLayoutTextSidebar, BsNewspaper, BsPersonCircle, BsPinMap, BsReverseLayoutTextWindowReverse, BsTelephone } from 'react-icons/bs';

import { logoutUser } from '@actions/generalActions';

const AppDrawer = (props) => {
    const { drawerWidth, drawerOpen, setDrawerOpen , public_link , list } = props;

    const classes = useStyles({ drawerWidth });
    const { isLogin } = useSelector(state => state.general);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const signOutUser = () => {
        dispatch(logoutUser());
        handleDrawerClose();
    }

    const [collapseState, setCollapseState] = useState([]);

    const handleClick = index => {
        setCollapseState({ [index]: !collapseState[index] });
    }

    const renderDrawerLink = useCallback((list) => {
        return (
            <>
                { list.map((item, index) => {
                    if(item.expandable) {
                        return (
                            <div key={index}>
                                <ListItem button onClick={ () => handleClick(index) }>
                                    <ListItemIcon>
                                        { item.icon }
                                    </ListItemIcon>
                                    <ListItemText primary={ item.label } primaryTypographyProps={{ variant: 'caption' }} />
                                    {collapseState[index] ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={collapseState[index]} timeout="auto" unmountOnExit>
                                    <List component="div">
                                        { _.map(item.subLink, subItem => (
                                            <Link component={RouterLink} key={subItem.link} to={{ pathname: subItem.link, state: { field: subItem.field } }}>
                                                <ListItem button className="drawer-sub-link">
                                                    <Typography variant="caption">- { subItem.label }</Typography>
                                                </ListItem>
                                            </Link>
                                        )) }
                                    </List>
                                </Collapse>
                            </div>
                        )
                    }

                    return (
                        <Link key={index} component={RouterLink} to={item.link} underline="none">
                            <ListItem button onClick={handleDrawerClose}>
                                <ListItemIcon>
                                    { item.icon }
                                </ListItemIcon>
                                <ListItemText primary={ item.label } primaryTypographyProps={{ variant: 'caption' }} />
                            </ListItem>
                        </Link>
                    )
                }) }
            </>
        )
        // eslint-disable-next-line
    }, [collapseState])
    

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.drawerHeader}>
                <IconButton onClick={handleDrawerClose}>
                    <ChevronLeft />
                </IconButton>
            </div>
            <List>
                { renderDrawerLink(public_link) }
                <Divider />
                { renderDrawerLink(list) }
                {
                    isLogin ? 
                    <>
                        <Divider />
                        <ListItem button onClick={ signOutUser }>
                            <ListItemIcon>
                                <BsArrowBarRight />
                            </ListItemIcon>
                                <Typography variant="caption">{t('auth.signOut')}</Typography>
                        </ListItem>
                    </>
                    : null
                }
            </List>
        </Drawer>
    )
};

export default AppDrawer;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: 240, //`calc(100% - ${props => props.drawerWidth}px)`,
        // marginLeft: props => props.drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: 240,
        flexShrink: 0,
    },
    drawerPaper: {
        width: 240,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // marginLeft: - (props => props.drawerWidth),
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));