import React, { memo } from 'react';
import _ from 'lodash';

import { Box, Typography, Grid, Link } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { BsLink45Deg } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

const CoffeePackagePromotion = memo(({ data }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box paddingTop={10}>
            <div>
                {
                    _.size(data.title) ?
                        <div dangerouslySetInnerHTML={{ __html: data.title }} />
                        :
                        <Typography variant="h2" className={classes.titleStyle}>{t('coffeePackage.promotionPackage')}</Typography>
                }
            </div>
            <div>
                {
                    _.size(data.subtitle) ?
                        <div dangerouslySetInnerHTML={{ __html: data.subtitle }} />
                        :
                        <Typography variant="h4" className={classes.subtitleStyle}>{t('coffeePackage.starterPackage')}</Typography>

                }
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                    {
                        (_.size(data) > 0 && _.size(data.images) > 0 && _.size(data.images[0].image)) ?
                            <>
                                {
                                    _.map(data.images, (item, index) => {
                                        if (_.size(item?.image)) {
                                            return (
                                                <Grid item xs={6} container justifyContent="center" alignItems="center" key={index}>
                                                    <Link rel="noopener noreferrer" href={item?.link ?  item?.link:"/" } target="_blank">
                                                        <div className={classes.imageContainerStyle}>
                                                            <img className={classes.imageStyle} alt="BlackBixon Pack" src={item?.image} width={400} height={566} />
                                                            {
                                                                _.size(item?.link) ?
                                                                    <div className={classes.overlayStyle} >
                                                                        <BsLink45Deg className={classes.linkIconStyle} />
                                                                    </div>
                                                                    : null
                                                            }
                                                        </div>
                                                    </Link>
                                                </Grid>
                                            )
                                        }
                                    })
                                }
                            </>
                            :
                            <>
                                <Grid item xs={6} container justifyContent="center" alignItems="center">
                                    <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/planselectiondetails/27" target="_blank">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Mocha Starter Pack" src="../images/coffee-package/coffee-package-promotion/mocha-starter-pack.png" />
                                            <div className={classes.overlayStyle} >
                                                <BsLink45Deg className={classes.linkIconStyle} />
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>
                                <Grid item xs={6} container justifyContent="center" alignItems="center">
                                    <Link rel="noopener noreferrer" href="https://blackbixon.com/index.php/cart/planselectiondetails/25" target="_blank">
                                        <div className={classes.imageContainerStyle}>
                                            <img className={classes.imageStyle} alt="Coffee Starter Pack" src="../images/coffee-package/coffee-package-promotion/coffee-starter-pack.png" />
                                            <div className={classes.overlayStyle} >
                                                <BsLink45Deg className={classes.linkIconStyle} />
                                            </div>
                                        </div>
                                    </Link>
                                </Grid>
                            </>
                    }
                </Grid>
            </div >
        </Box >
    )
});

export default CoffeePackagePromotion;

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "capitalize",
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
        color: theme.palette.secondary.main,
    },
    imageStyle: {
        width: '100%',
        objectFit: 'cover',
        maxWidth: 400,
        display: 'block !important',
    },
    subtitleStyle: {
        textTransform: "capitalize",
        marginTop: '10px !important',
        marginBottom: '10px !important',
        textAlign: 'center',
        color: theme.palette.primary.main,
    },
    imageContainerStyle: {
        flex: 1,
        margin: 10,
        position: 'relative !important',
    },
    linkIconStyle: {
        fontSize: '5rem !important',
        color: '#ffffff !important',
        opacity: 1,
    },
    overlayStyle: {
        background: '#000000 !important',
        opacity: 0,
        position: 'absolute !important',
        transition: 'opacity 0.25s !important',
        top: '0px !important',
        bottom: '0px !important',
        left: '0px !important',
        right: '0px !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        display: 'flex !important',
        '&:hover': {
            opacity: 0.3,
        },
        maxWidth: 400,
    }
}))