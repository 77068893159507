import { getUrl, putUrl } from '@helpers/api';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { React, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Link as RouterLink } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { Box, Button, Grid, LinearProgress, Link, Typography, Container, Dialog , DialogContent, DialogTitle , DialogActions , Backdrop , CircularProgress } from '@mui/material';

import { BsArrowLeft } from "react-icons/bs";
import OrderItemCard from './OrderDetailCard';

const OrderDetail = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [loading, setLoading] = useState(false);

    const [order, setOrder] = useState({});
    const [statusList, setStatusList] = useState({});
    const [typeList, setTypeList] = useState({});
    const [deliveryStatus, SetDeliveryStatus] = useState("-");
    const [cancelOrderDialog, setCancelOrderDialog] = useState({
        open: false,
        order: [],
    });
    let { order_no } = useParams();


    //ipay88
    const [ipay88Url, setIpay88Url] = useState("");
    const [ipay88PaymentInput, setIpay88PaymentInput] = useState([]);
    const ipay88PaymentForm = useRef();


    useEffect(() => {
        setLoading(true);
        getOrderDetail();
        // eslint-disable-next-line
    }, [order_no]);

    useEffect(() => {
        setLoading(true)
        if (_.size(order.order_trackings) > 0) {
            getUrl(`/order-delivery-status/${order_no}`,).then(response => {
                setLoading(false);
                if (response.status) {
                    const deliveryStatusList = response.status_list;
                    const delivery_status_info = _.find(deliveryStatusList, { code: response.data });
                    const status = delivery_status_info[i18n.language] ? delivery_status_info[i18n.language] : delivery_status_info['en'];
                    SetDeliveryStatus(status);
                }
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }, [order]);

    const getOrderDetail = () =>{
        getUrl(`/member-order-detail/${order_no}`,).then(response => {
            setLoading(false);
            if (response.status) {
                setOrder(response.data.listing);
                setStatusList(response.data.status_list);
                setTypeList(response.data.type_list);
                if (response.data.listing?.order_payments[0]?.payment_details) {
                    setIpay88PaymentInput(response.data.listing?.order_payments[0]?.payment_details);
                    setIpay88Url(response.data.listing?.order_payments[0]?.payment_details.url);
                }
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const displayStatus = () => {
        let status_info = _.find(statusList, { code: order.status });

        return status_info[i18n.language] ? status_info[i18n.language] : status_info['en'];
    }

    const displayType = () => {
        let type_info = _.find(typeList, { code: order.type });

        return type_info[i18n.language] ? type_info[i18n.language] : type_info['en'];
    }

    const displayTotal = () => {
        let delivery_total = _.size(order.order_trackings) > 0 ? order?.order_trackings[0]?.price?.grand_total : 0;

        let total_amount = parseFloat(order.total_amount) + parseFloat(delivery_total);

        return <NumberFormat prefix="RM " value={parseFloat(total_amount).toFixed(2)} displayType="text" fixedDecimalScale={2} />;

    }

    const handlePayment = () => {
        // delete ipay88Url;
        let postData = {
            ...ipay88PaymentInput,
            url: ipay88Url,
        }
        // console.log("paymentUrl",paymentUrl);
        // console.log("paymentInfo", postData);
        setIpay88PaymentInput(postData);
        ipay88PaymentForm.current.submit();
    }

    const handleCancelOrderDialogOpen = () => {
        setCancelOrderDialog({
            open: true,
            order: order,
        });
    }
    const handleCancelOrderDialogClose = () => {
        setCancelOrderDialog({
            open: false,
            order: [],
        });
    }
    const handleCancelOrderDialogSave = () => {
        setLoading(true);
        putUrl(`/member-cancel-unpaid-order/${cancelOrderDialog.order.id}`).then(response => {
            setLoading(false);
            if (response.status) {
                handleCancelOrderDialogClose();
                getOrderDetail();
            } 

            if (response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }


    return (
        <Box className={classes.paper}>
            <Container maxWidth="md">
                <Grid container direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                    <Grid item>
                        <Link component={RouterLink} to={`/order`} underline="none">
                            <Typography variant="h6"><BsArrowLeft size={15} /> Go Back To Order</Typography>
                        </Link>
                    </Grid>
                    {
                        order.status === 20 ?
                            <Grid item>
                                <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                                    <Typography className={classes.buttonTextStyle} onClick={handleCancelOrderDialogOpen}>Cancel Order</Typography>
                                </Button>
                                {/* <Button size="small" variant="contained" className={classes.roundedButtonStyle} style={{ marginLeft: 10 }}>
                                    <Typography className={classes.buttonTextStyle} onClick={handlePayment}>Pay Again</Typography>
                                </Button> */}
                            </Grid>
                            :
                            null
                    }
                </Grid>
                {
                    _.size(order) > 0 ?
                        <Grid container direction="column" justifyContent="center" alignItems="center" style={{ padding: 15, flex: 1, boxShadow: '10px 10px 8px #888888', border: '1px solid #808080', borderRadius: '15px', backgroundColor: '#ffffff', }}>
                            <Grid item container style={{ borderBottom: ' 1px solid #000000', marginBottom: 10 }}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography variant="h5">{order.order_no}</Typography>
                                        <Typography variant="h5">{order.delivery_datetime}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h5" className={classes.titleStyle} style={{ textAlign: 'right' }}>
                                            {_.size(statusList) > 0 ? displayStatus() : ""}
                                        </Typography>
                                        <Typography variant="h5" style={{ textAlign: 'right' }} >
                                            {_.size(typeList) > 0 ? `Type : ${displayType()}` : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                _.size(order.order_trackings) > 0 && order.order_trackings &&
                                <Grid item container style={{ borderBottom: ' 1px solid #000000', marginBottom: 10 }}>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="h5" style={{ fontWeight: 'bold' }}>Delivery Status</Typography>
                                            <Typography variant="body2">{deliveryStatus}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item container style={{ borderBottom: ' 1px solid #000000', marginBottom: 10 }}>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item xs={6}>
                                        {
                                            _.size(order.order_trackings) > 0 && _.size(order.order_trackings[0]?.address) > 0 &&
                                            <Grid item xs={6}>
                                                <Typography variant="h5" style={{ fontWeight: 'bold' }}>Recipient Info</Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            _.size(order.user) > 0 &&
                                            <>
                                                <Typography variant="h5" style={{ fontWeight: 'bold', textAlign: 'right' }}>Buyer Info</Typography>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                    <Grid item xs={6}>
                                        {
                                            _.size(order.order_trackings) > 0 && _.size(order.order_trackings[0]?.address) > 0 &&
                                            <>
                                                <Typography variant="body2" >Name : {order.order_trackings[0]?.address?.username || "-"}</Typography>
                                                <Typography variant="body2" >Mobile : {order.order_trackings[0]?.address?.mobile || "-"}</Typography>
                                                <Typography variant="body2" >Email : {order.order_trackings[0]?.address?.email || "-"}</Typography>
                                                <Typography variant="body2" style={{ fontWeight: 'bold' }}>Address : {order.order_trackings[0]?.address?.full_address || "-"}</Typography>
                                            </>
                                        }
                                    </Grid>
                                    <Grid item xs={6}>
                                        {
                                            _.size(order.user) > 0 &&
                                            <>
                                                <Typography variant="body2" style={{ textAlign: 'right' }}>Name : {order.user.name}</Typography>
                                                <Typography variant="body2" style={{ textAlign: 'right' }}>Mobile : {order.user.mobile || "-"}</Typography>
                                                <Typography variant="body2" style={{ textAlign: 'right' }} >Email : {order.user.email || "-"}</Typography>
                                            </>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container style={{ borderBottom: ' 1px solid #000000', marginBottom: 10 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>Product Info</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        _.size(order.order_details) > 0 && _.map(order.order_details, (data, dataIndex) => {
                                            return (
                                                <OrderItemCard key={dataIndex} data={data} />
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                            <Grid item container justifyContent="flex-end" direction="column">
                                <Typography variant="subtitle1" style={{ textAlign: 'right', fontWeight: 'bold' }}>Subtotal :  RM {order.total_amount}</Typography>
                                {
                                    _.size(order.order_trackings) > 0 ?
                                        <>
                                            <Typography variant="subtitle1" style={{ textAlign: 'right', fontWeight: 'bold' }}>Delivery Fee :  RM {order.order_trackings[0]?.price?.total ? order.order_trackings[0]?.price?.total : '-'}</Typography>
                                            <Typography variant="subtitle1" style={{ textAlign: 'right', fontWeight: 'bold' }}>Tax :   RM {order.order_trackings[0]?.price?.tax ? order?.order_trackings[0]?.price?.tax : '-'}</Typography>
                                        </>
                                        : null
                                }
                                <Typography variant="subtitle1" style={{ textAlign: 'right', fontWeight: 'bold' }}>Total Amount : {displayTotal()} </Typography>
                            </Grid>
                        </Grid>
                        :
                        loading
                            ?
                            <Box style={{ marginTop: 20 }}>
                                <LinearProgress size={20} />
                            </Box>
                            :
                            <Box style={{ marginTop: 20, textAlign: "center" }}>
                                No Data
                            </Box>
                }
                <form ref={ipay88PaymentForm} action={ipay88Url} method="POST">
                    {ipay88PaymentInput ?
                        _.map(ipay88PaymentInput, (paymentValue, paymentIndex) => {
                            return (
                                <input hidden key={paymentIndex} name={paymentIndex} defaultValue={paymentValue} />
                            )
                        })
                        : null}
                </form>
                {/* CANCEL ORDER DIALOG */}
                <Dialog open={cancelOrderDialog.open} onClose={handleCancelOrderDialogClose} style={{ zIndex: 999 }}>
                    <DialogTitle>{" Cancel Order : " + cancelOrderDialog.order.order_no}</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>Are you sure want to cancel this ?</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle} style={{paddingLeft:5 , paddingRight:5}}>
                            <Typography className={classes.buttonTextStyle} onClick={handleCancelOrderDialogClose}>No</Typography>
                        </Button>
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle} style={{ marginLeft: 10,paddingLeft:5 , paddingRight:5 }}>
                            <Typography className={classes.buttonTextStyle} onClick={handleCancelOrderDialogSave}>Yes</Typography>
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Box>
    )
}

export default OrderDetail;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginLeft: "20px !important",
        marginBottom: "20px !important"
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    itemContainer: {
        margin: "20px !important",
        border: 'none',
        borderRadius: 10,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 1px 6px 3px #0000001f',
    },
}))