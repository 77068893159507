import React, {useState} from 'react'

import { Container, Box, Dialog , Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FaSearchPlus } from 'react-icons/fa';

import HomeCarousel from '../Home/HomeCarousel';
import HomeLocateUs from '../Home/HomeLocateUs';

const Menu = () => {
    const theme = useTheme();
    const classes = useStyles();
    const [openImage, setOpenImage] = useState();
    console.log('openImage', openImage);

    return (
        <Box>
            <HomeCarousel data={[]}/>
            <Container className="app-container" maxWidth="lg">
                <Box style={{ margin: 50 }}>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Menu
                    </Typography>
                </Box>
                <Box style={{ margin: 30 }}>
                    <div className={classes.imageContainerStyle} onClick={() => setOpenImage(`../images/menu/menu-1.png`)}>
                        <div className={classes.overlayStyle} >
                            <FaSearchPlus className={classes.linkIconStyle} />
                        </div>
                        <img alt="menu1" src={`../images/menu/menu-1.png`} width="100%" loading='lazy' />
                    </div>
                </Box>
                <Box style={{ margin: 50 }}>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Page 1
                    </Typography>
                </Box>
                <Box style={{ margin: 30 }}>
                    <div className={classes.imageContainerStyle} onClick={() => setOpenImage(`../images/menu/menu-2.png`)}>
                        <div className={classes.overlayStyle} >
                            <FaSearchPlus className={classes.linkIconStyle} />
                        </div>
                        <img alt="menu2" src={`../images/menu/menu-2.png`} width="100%" loading='lazy' />
                    </div>
                </Box>
                <Box style={{ margin: 50 }}>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Page 2
                    </Typography>
                </Box>
                <Dialog
                    open={openImage?true:false}
                    onClose={() => setOpenImage()}
                    fullWidth={true}
                    maxWidth={'xl'}
                    PaperProps={{
                        style: {
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                        },
                    }}
                >
                    <img alt="menu" src={openImage} width="100%" loading='lazy' />
                </Dialog>
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}

export default Menu

const useStyles = makeStyles((theme) => ({
    imageContainerStyle: {
        flex: 1,
        margin: 10,
        position: 'relative !important',
    },
    linkIconStyle: {
        fontSize: '5rem !important',
        color: '#ffffff !important',
        opacity: 1,
    },
    overlayStyle: {
        background: '#000000 !important',
        opacity: 0,
        position: 'absolute !important',
        transition: 'opacity 0.25s !important',
        top: '0px !important',
        bottom: '0px !important',
        left: '0px !important',
        right: '0px !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        display: 'flex !important',
        '&:hover': {
            opacity: 0.3,
        },
        marginBottom: 6
    },
    titleStyle: {
        textTransform: "capitalize",
        fontFamily: 'OregonLdo !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
}))