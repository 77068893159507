import React from 'react';
import { Box, Container } from '@mui/material';
import QueueInfo from './QueueInfo';

const index = () => {
    return (
        <Box>
            <Container className="app-container" maxWidth="md">
                <QueueInfo />
            </Container>
        </Box>
    )
}
export default index