import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Container, Link, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { BsWhatsapp } from 'react-icons/bs';

import HomeLocateUs from '../Home/HomeLocateUs';

const NewFaq = () => {
    const theme = useTheme();
    const classes = useStyles();

    const businessHour = useRef(null);
    const cafeLocation = useRef(null);
    const halal = useRef(null);
    const reservation = useRef(null);
    const menu = useRef(null);
    const contactUs = useRef(null);
    const wifi = useRef(null);
    const pets = useRef(null);
    const food = useRef(null);
    const catering = useRef(null);
    const privateRooms = useRef(null);
    const croissants = useRef(null);
    const vegetarian = useRef(null);
    const coffeeBeans = useRef(null);

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop - 90,
            behavior: 'smooth'
        })
    }
    return (
        <Box>
            <Container className="app-container" maxWidth="lg">
                <Box style={{ margin: 20 }}>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Frequently Asked Questions
                    </Typography>
                </Box>
                <Box style={{ margin: 20, display: 'flex' , whiteSpace:'pre' , flexWrap:'wrap' , justifyContent:'flex-start' }}>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(businessHour)}>
                        Business Hour
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }}>
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(cafeLocation)}>
                        Location
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(halal)}>
                        Halal
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(reservation)}>
                        Reservation
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(menu)}>
                        Menu
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(contactUs)}>
                        Contact Us
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(wifi)}>
                        Wifi
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(pets)}>
                        Pets
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(food)}>
                        Food
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(catering)}>
                        Catering
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(privateRooms)}>
                        Private Rooms
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(croissants)}>
                        Croissants
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(vegetarian)}>
                        Vegetarian
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold" }} >
                        |
                    </p>
                    <p className={classes.textStyle} style={{ color: theme.palette.primary.main, fontWeight: "bold", cursor: 'pointer' }} onClick={() => scrollToSection(coffeeBeans)}>
                        Coffee Beans
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={businessHour}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        What are your business hours?
                    </p>
                    <p className={classes.textStyle}>
                        We're open every day from 8:00 AM to 10:00 PM.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={cafeLocation}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Where can we find you?
                    </p>
                    <p className={classes.textStyle}>
                        We currently have two Klang Valley locations in Jalan Imbi & Petaling Jaya.
                        Stay tuned for more outlets!
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={halal}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Is your food Halal?
                    </p>
                    <p className={classes.textStyle}>
                        We're actively working on obtaining Halal Certification. Currently, all our ingredients come from Halal-certified suppliers, and we're pork-free and alcohol-free establishment.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={reservation}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Can I make reservations?
                    </p>
                    <p className={classes.textStyle}>
                        Absolutely! To reserve a table, just send us these details via DM or WhatsApp:
                    </p>
                    <p className={classes.textStyle}>
                        Name of Outlet:
                    </p>
                    <p className={classes.textStyle}>
                        Indoor/Outdoor/No Preference:
                    </p>
                    <p className={classes.textStyle}>
                        Your Name:
                    </p>
                    <p className={classes.textStyle}>
                        Contact Number:
                    </p>
                    <p className={classes.textStyle}>
                        Number of Guests (Pax):
                    </p>
                    <p className={classes.textStyle}>
                        Preferred Date & Time:
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={menu}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        May I see your menu?
                    </p>
                    <p className={classes.textStyle}>
                        Sure thing! Check out our <Link component={RouterLink} to="/menu">menu</Link>.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={contactUs}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        How do we contact you?
                    </p>
                    <p className={classes.textStyle}>
                        Reach out to us at the following outlet contact numbers:
                    </p>
                    <p className={classes.textStyle}>
                        Imbi: <a href="tel:0173950321" style={{ color: 'black', textDecoration: 'none' }}>017-395 0321</a> | Petaling Jaya:  <a href="tel:0125480321" style={{ color: 'black', textDecoration: 'none' }}>012-548 0321</a>
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={wifi}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Does your place offer WiFi?
                    </p>
                    <p className={classes.textStyle}>
                        Yes, WiFi is available at all our locations. Just ask our friendly staff for the password.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={pets}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do you allow pets at your outlets?
                    </p>
                    <p className={classes.textStyle}>
                        Unfortunately, we can't accommodate pets at our outlets.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={food}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do all your outlets have the same offering?
                    </p>
                    <p className={classes.textStyle}>
                        Our core menu remains consistent across all outlets. However, we feature unique artisanal pastries and main dishes at each location. Come and discover our diverse pastry and mains selection!
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={catering}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do you take pre-orders or cater to events?
                    </p>
                    <p className={classes.textStyle}>
                        Absolutely! We welcome advance orders, including corporate and event catering. Reach out to us via WhatsApp for more details.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={privateRooms}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do you have a private room?
                    </p>
                    <p className={classes.textStyle}>
                        Yes, we offer private rooms at all our establishments. Contact us via WhatsApp at your chosen location to reserve one.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={croissants}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do we bake our croissants?
                    </p>
                    <p className={classes.textStyle}>
                        Yes! We're always baking fresh throughout the day!
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={vegetarian}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        Do you have vegetarian options?
                    </p>
                    <p className={classes.textStyle}>
                        We have a variety of vegetarian menu options, and we can accommodate vegetarian preferences for many dishes. Just let our team know when you order.
                    </p>
                </Box>
                <Box style={{ margin: 20 }} ref={coffeeBeans}>
                    <p className={classes.textStyle} style={{ fontWeight: "bold" }}>
                        What coffee beans or special blend do you use?
                    </p>
                    <p className={classes.textStyle}>
                        Currently, we use a blend of coffee beans sourced from Rwanda and Brazil.
                        This blend offers a rich, dark roast flavor and aroma.
                    </p>
                </Box>
                <Box style={{ margin: 20 }}>
                    <Typography variant="h5" className={classes.titleStyle}>
                        Any Other Questions?
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" className={classes.infoStyle} justifyContent="center" alignItems="center">
                    <Link rel="noopener noreferrer" href="https://wa.me/60173950321" target="_blank" underline="none">
                        <Button size="small" variant="contained" className={classes.roundedButtonStyle}>
                            <BsWhatsapp size="18px" style={{ marginLeft: 10 }} />
                            <Typography className={classes.buttonTextStyle}>Contact Us</Typography>
                        </Button>
                    </Link>
                </Box>
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}

export default NewFaq

const useStyles = makeStyles((theme) => ({
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
    },
    textStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.9rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        marginLeft:10,
        marginRight:10,
    },
    titleStyle: {
        textTransform: "capitalize",
        fontFamily:'OregonLdo !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
}))