import React from 'react'

import { Box, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next';

const LocateUsMap = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box paddingTop={4}>
            <div>
                <Typography variant="h2" className={classes.titleStyle}>{t('title.contactUs')}</Typography>
            </div>
            <div className={classes.imageContainerStyle}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.8047056726464!2d101.7185592752072!3d3.1461790327095756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc3707b3f3a207%3A0x493dba9722c88251!2sBlackbixon%20Cafe!5e0!3m2!1sen!2smy!4v1656340727351!5m2!1sen!2smy" width="100%" height="400" className={classes.mapStyle} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Black Bixon Cafe Map"></iframe>
            </div>
        </Box>
    )
}

export default LocateUsMap;

const useStyles = makeStyles((theme) => ({
    imageContainerStyle: {
        position: 'relative !important',
        width: '100% !important',
        overflow: 'hidden !important',
        border: 'solid 5px',
        borderRadius: 10,
        borderColor: theme.palette.primary.main,
    },
    mapStyle: {
        position: 'absolute important',
        top: 0,
        left: 0,
        width: '100 % !important',
        height: '100 % !important',
    },
    titleStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoDemibold !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign:'center',
    },
}))