import { getUrl } from '@helpers/api';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';
import Modal from "./Modal";
import Scenario from "./Scenario/";


const LatestNewsInfo = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const [viewMore, setViewMore] = useState(false);
    const [listing, setListing] = useState([]);
    const [loading, setLoading] = useState(false);
    const dataForDisplay = viewMore ? listing : listing.slice(0, 4)
    const [state, setstate] = useState({
        content: {},
        openModal: false,
        title: {},
        image: {}
      })

    const handleOpenModal = ({ content, title, image }) => {
        setstate({ content, openModal: true, title, image });
    };

    const handleCloseModal = () => {
        setstate({ openModal: false, content: {}, title: {}, image: {} });
    };

    useEffect(() => {
        setLoading(true);
        getUrl("/latest-news").then(response => {
            setLoading(false);
            if (response.status) {
                setListing(response.data.listing);
            }
        }).catch(err => {
            setLoading(false);
            addToast(t('general.error'), { appearance: 'error' });
        })
        // eslint-disable-next-line
    }, []);
    
    return (
        <Box display="flex" flexDirection="column" paddingTop={4}>
            <div>
                <Typography variant="h2" className={classes.titleStyle}>{t('title.latestNews')}</Typography>
                <Typography className={classes.subtitleStyle}>
                    Stay up-to-date with the latest announcements, promotions, events happening at BlackBixon and more!
                </Typography>
            </div>
            <div style={{ paddingTop: 40 }}>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">  
                {
                loading ?
                    <CircularProgress color="primary" />
                    :
                    _.size(dataForDisplay) ? dataForDisplay.map((props, index) => (
                        <Grid item xs={12} sm={6} md={6} container spacing={2} direction="column" justifyContent="center" alignItems="center" style={{ marignTop: 20, marginBottom: 20 }}  key={index}>
                            <Scenario
                                key={index}
                                {...props}
                                handleOpenModal={handleOpenModal}
                            />
                            <Typography variant="body1" className={classes.textStyle} style={{ paddingLeft: 10, paddingRight: 10}}>{props.title[i18n.language]}</Typography>
                        </Grid>
                    ))
                    : <Typography variant="body1" className={classes.textStyle}>No News.</Typography>
                }
                </Grid>
                {_.size(dataForDisplay) > 0 &&
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Button variant="contained" className={classes.roundedButtonStyle} onClick={() => setViewMore(!viewMore)}>
                    {viewMore ? 
                        <Typography className={classes.buttonTextStyle} >{t('general.readLess')}</Typography>
                    :
                        <Typography className={classes.buttonTextStyle} >{t('general.readMore')}</Typography>
                    }          
                        </Button>
                </Box> 
                }
            </div>
            <Modal {...state} language={i18n.language} onCloseModal={handleCloseModal} />
        </Box>
    )
}
export default LatestNewsInfo;

const useStyles = makeStyles((theme) => ({    
    textStyle: {
        textAlign: 'left !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    subtitleStyle: {
        textAlign: 'left !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.0rem !important',
        },
    },
    titleStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '3.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem !important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main+'!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main+'!important',
        },
    },
}))