import React, { useState } from 'react';
import { IoArrowUpCircleSharp } from "react-icons/io5";

const ScrollToTop = () => {

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    }
    else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <IoArrowUpCircleSharp onClick={scrollToTop} size="40" style={{ display: visible ? 'inline' : 'none', right: 10, zIndex: 99, bottom: 5, position: 'fixed' }} />
  );
}

export default ScrollToTop; 
