import _ from 'lodash';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { CardMedia, Grid, IconButton, TextField, Typography, Button } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import NumberFormat from 'react-number-format';

const CartItem = ({ data, reloadCart }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [childOption, setChildOption] = useState(data.product.options);
    const [mainOption, setMainOption] = useState(data.product.product_options);
    const [inputErrors, setInputErrors] = useState({});
    const [productQuantity, setProductQuantity] = useState(data.quantity);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const [ itemRemark , setItemRemark]=useState(data.remarks);

    const displayPrice = () => {
        let price = data.product.price ? parseFloat(data.product.price) : 0;

        if (_.size(data.product_options) > 0 && price > 0) {
            _.map(data.product_options, main => {
                // let main_option = _.find(mainOption, { id: parseInt(main.id) });
                // if (_.size(main.option) > 0 && main_option) {
                //     _.map(main.option, child => {
                //         let child_option = _.find(childOption[main_option.option_code], { id: parseInt(child) })
                //         if (parseFloat(child_option.price) && child_option) {
                //             price = price + (parseFloat(child_option.price));
                //         }
                //     })
                // }
                price = price + (parseFloat(main.price));
            })
        }

        //add quantity
        price = price * parseFloat(data.quantity);

        return <NumberFormat prefix="RM " value={parseFloat(price).toFixed(2)} displayType="text" fixedDecimalScale={2} />;
    }

    return (
        <Grid style={{ marginTop: 15, marginBottom: 15, flex: 1 }} >
            <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item xs>
                    <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid padding={5} xs={4} item>
                            {data.product?.images[0] &&
                                <CardMedia component="img" src={data.product?.images[0]?.file_name} style={{ width: 150, maxWidth: '100%', display: 'flex', height: 200 }} alt="product_image" />
                            }
                        </Grid>
                        <Grid item xs style={{marginTop:10, marginBottom:10}}>
                            {/* <CardContent> */}
                            <Grid container spacing={2} direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid item style={{ paddingLeft: '0px', marginTop: '5px' }}>
                                    <Typography variant="h5" className={classes.titleStyle} >{data.product.name} ( {t('product.basicPrice')} : {parseFloat(data.product.price).toFixed(2)} )</Typography>
                                </Grid>
                                {
                                    _.size(data.product_options) > 0 &&
                                    <Grid item xs={6}>
                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                                            {
                                                _.map(data.product_options, list => {
                                                    return (
                                                        <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" key={list.id}>
                                                            <Grid item>
                                                                <Typography variant="body2" className={classes.mainOptionTextStyle}>{list.value} {`( + RM ${parseFloat(list.price).toFixed(2)} )`}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            {/* </CardContent> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ marginRight: 30 }}>
                    <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center">
                        <Grid item>
                            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center">
                                <Grid item xs={12}>
                                    <Typography style={{ textAlign:'start'}}>X {productQuantity}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <Typography className={classes.titleStyle}>{displayPrice()}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" style={{ paddingTop: 0 }}>
                <Grid item xs={6} sm={12} style={{ paddingTop: 10 }} >
                    <TextField
                        label="Remark"
                        name="remark"
                        fullWidth
                        size="small"
                        InputProps={{ readOnly: true }}
                        value={itemRemark || ""}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    titleStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoUltraBlack !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.95rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem !important',
        },
    },
    mainOptionTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'NuezeitGroteskLight !important !important',
        textDecoration: 'underline !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.75rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.95rem !important',
        },
    },
    subOptionTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'NuezeitGroteskLight !important !important',
        textAlign: 'center',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.55rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
    },
    priceStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.45rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.65rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.85rem !important',
        },
        // fontFamily: 'NuezeitGroteskLight !important',
        marginRight: '20px !important'
    },
}));

export default CartItem;