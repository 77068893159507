import React, { useEffect, useState } from "react";
import { Typography, Grid, Card, CardContent, CardMedia, Box, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { getUrl } from "@helpers/api";
import _ from "lodash";

const GalleryInfo = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [titleList, setTitleList] = useState([]);
	const styles = useStyles();
	const theme = useTheme();
	const { addAlert, setLoading } = useToasts();
	const [viewMore, setViewMore] = useState(false);
	const dataForDisplay = viewMore ? titleList : titleList.slice(0, 6)

	useEffect(() => {
		getUrl(`/settings/member/galleries`)
			.then((response) => {
				console.log(response.data.value.data.galleries);
				if (response.status) {
					setTitleList(response.data.value.data.galleries.images);
				} else {
					addAlert("", t("error.contactSupport"), "error", "");
				}
			})
			.catch((error) => {
				addAlert("", error.message || t("error.contactSupport"), "error", "");
			});
		// eslint-disable-next-line
	}, []);

	return (
		<Box marginTop={4}>
			<div>
				<Typography variant="h2" className={classes.titleStyle} >{t("title.gallery")}</Typography>
			</div>
			{_.size(dataForDisplay) > 0 ? (
				<div className={classes.containerStyle}>
					<Grid container spacing={5}>
						{_.map(dataForDisplay, (gallery) => {
							return (
								<Grid
									item
									key={gallery.img_id}
									xs={12}
									sm={4}
									md={4}
									className={classes.cardStyle}
								>
									<Card
										sx={{
											height: "100%",
											display: "flex",
											flexDirection: "column",
											width: "100%",
											boxShadow: 0,
										}}
									>
										<CardMedia
											component="img"
											src={
												_.size(gallery.image)
													? gallery.image.file_name
													: "/image/emptyStatus/noproductimg.jpg"
											}
											alt="Black Bixon Gallery"
										/>
										<div>
											<CardContent sx={{ flexGrow: 1 }}>
												<Typography className={classes.cardContentStyle}>
													{gallery.text}
												</Typography>
											</CardContent>
										</div>
									</Card>
								</Grid>
							);
						})}
					</Grid>
				</div>
			) : (
				<div
					className={styles.flexCenterMiddle}
					style={{ flexDirection: "column" }}
				>
					<img
						src="/images/emptyStatus/noproductimg.jpg"
						alt="noresult"
						style={{ width: 180, margin: "0 auto" }}
					/>
					<Typography style={{ color: theme.palette.gray.main }}>
						{t("emptyStatus.noProduct")}
					</Typography>
				</div>
			)}

			{_.size(titleList) > 6 &&
				<Box display="flex" alignItems="center" flexDirection="column">
					<Button variant="contained" className={classes.roundedButtonStyle} onClick={() => setViewMore(!viewMore)}>
						{viewMore ?
							<Typography className={classes.buttonTextStyle} >{t('general.viewLess')}</Typography>
							:
							<Typography className={classes.buttonTextStyle} >{t('general.viewMore')}</Typography>
						}
					</Button>
				</Box>
			}
		</Box>
	);
};

export default GalleryInfo;

const useStyles = makeStyles((theme) => ({
	textStyle: {
		textAlign: "left !important",
		[theme.breakpoints.up("xs")]: {
			fontSize: "1.2rem !important",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "1.5rem !important",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "2rem !important",
		},
	},
	subtitleStyle: {
		textAlign: "left !important",
		[theme.breakpoints.up("xs")]: {
			fontSize: "1.5rem !important",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "1.8rem !important",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "2.5rem !important",
		},
	},
	titleStyle: {
		[theme.breakpoints.up("xs")]: {
			fontSize: "3.2rem !important",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "3.5rem !important",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "4rem !important",
		},
	},
	imageContainerStyle: {
		flex: 1,
		padding: 10,
	},
	imageStyle: {
		width: "100%",
		objectFit: "contain",
	},
	containerStyle: {
		flex: 1,
		padding: 10,
		display: "flex",
		justifyContent: "center",
	},

	buttonTextStyle: {
		textTransform: "capitalize",
		paddingLeft: "10px !important",
		paddingRight: "10px !important",
		paddingTop: "5px !important",
		paddingBottom: "5px !important",
		[theme.breakpoints.up("xs")]: {
			fontSize: ".85rem !important",
		},
		[theme.breakpoints.up("sm")]: {
			fontSize: "1rem !important",
		},
		[theme.breakpoints.up("md")]: {
			fontSize: "1.2rem !important",
		},
	},
	roundedButtonStyle: {
		marginTop: "5px !important",
		borderRadius: "20px !important",
		width: "fit-content",
		marginBottom: "5px !important",
		backgroundColor: theme.palette.button.main + "!important",
		"&:hover": {
			backgroundColor: theme.palette.primary.main + "!important",
		},
	},
	infoStyle: {
		marginTop: 20,
		marignBottom: 20,
	},
	boxStyle: {
		marginTop: 10,
		marginBottom: 10,
		display: "flex",
		flexDirection: "inherit",
		[theme.breakpoints.up("xs")]: {
			flexDirection: "column",
		},
		[theme.breakpoints.up("sm")]: {
			flexDirection: "inherit",
		},
		[theme.breakpoints.up("md")]: {
			flexDirection: "inherit",
		},
	},
	cardStyle: {
		paddingTop: "120px !important",
		"&:nth-child(3n+2)": {
			paddingTop: "50px !important",
		},
	},

	cardContentStyle: {
		textAlign: "center",
	},
}));
