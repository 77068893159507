import React, { memo, useEffect, useState, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useToasts } from 'react-toast-notifications';

import { makeStyles, useTheme } from '@mui/styles';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Link, Badge,Grid  } from '@mui/material';
import { MenuRounded } from '@mui/icons-material';
// import { CenterContent } from './style';
import clsx from 'clsx';
import _ from 'lodash';


import AppDrawer from './AppDrawer';
import { v4 as uuidv4 } from 'uuid';
import { changeDefaultLanguage, storeUuid, removeUuid } from '@actions/generalActions';
import { updateUserState } from '@actions/userActions';
import { getUrl, postUrl } from '@helpers/api';
import instance from '@helpers/request';
import { changeCartState,logoutUser } from '@actions/generalActions';

import { ExitToAppRounded, PersonAddRounded } from '@mui/icons-material';
import { BsArrowBarRight, BsBuilding, BsCup, BsGrid3X3Gap, BsImages, BsJournalCheck, BsLayoutTextSidebar, BsNewspaper, BsPersonCircle, BsPinMap, BsReverseLayoutTextWindowReverse, BsTelephone, BsSearch , BsHandbag, BsFillFileTextFill, BsFillQuestionCircleFill, BsTelephoneFill, BsFillPersonFill } from 'react-icons/bs';

const drawerWidth = 240;

export default memo(function AppHeader() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [changeColor, setChangeColor]=useState(false);

    const { t, i18n } = useTranslation();
    const { defaultLanguage, isLogin, accessToken, cartTotal, uuid } = useSelector(state => state.general);
    const classes = useStyles();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    let location = useLocation();
    const theme = useTheme();

    const public_restricted_link = useMemo(() => ([
        // { link: '/sign-in', icon: <ExitToAppRounded />, label: t('auth.signIn') },
        // { link: '/sign-up', icon: <PersonAddRounded />, label: t('auth.signUp') },
    ]), [t]);

    const private_link = useMemo(() => ([
        // { link: '/order', icon: <BsFillFileTextFill />, label: t('title.order'), expandable: false },
        // { link: '/profile', icon: <BsPersonCircle />, label: t('title.profile'), expandable: false },
        // { link: '/profile', icon: <AccountCircleRounded />, label: t('title.profile'), expandable: true, subLink: [
        //     { link: '/profile', label: t('profile.changeProfile') },
        //     { link: '/profile/password', field: "password", label: t('profile.changePassword') },
        //     { link: '/profile/security_password', field: "security_password", label: t('profile.changeSecurityPassword') },
        // ] },
        // { link: '/transfer', icon: <CallMadeRounded />, label: t('title.transfer'), expandable: false },
        // { link: '/transactions', icon: <CreditCardRounded />, label: t('title.transaction'), expandable: false },
    ]), [t]);

    const public_link = useMemo(() => ([
        { link: '/', icon: <BsGrid3X3Gap />, label: t('title.home'), expandable: false },
        { link: '/our-story', icon: <BsBuilding />, label: t('title.ourStory'), expandable: false },
        { link: '/menu', icon: <BsLayoutTextSidebar />, label: t('title.menu'), expandable: false },
        // { link: '/coffee-package', icon: <BsCup />, label: t('title.coffeePackage'), expandable: false },
        // { link: '/reservation', icon: <BsJournalCheck />, label: t('title.reservation'), expandable: false },
        // { link: '/gallery', icon: <BsImages />, label: t('title.gallery'), expandable: false },
        { link: '/career', icon: <BsFillPersonFill />, label: t('title.career'), expandable: false },
        { link: '/locate-us', icon: <BsPinMap />, label: t('title.locateUs'), expandable: false },
        { link: '/contact-us', icon: <BsTelephoneFill />, label: t('title.contactUs'), expandable: false },
        { link: '/faq', icon: <BsFillQuestionCircleFill />, label: t('title.faqShort'), expandable: false },
        // { link: '/career', icon: <BsReverseLayoutTextWindowReverse />, label: t('title.career'), expandable: false },
        // { link: '/latest-news', icon: <BsNewspaper />, label: t('title.latestNews'), expandable: false },
        // { link: '/contact-us', icon: <BsTelephone />, label: t('title.contactUs'), expandable: false },
    ]), [t]);

    const list = useMemo(() => {
        return isLogin ? private_link : public_restricted_link;
    }, [isLogin, private_link, public_restricted_link]);

    useEffect(() => {
        if (accessToken) {
            delete instance.defaults.headers.common['Guest-Token'];
            if (uuid) {
                postUrl('carts/merge', {
                    guest_token: uuid
                }).catch(error => {
                    addToast(JSON.stringify(error.message), { appearance: 'error' });
                });
            }
            getUrl('/user').then(response => {
                // console.log("response", response);
                if (uuid) {
                    dispatch(removeUuid());
                }
                if (response.status) {
                    dispatch(updateUserState(response.data));
                } else {
                    addToast(t('error.contactSupport'), { appearance: 'error' });
                }
            })

            getUrl('/cart-total').then(response => {
                // console.log("response", response);
                if (response.status) {
                    dispatch(changeCartState(response.cart_total));
                } else {
                    addToast(t('error.contactSupport'), { appearance: 'error' });
                }
            })
        } else {
            delete instance.defaults.headers.common['Authorization'];
            instance.defaults.headers.common['Guest-Token'] = uuid;
        }
        if (!uuid && !accessToken) {
            const createdUuid = uuidv4();
            dispatch(storeUuid(createdUuid));
        }
        // eslint-disable-next-line
    }, [uuid, accessToken]);

    // const handleLanguageMenuOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleLanguageMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleLanguageMenuClose();
    };

    // const handleMobileMenuOpen = (event) => {
    //     setMobileMoreAnchorEl(event.currentTarget);
    // };

    const changeLanguage = lang => {
        if (defaultLanguage !== lang) {
            i18n.changeLanguage(lang);
            dispatch(changeDefaultLanguage(lang));
        }

        handleMenuClose();
    }

    const signOutUser = () => {
        dispatch(logoutUser());
    }

    const changeBackgroundColor = () => {
        if (window.scrollY > 0) {
            setChangeColor(true);
        } else {
            setChangeColor(false);
        }
    }
    window.addEventListener('scroll',changeBackgroundColor);

    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(mobileMoreAnchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => changeLanguage('en')}>
                <Typography variant="button">English</Typography>
            </MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>
                <Typography variant="button">中文</Typography>
            </MenuItem>
        </Menu>
    )

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => changeLanguage('en')}>
                <Typography variant="button">English</Typography>
            </MenuItem>
            <MenuItem onClick={() => changeLanguage('cn')}>
                <Typography variant="button">中文</Typography>
            </MenuItem>
        </Menu>
    );

    const bannerUrl = ['/', '/our-story', '/contact-us', '/carrer'];

    return (
        <>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: theme.palette.primary.main, color: theme.palette.primary.background, height: 50}}>
            {t('title.cafeTitle')}
        </div>
            <AppBar
                color={changeColor ? 'primary' : (_.includes(bannerUrl, location.pathname) ? "transparent" : "primary")}
                elevation={0}
                position={changeColor? "sticky" : (_.includes(bannerUrl, location.pathname) ? "absolute" : "sticky")}
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: drawerOpen,
                })}
                style={{marginTop: (changeColor? -1 : (_.includes(bannerUrl, location.pathname) ? 50 : -1))}}
            >
                <Toolbar style={{ minHeight: 0 , maxHeight:100 , height:80 }}>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={() => setDrawerOpen(!drawerOpen)}
                            edge="start"
                            className={clsx(classes.menuButton, drawerOpen && classes.hide)}
                        >
                            <MenuRounded className={classes.navbarIcon} />
                        </IconButton>
                    </div>
                    <Link className={classes.logoContainer} component={RouterLink} to={"/"}>
                        <img className={classes.logoStyle} alt='BlackBixon Cafe' src="/images/logo.png" />
                    </Link>
                    <div className={classes.centerContent} />
                    <div className={classes.sectionDesktop}>
                        {
                            _.size(public_link) > 0 && _.map(public_link, (publicList, publicKey) => {
                                return (
                                    <Link component={RouterLink} to={publicList.link} className={classes.menuList} underline="none" key={publicKey}>
                                        <Typography className={classes.menuText} variant="h6">{publicList.label}</Typography>
                                    </Link>
                                )
                            })
                        }
                        {
                            _.size(list) > 0 && _.map(list, (menuList, menuKey) => {
                                return (
                                    <Link component={RouterLink} to={menuList.link} className={classes.menuList} underline="none" key={menuKey}>
                                        <Typography className={classes.menuText} variant="h6">{menuList.label}</Typography>
                                    </Link>
                                )
                            })
                        }
                        {/* {
                            isLogin &&
                            <div onClick={signOutUser} className={classes.menuList} style={{ cursor: 'pointer' }}>
                                <Typography className={classes.menuText} variant="h6">{t('auth.signOut')}</Typography>
                            </div>
                        } */}
                        {/* <Link component={RouterLink} to={isLogin ? "profile" : "sign-in"} className={classes.button}>
                            <BsPersonCircle size={20} className={classes.navbarIcon} />
                        </Link> */}
                        {/* <Link component={RouterLink} to="/search" className={classes.button}>
                            <BsSearch size={20} className={classes.navbarIcon} />
                        </Link> */}
                        {/* <Link component={RouterLink} to="/cart" className={classes.button}>
                            <Badge badgeContent={cartTotal} color="secondary">
                                <BsHandbag size={20} className={classes.navbarIcon} style={{ marginBottom: 5 }} />
                            </Badge>
                        </Link> */}
                        {/* <Button
                            variant="text"
                            onClick={handleLanguageMenuOpen}
                            className={classes.button}
                            startIcon={<LanguageRounded className={ classes.navbarText } />}
                        >
                            <Typography variant="button" className={ classes.navbarText }>{ t('header.language') }</Typography>
                        </Button> */}
                    </div>
                    <div className={classes.sectionMobile}>
                        <div className={classes.mobileBtnContainer}>
                            {/* <Link component={RouterLink} to={isLogin ? "profile" : "sign-in"} className={classes.button}>
                                <BsPersonCircle size={20} className={classes.navbarIcon} />
                            </Link> */}
                            {/* <Link component={RouterLink} to="/search" className={classes.button}>
                                <BsSearch size={20} className={ classes.navbarIcon } />
                            </Link>*/}
                            {/* <Link component={RouterLink} to="/cart" className={classes.button}>
                                <Badge badgeContent={cartTotal} color="secondary">
                                    <BsHandbag size={20} className={classes.navbarIcon} style={{ marginBottom: 10 }} />
                                </Badge>
                            </Link>  */}
                        </div>
                        {/* <IconButton
                            aria-label="show more"
                            aria-haspopup="true"
                            className={classes.mobileBtn}
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <LanguageRounded />
                        </IconButton> */}
                    </div>
                </Toolbar>
                {renderMobileMenu}
                {renderMenu}
            </AppBar>
            <AppDrawer drawerWidth={drawerWidth} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} public_link={public_link} list={list}/>
        </>
    )
})

// const AuthButton = memo((props) => {
//     const { t } = useTranslation();
//     const classes = useStyles();
//     const dispatch = useDispatch();
//     const { isLogin } = useSelector(state => state.auth);
//     console.log(isLogin);

//     const signOutUser = () => {
//         dispatch(logoutUser());
//     }

//     return (
//         <>
//             { isLogin ?
//                 <Button
//                     variant="text"
//                     className={classes.button}
//                     startIcon={<ExitToAppRounded className={ classes.navbarText } />}
//                     onClick={ signOutUser }
//                 >
//                     <Typography variant="button" className={ classes.navbarText }>{ t('auth.signOut') }</Typography>
//                 </Button>
//             :
//                 <>
//                     <Link component={RouterLink} to="/sign-in">
//                         {/* <Button
//                             variant="text"
//                             className={classes.button}
//                             startIcon={<BsPersonCircle className={ classes.navbarText } />}
//                         >
//                             <Typography variant="button" className={ classes.navbarText }>{ t('auth.signIn') }</Typography>
//                         </Button> */}
//                         <BsPersonCircle color="primary" />
//                     </Link> 
//                     <Link component={RouterLink} to="/sign-up">
//                         <Button
//                             variant="text"
//                             className={classes.button}
//                         >
//                             <Typography variant="button" color="textSecondary" className={ classes.navbarText }>{t('auth.signUp')}</Typography>
//                         </Button>
//                     </Link>
//                 </>
//             }
//         </>
//     )
// });

const useStyles = makeStyles((theme) => ({
    logoContainer: {
        position: 'absolute',
        zIndex: 2,
        [theme.breakpoints.up('xs')]: {
            left: 40,
        },
        [theme.breakpoints.up('sm')]: {
            left: 50,
        },
        [theme.breakpoints.up('md')]: {
            left: 60,
        },
    },
    logoStyle: {
        objectFit: 'contain',
        [theme.breakpoints.up('xs')]: {
            height: 50,
        },
        [theme.breakpoints.up('sm')]: {
            height: 45,
        },
        [theme.breakpoints.up('md')]: {
            height: 60,
        },
    },
    navbarText: {
        color: theme.palette.primary.main,
    },
    navbarIcon: {
        color: "#FFF",
        strokeWidth: ".5",
    },
    sectionDesktop: {
        display: 'none',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // [theme.breakpoints.up('xs')]: {
        //     marginTop: 25
        // },
        // [theme.breakpoints.up('sm')]: {
        //     marginTop: 28
        // },
        // [theme.breakpoints.up('md')]: {
        //     marginTop: 30
        // },
        zIndex:'998 !important',
    },
    appBarShift: {
        width: drawerWidth, //`calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    centerContent: {
        flexGrow: 1,
    },
    button: {
        margin: theme.spacing(2),
        padding: theme.spacing(2)
    },
    mobileBtn: {
        color: theme.palette.common.white,
    },
    mobileBtnContainer: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    menuList:{
        paddingLeft:'5px !important',
        paddingRight:'5px !important'
    },
    menuText:{
        color:'#ffffff !important',
        fontWeight:'bold !important',
        marginLeft:'5px !important',
        marginRight:'5px !important',
    },
    menuBar:{
        alignItems:'flex-start',
        display:'flex',
    },
    hide:{
        display:'none !important',
    },
    root: {
        flexGrow: 1,
        marginTop: 30,
        height: 5000
      },
}));