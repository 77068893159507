import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Button, Card, CardContent, CircularProgress, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { useToasts } from 'react-toast-notifications';

import { putUrl } from '@helpers/api';

const AccountSection = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [profileField, setProfileField] = useState({
        email: '',
        name: '',
        mobile: '',
        ic: '',
    });
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const userState = useSelector(state => state.user);
    
    useEffect(() => {
        if(userState) {
            setProfileField({
                name: userState.name || '', 
                mobile: userState.mobile || '', 
                ic: userState.ic || '', 
            });
        }
    }, [userState]);

    const updateProfileBtnClicked = () => {
        setLoading(true);
        putUrl("/user", profileField).then(response => {
            if(response.status) {
                setInputErrors({});
            } else {
                setInputErrors(response.errors);
            }

            if(response.message) {
                addToast(response.message, { appearance: response.status ? 'success' : 'error' });
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleChange = ({ target }) => {
        setProfileField({ ...profileField, [target.name]: target.value });
    }

    return (
        <Card variant="elevation" elevation={3} className={classes.cardContainerStyle}>
            <CardContent className={classes.cardContentRoot}>
                <div className={classes.paper}>
                    <Typography component="p" variant="h5">
                        { t('user.profile') }
                    </Typography>
                    <div className={classes.formContainer}>
                        <div className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ t('user.name') }
                                        value={profileField.name}
                                        autoComplete="name"
                                        name="name"
                                        onChange={handleChange}
                                        error={inputErrors?.name ? true : false }
                                        helperText={inputErrors?.name || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ t('user.email') }
                                        value={userState.email || ''}
                                        disabled
                                        InputLabelProps={{
                                            style: { fontFamily: 'OregonLdoDemibold' }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ t('user.mobile') }
                                        value={profileField.mobile}
                                        autoComplete="tel-national"
                                        name="mobile"
                                        onChange={handleChange}
                                        error={inputErrors?.mobile ? true : false }
                                        helperText={inputErrors?.mobile || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        label={ t('user.ic') }
                                        value={profileField.ic}
                                        autoComplete="ic"
                                        name="ic"
                                        onChange={handleChange}
                                        error={inputErrors?.ic ? true : false }
                                        helperText={inputErrors?.ic || ''}
                                        // InputLabelProps={{
                                        //     style: { fontFamily: 'OregonLdoDemibold' }
                                        // }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={ updateProfileBtnClicked }
                                            disabled={ loading ? true : false }
                                            // style={{ fontFamily:'OregonLdoRegular'}}
                                        >
                                            {
                                                loading ? 
                                                <CircularProgress size={20} />
                                                : t('general.update')
                                            }
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </CardContent>
        </Card>
    )
}

export default AccountSection;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginBottom: 10,
        marginTop:10,
    },
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    cardContentRoot: {
        padding: theme.spacing(6)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
    }
}))