import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const PolicyInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box marginTop={4}>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="h2" className={classes.titleStyle}>{t('title.policy')}</Typography>
                </Box>
            </div>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="justify">
                    <Container>
                        <ol>
                            <li key={1} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >Please contact us&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    immediately if you wish to cancel your Order after it has been placed. Please bear in mind that our Kitchen will begin processing and preparing your meal upon receipt of your order according to the scheduled delivery time.
                                </Typography>
                            </li><br />
                            <li key={2} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    will not be responsible or bear any cost if the address given is incorrect or inaccurate.
                                </Typography>
                            </li><br />
                            <li key={3} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    will not be responsible for late delivery due to the incorrect information/address or instructions provided by your end.
                                </Typography>
                            </li><br />
                            <li key={4} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    If food package found to be missing in the midst of delivery, please contact us immediately at&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ color:"blue", textDecorationLine: 'underline' }}>
                                    blackbixoncafe@gmail.com
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    &nbsp;or our contact at +6017 395 0321.
                                </Typography>
                            </li><br />
                            <li key={5} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    You have the right to cancel your Order provided our Kitchen has not yet accepted your Order. You have the right to a refund for a cancelled Order only if our Kitchen has not yet accepted your Order.
                                </Typography>
                            </li><br />
                            <li key={6} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    For advance orders (orders made more than 24 hours before specified delivery time), you may cancel your order before 11am of the day of the scheduled delivery and receive a full refund.&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    reserves the right to charge you 50% upfront as cancellation fee.
                                </Typography>
                            </li><br />
                            <li key={7} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    Should we confirm that you are owed a refund, we will provide you with further details on processing your refund. Refunds may be initiated to your credit card or original method of payment.
                                </Typography>
                            </li><br />
                            <li key={8} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    If you decide to cancel your order after it has been accepted by our Kitchen and arranged for delivery, you understand that no refunds (whether in whole or in part) will be issued to you and you forfeit the delivery of your cancelled Order.
                                </Typography>
                            </li><br />
                            <li key={9} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    reserves the right to cancel any Order and/or suspend, deactivate or terminate your customer account in its sole discretion if it reasonably suspects or detects fraudulent behaviour or activity associated with your&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    account and/or with your Order.
                                </Typography>
                            </li><br />
                            <li key={10} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ textDecorationLine: 'underline' }}>
                                    No Show Cancellation:
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    &nbsp;If you remain uncontactable or fail to receive the Order within ten (10) minutes from the time the Order arrives at your delivery address,&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    reserves the right to cancel the Order without refund or remedy to you.
                                </Typography>
                            </li><br />
                            <li key={11} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    Upon receipt of your Order, if you discover that there are issues with your Order (e.g. wrong order, defective order, or missing items) please contact customer support immediately. In some cases,&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    may request for photographic proof and/or additional information to properly investigate the issue with your Order. If we determine that the Order and/or Goods you received are not of satisfactory condition or quality, we will compensate you for your Order or parts of your Order.
                                </Typography>
                            </li><br />
                            <li key={12} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    Once your return is received and inspected, we will send you an email to notify you that we have received your returned item.
                                </Typography>
                            </li><br />
                            <li key={13} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    We will also notify you of the approval or rejection of your refund. If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.
                                </Typography>
                            </li><br />
                            <li key={14} style={{ paddingLeft: '10px' }}>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    For all cash payments, we do not do refunds. You will receive store credit in the amount of the payment which you can use on the website for any future purchase
                                </Typography>
                            </li><br />
                        </ol>
                    </Container>
                </Box>
            </div>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="h2" className={classes.titleStyle}>LATE OR MISSING REFUNDS (ONLY IF APPLICABLE)</Typography>
                </Box>
            </div>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="justify">
                <Container>
                    <Typography variant="body1" component="p" className={classes.textStyle} >
                        If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted. Next, contact your bank.
                    </Typography>
                    <br /><br />
                    <Typography variant="body1" component="p" className={classes.textStyle} >
                        There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at&nbsp;
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.textStyle} style={{ color: 'blue', textDecorationLine: 'underline' }}>
                        blackbixoncafe@gmail.com
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.textStyle} >
                        &nbsp;or our contact at +6017 395 0321.
                    </Typography>
                    </Container>
                    </Box>
            </div>
        </Box>
    )
}

export default PolicyInfo;

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textAlign: 'left !important',
        display: 'inline !important',
        // fontFamily:'NuezeitGroteskLight !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    titleStyle: {
        // fontFamily:'OregonLdoBold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '3.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem !important',
        },
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
    },
}))