import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { Button, CircularProgress, Box, Card, CardContent, Grid, IconButton, TextField, Typography , FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { BsPlusCircle } from 'react-icons/bs';
import { useToasts } from 'react-toast-notifications';
import AddressAccordion from './AddressAccordion';
import Geocode from "react-geocode";
import { GOOGLE_MAP_TOKEN } from '../../../../configs/config';

import { postUrl } from '@helpers/api';
import { updateAddresses } from '@actions/userActions';

Geocode.setApiKey(GOOGLE_MAP_TOKEN);
// Geocode.enableDebug();

const INITIAL_STATE = {
    name: '',
    unit: '',
    street: '',
    address1: '',
    address2: '',
    zip: '',
    city: '',
    state: '',
    country: '',
    username:'',
    email:'',
    mobile:'',
    coordinate:'',
};

const AddressSection = memo(() => {
    const [addAddressFormShow, setAddAddressFormShow] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [addressField, setAddressField] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const { addresses } = useSelector(state => state.user);

    const toggleAddAddress = () => {
        setAddAddressFormShow(!addAddressFormShow);
    }

    const addAddressBtnClicked = () => {
        setLoading(true);
        let address = addressField.unit + ' ' + addressField.street + ' ' + addressField.address2 + ' ' + addressField.zip + ' ' + addressField.city + ' ' + addressField.state + ' ' + addressField.country;
        Geocode.fromAddress(address).then(
            (response) => {
                const coordinate = '{"lon":'+parseFloat(response.results[0].geometry.location.lng)+',"lat":'+parseFloat(response.results[0].geometry.location.lat)+'}';
                const { lat, lng } = response.results[0].geometry.location;
                setAddressField({ ...addressField, coordinate: coordinate});
                let postData = {
                    name: addressField.name,
                    unit: addressField.unit,
                    street: addressField.street,
                    // address1: addressField.address1,
                    address2: addressField.address2,
                    zip: addressField.zip,
                    city: addressField.city,
                    state: addressField.state,
                    country: addressField.country,
                    coordinate: {
                        lon: lng,
                        lat: lat,
                    },
                    username: addressField.username,
                    email: addressField.email,
                    mobile: addressField.mobile,
                }
                postUrl(`/address`, postData).then(response => {
                    if(response.status) {
                        setInputErrors({});
                        dispatch(updateAddresses(response.data));
                        toggleAddAddress();
                        setAddressField(INITIAL_STATE)
                    } else {
                        setInputErrors(response.errors);
                    }
        
                    if(response.message) {
                        addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                    }
                    setLoading(false);
                }).catch(err => {
                    setLoading(false);
                    addToast(JSON.stringify(err), { appearance: 'error' });
                })
            },
            (error) => {
                setLoading(false);
                addToast(JSON.stringify(error), { appearance: 'error' });
            }
        );
    }

    const handleChange = ({ target }) => {
        setAddressField({ ...addressField, [target.name]: target.value });
    }

    return (
        <Card variant="elevation" elevation={3} className={classes.cardContainerStyle}>
            <CardContent className={classes.cardContentRoot}>
                <div className={classes.paper}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography component="p" variant="h5">
                            { t('user.addresses') }
                        </Typography>
                        <IconButton onClick={toggleAddAddress} color="primary" aria-label="add address" component="span">
                            <BsPlusCircle />
                        </IconButton>
                    </Box>
                    { addAddressFormShow ?
                        <Box>
                            <div className={classes.formContainer} noValidate>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Label"
                                            value={addressField.name}
                                            autoComplete="name"
                                            name="name"
                                            onChange={handleChange}
                                            error={inputErrors?.name ? true : false}
                                            helperText={inputErrors?.name || ''}
                                        />
                                        <FormHelperText>You can write your label as Home or Office.</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Recipient Name"
                                            value={addressField.username}
                                            autoComplete="name"
                                            name="username"
                                            onChange={handleChange}
                                            error={inputErrors?.username ? true : false}
                                            helperText={inputErrors?.username || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Recipient Email"
                                            value={addressField.email}
                                            autoComplete="email"
                                            name="email"
                                            type="email"
                                            onChange={handleChange}
                                            error={inputErrors?.email ? true : false}
                                            helperText={inputErrors?.email || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label="Recipient Mobile"
                                            value={addressField.mobile}
                                            autoComplete="name"
                                            name="mobile"
                                            onChange={handleChange}
                                            error={inputErrors?.mobile ? true : false}
                                            helperText={inputErrors?.mobile || ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.unit') }
                                            value={addressField.unit}
                                            autoComplete="unit"
                                            name="unit"
                                            onChange={handleChange}
                                            error={inputErrors?.unit ? true : false }
                                            helperText={inputErrors?.unit || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.street') }
                                            value={addressField.street}
                                            autoComplete="street"
                                            name="street"
                                            onChange={handleChange}
                                            error={inputErrors?.street ? true : false }
                                            helperText={inputErrors?.street || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            rows={2}
                                            multiline
                                            label={ t('address.address1') }
                                            value={addressField.address1}
                                            autoComplete="address-line1"
                                            name="address1"
                                            onChange={handleChange}
                                            error={inputErrors?.address1 ? true : false }
                                            helperText={inputErrors?.address1 || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            rows={2}
                                            multiline
                                            label={ t('address.address2') }
                                            value={addressField.address2}
                                            autoComplete="address-line2"
                                            name="address2"
                                            onChange={handleChange}
                                            error={inputErrors?.address2 ? true : false }
                                            helperText={inputErrors?.address2 || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.zip') }
                                            value={addressField.zip}
                                            autoComplete="postal-code"
                                            name="zip"
                                            onChange={handleChange}
                                            error={inputErrors?.zip ? true : false }
                                            helperText={inputErrors?.zip || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.city') }
                                            value={addressField.city}
                                            autoComplete="address-level2"
                                            name="city"
                                            onChange={handleChange}
                                            error={inputErrors?.city ? true : false }
                                            helperText={inputErrors?.city || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.state') }
                                            value={addressField.state}
                                            autoComplete="address-level1"
                                            name="state"
                                            onChange={handleChange}
                                            error={inputErrors?.state ? true : false }
                                            helperText={inputErrors?.state || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={ t('address.country') }
                                            value={addressField.country}
                                            autoComplete="country-name"
                                            name="country"
                                            onChange={handleChange}
                                            error={inputErrors?.country ? true : false }
                                            helperText={inputErrors?.country || ''}
                                            // InputLabelProps={{
                                            //     style: { fontFamily: 'OregonLdoDemibold' }
                                            // }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                onClick={ addAddressBtnClicked }
                                                disabled={ loading ? true : false }
                                            >
                                                { loading ? 
                                                    <CircularProgress size={20} />
                                                : t('general.create') }
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    : null }
                    <Box paddingTop={1}>
                        { _.map(addresses, addressItem => <AddressAccordion key={addressItem.id} {...addressItem} /> )}
                    </Box>
                </div>
            </CardContent>
        </Card>
    )
})

export default AddressSection;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginTop: 20,
    },    
    cardContentRoot: {
        padding: theme.spacing(6)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
    }
}))