import { postUrl, putUrl, getUrl } from '@helpers/api';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import Geocode from "react-geocode";
import { GOOGLE_MAP_TOKEN } from '../../../configs/config';
import { updateAddresses } from '@actions/userActions';
import { changeLoginState } from '@actions/generalActions';

import { Box, Button, Card, CardActionArea, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField, Typography, FormHelperText } from '@mui/material';
import { FiEdit, FiPlusSquare, FiUser, FiPhoneCall, FiMail } from "react-icons/fi";

const map_token = "pk.09c7d41fc2f3fc6c2b871fe7e2ecdc8d";
Geocode.setApiKey(GOOGLE_MAP_TOKEN);
// Geocode.enableDebug();

const CustomerInfo = (props) => {
    const { buyerInfo, setBuyerInfo, addresses, loading, setLoading, accessToken, carts, orderInfo, setDeliveryFeeInfo } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const [inputErrors, setInputErrors] = useState({});
    const [feeErrors, setFeeErrors] = useState({});

    const [buyerInfoDialog, setBuyerInfoDialog] = useState({
        open: false,
        name: '',
        email: '',
        mobile: '',
    });
    const [addressDialog, setAddressDialog] = useState({
        open: false,
        username: '',
        email: '',
        mobile: '',
        id: '',
        name: '',
        unit: '',
        street: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
        coordinate: {
            lat: '',
            lon: '',
        }
    });

    const [registerDialog, setRegisterDialog] = useState({
        open: false,
        name: '',
        email: '',
        mobile: '',
        ic: '',
        password: '',
        password_confirmation: '',
    });

    const [loginDialog, setLoginDialog] = useState({
        open: false,
        email: '',
        password: '',
        type: 'web',
    });

    const [mapCoordinate, setMapCoordinate] = useState([]);
    const [selectedCoordinate, setSelectedCoordinate] = useState('');
    // Buyer Info
    const handleBuyerInfoOpen = () => {
        setInputErrors({});
        setBuyerInfoDialog({
            open: true,
            name: buyerInfo.name,
            email: buyerInfo.email,
            mobile: buyerInfo.mobile,
        });
    }
    const handleBuyerInfoClose = () => {
        setInputErrors({});
        setBuyerInfoDialog({
            open: false,
            name: '',
            email: '',
            mobile: '',
        });
    }
    const handleBuyerInfoSave = () => {
        setBuyerInfo({
            ...buyerInfo,
            name: buyerInfoDialog.name,
            email: buyerInfoDialog.email,
            mobile: buyerInfoDialog.mobile,
        });
        handleBuyerInfoClose();
    }

    const handleRegisterUserOpen = () => {
        setInputErrors({});
        setRegisterDialog({
            ...registerDialog,
            open: true,
        });
    }

    const handleRegisterUserClose = () => {
        setInputErrors({});
        setRegisterDialog({
            ...registerDialog,
            open: false,
            name: '',
            email: '',
            mobile: '',
            ic: '',
            password: '',
            password_confirmation: '',
        });
    }

    const handleRegisterUserSave = () => {
        let postData = {
            name: registerDialog.name,
            email: registerDialog.email,
            mobile: registerDialog.mobile,
            ic: registerDialog.ic,
            password: registerDialog.password,
            password_confirmation: registerDialog.password_confirmation,
        }
        setLoading(true);
        postUrl('register', postData).then(response => {
            setLoading(false);
            let message = response.message;

            if (message) {
                addToast(message, { appearance: response.status ? 'success' : 'error' });
            }

            if (!response.status) {
                setInputErrors(response.errors);
            } else {
                setInputErrors({});
                setLoginDialog({ ...loginDialog, email: registerDialog.email, password: registerDialog.password });
                loginUser(registerDialog.email, registerDialog.password);
                handleRegisterUserClose();
            }

        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const handleLoginUserOpen = () => {
        setInputErrors({});
        setLoginDialog({
            ...loginDialog,
            open: true,
        });
    }

    const handleLoginUserClose = () => {
        setInputErrors({});
        setLoginDialog({
            ...loginDialog,
            open: false,
            email: '',
            password: '',
        });
    }

    const loginUser = (userEmail, userPassword) => {
        setLoading(true);
        let postData = {
            email: userEmail,
            password: userPassword,
            type: 'web',
        }

        setLoading(true);
        postUrl('login', postData).then(response => {
            setLoading(false);
            let message = response.message;
            if (!response.status) {
                setInputErrors(response.errors);
            } else {
                dispatch(changeLoginState(response.data));
                handleLoginUserClose();
            }

            if (message) {
                addToast(message, { appearance: response.status ? 'success' : 'error' });
            }
        }).catch(err => {
            setLoading(false);
            addToast(JSON.stringify(err), { appearance: 'error' });
        })
    }

    const buyerInfoBox = () => {
        return (
            <Box className={classes.outlineBox}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>Buyer Info</Typography>
                {
                    accessToken ?
                        <Card className={classes.buyerInfoBox}>
                            <CardHeader
                                // action={
                                //     <IconButton style={{ zIndex: 1 }} onClick={() => handleBuyerInfoOpen()} disabled={loading}>
                                //         <FiEdit size={15} style={{ color: theme.palette.primary.main }} />
                                //     </IconButton>
                                // }
                                style={{ paddingBottom: 0 }}
                            />
                            <CardContent style={{ marginTop: -20 }}>
                                <Typography>{t('user.name') + ": " + (buyerInfo.name ? buyerInfo.name : '-')}</Typography>
                                <Typography>{t('user.email') + ": " + (buyerInfo.email ? buyerInfo.email : '-')}</Typography>
                                <Typography>{t('user.mobile') + ": " + (buyerInfo.mobile ? buyerInfo.mobile : '-')}</Typography>
                            </CardContent>
                        </Card>
                        :
                        <div>
                            <Grid container spacing={2} padding={2} direction="row" justifyContent="flex-start" alignItems="center">
                                <Grid item>
                                    <Button variant="contained" onClick={() => handleRegisterUserOpen()} className={classes.buttonStyle}>
                                        <Typography className={classes.buttonTextStyle}>Sign Up</Typography>
                                    </Button>
                                </Grid>
                                <Grid item style={{ paddingLeft: 10 }}>
                                    <Button variant="contained" onClick={() => handleLoginUserOpen()} className={classes.buttonStyle}>
                                        <Typography className={classes.buttonTextStyle}>Sign In</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                }
            </Box>
        )
    }

    // Address
    const handleAddressOpen = (addressId) => {
        if (addressId) {
            let selectedAddress = _.find(addresses, function (addr) { return addr.id === addressId; });
            setAddressDialog({
                open: true,
                id: addressId,
                name: selectedAddress.name ? selectedAddress.name : '',
                unit: selectedAddress.unit ? selectedAddress.unit : '',
                street: selectedAddress.street ? selectedAddress.street : '',
                // address1: selectedAddress.address1 ? selectedAddress.address1 : '',
                address2: selectedAddress.address2 ? selectedAddress.address2 : '',
                zip: selectedAddress.zip ? selectedAddress.zip : '',
                city: selectedAddress.city ? selectedAddress.city : '',
                state: selectedAddress.state ? selectedAddress.state : '',
                country: selectedAddress.country ? selectedAddress.country : '',
                coordinate: selectedAddress.coordinate ? selectedAddress.coordinate : '',
                username: selectedAddress?.username ? selectedAddress?.username : '',
                email: selectedAddress?.email ? selectedAddress?.email : '',
                mobile: selectedAddress?.mobile ? selectedAddress?.mobile : '',
            });
        } else {
            setAddressDialog({
                open: true,
                id: '',
                name: '',
                unit: '',
                street: '',
                address1: '',
                address2: '',
                zip: '',
                city: '',
                state: '',
                country: '',
                username: '',
                email: '',
                mobile: '',
            });
        }
    }
    const handleAddressClose = () => {
        setInputErrors({});
        setAddressDialog({
            open: false,
            id: '',
            name: '',
            unit: '',
            street: '',
            address1: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            username: '',
            email: '',
            mobile: '',
        });
        setMapCoordinate([]);
        setSelectedCoordinate('');
    }
    const handleAddressSave = () => {
        setLoading(true);
        let postData = {
            name: addressDialog.name,
            unit: addressDialog.unit,
            street: addressDialog.street,
            // address1: addressDialog.address1,
            address2: addressDialog.address2,
            zip: addressDialog.zip,
            city: addressDialog.city,
            state: addressDialog.state,
            country: addressDialog.country,
            coordinate: {
                lon: (selectedCoordinate >= 0 && _.size(mapCoordinate) > 0 ? parseFloat(mapCoordinate[selectedCoordinate]['geometry']['location']['lng']) : (addressDialog?.coordinate?.lon ? addressDialog?.coordinate?.lon : [])),
                lat: (selectedCoordinate >= 0 && _.size(mapCoordinate) > 0 ? parseFloat(mapCoordinate[selectedCoordinate]['geometry']['location']['lat']) : (addressDialog?.coordinate?.lat ? addressDialog?.coordinate?.lat : [])),
            },
            username: addressDialog.username,
            email: addressDialog.email,
            mobile: addressDialog.mobile,
        }
        if (addressDialog.id) {
            // update
            putUrl(`/address/${addressDialog.id}`, postData).then(response => {
                if (response.status) {
                    handleAddressClose();
                    dispatch(updateAddresses(response.data));
                } else {
                    setInputErrors(response.errors);
                }
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        } else {
            // add
            postUrl(`/address`, postData).then(response => {
                if (response.status) {
                    handleAddressClose();
                    dispatch(updateAddresses(response.data));
                } else {
                    setInputErrors(response.errors);
                }
                if (response.message) {
                    addToast(response.message, { appearance: response.status ? 'success' : 'error' });
                }
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
        }
    }
    const buyerAddressBox = () => {
        if (orderInfo.type === 'delivery') {
            return (
                <Box className={classes.outlineBox}>
                    <Box className={classes.spaceBetween}>
                        <Typography variant="h6">Delivery Address</Typography>
                        <Box className={classes.spaceBetween}>
                            <IconButton className={classes.addNewAddress} onClick={() => handleAddressOpen(0)} disabled={loading}>
                                <FiPlusSquare size={20} style={{ color: theme.palette.primary.main }} />
                            </IconButton>
                            <Typography style={{ paddingTop: 5 }}>Add Address</Typography>
                        </Box>
                    </Box>
                    <Box className={classes.addressBox}>
                        {
                            _.size(addresses) > 0
                                ?
                                <Grid container spacing={2}>
                                    {
                                        _.map(addresses, (address, key) => {
                                            return (
                                                <Grid item xs={6} key={key} md={3}>
                                                    <Card className={(address.id === buyerInfo.address_id) ? classes.selectedAddressItemBox : classes.addressItemBox}>
                                                        <CardHeader
                                                            action={
                                                                <IconButton style={{ zIndex: 1 }} onClick={() => handleAddressOpen(address.id)} disabled={loading}>
                                                                    <FiEdit size={15} style={{ color: (address.id === buyerInfo.address_id) ? '#ffffff' : theme.palette.primary.main }} />
                                                                </IconButton>
                                                            }
                                                            style={{ paddingBottom: 0 }}
                                                        />
                                                        <CardActionArea style={{ marginTop: -40 }} onClick={() => setBuyerInfo({ ...buyerInfo, address_id: address.id })} disabled={loading}>
                                                            <CardContent>
                                                                <Typography>{address.name}</Typography>
                                                                <Typography><FiUser /> {address?.username ? address?.username : ""}</Typography>
                                                                <Typography><FiMail /> {address?.email ? address?.email : ""}</Typography>
                                                                <Typography><FiPhoneCall /> {address?.mobile ? address?.mobile : ""}</Typography>
                                                                <Typography>{address.full_address}</Typography>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                :
                                <Box style={{ marginTop: 20, textAlign: "center" }}>
                                    <Typography variant="body2" style={{ marginBottom: 10 }}>No Address</Typography>
                                </Box>
                        }
                    </Box>
                </Box>
            )
        }
    }

    const checkMap = () => {
        setLoading(true)
        setMapCoordinate([]);
        setSelectedCoordinate('');
        setInputErrors({});
        let address = addressDialog.unit + ' ' + addressDialog.street + ' ' + addressDialog.address2 + ' ' + addressDialog.zip + ' ' + addressDialog.city + ' ' + addressDialog.state + ' ' + addressDialog.country;
        Geocode.fromAddress(address).then(
            (response) => {
                setLoading(false);
                // const { lat, lng } = response.results[0].geometry.location;
                setMapCoordinate(response.results)
            },
            (error) => {
                setLoading(false);
                addToast(JSON.stringify(error), { appearance: 'error' });
            }
        );
    }

    // const handleGetDeliveryFee = () => {
    //     let food_type = 1; //halal

    //     _.map(carts, productItem => {
    //         if (productItem.product.type === 2) {
    //             food_type = 2; // non-halal
    //         }
    //     })

    //     let postData = {
    //         store_id: 1,
    //         address_id: buyerInfo.address_id,
    //         food_type: food_type,
    //     };

    //     setFeeErrors({});
    //     getUrl('/get-delivery-fee-quotation', postData).then(response => {
    //         if (response.status) {
    //             setDeliveryFeeInfo(response.data);
    //         } else {
    //             if (response.errors) {
    //                 setFeeErrors(response.errors);
    //             }
    //         }

    //         if (response?.message) {
    //             addToast(response.message, { appearance: response.status ? 'success' : 'error' });
    //         }

    //     }).catch(err => {
    //         addToast(JSON.stringify(err), { appearance: 'error' });
    //     })
    // }

    // useEffect(() => {
    //     if (orderInfo.type === 'delivery') {
    //         handleGetDeliveryFee();
    //     }
    //     setDeliveryFeeInfo({});
    //     // eslint-disable-next-line
    // }, [buyerInfo.address_id, orderInfo.type]);

    const displayMap = () => {
        let address = addressDialog.unit + ' ' + addressDialog.street + ' ' + addressDialog.address2;
        let addressReplaced = address.replaceAll(' ', '+');
        let show_map = false;
        if (addressDialog?.coordinate?.lat && addressDialog?.coordinate?.lon) {
            show_map = true;
        } else if (_.size(mapCoordinate) > 0) {
            show_map = true;
        }
        if (show_map) {
            return (
                <Grid item xs={12}>
                    {/* <img src={`https://maps.locationiq.com/v3/staticmap?key=${map_token}&size=550x400&zoom=20&format=png&markers=icon:large-orange-cutout|${(selectedCoordinate !== '' && selectedCoordinate >= 0 ? parseFloat(mapCoordinate[selectedCoordinate]['coordinates']['lat']) : (addressDialog?.coordinate?.lat ? addressDialog?.coordinate?.lat : 3.146152251042474))},${(selectedCoordinate !== '' && selectedCoordinate >= 0 ? parseFloat(mapCoordinate[selectedCoordinate]['coordinates']['lon']) : (addressDialog?.coordinate?.lon ? addressDialog?.coordinate?.lon : 101.71778679877822))}`} alt="coordinate map" /> */}
                    <img src={`https://maps.googleapis.com/maps/api/staticmap?key=${GOOGLE_MAP_TOKEN}&size=550x400&zoom=18&format=png&markers=color:red|label:S|${addressReplaced}&center=${addressReplaced}`} alt="coordinate map" />
                </Grid>
            )
        } else return null
    };

    return (
        <div>
            {/* ERROR DISPLAY */}
            {
                _.size(feeErrors) > 0
                    ?
                    <Box className={classes.inputErrorBox} paddingLeft={3}>
                        {
                            _.map(feeErrors, (err, key) => {
                                return (
                                    <Typography key={key} color="error">{err}</Typography>
                                )
                            })
                        }
                    </Box>
                    :
                    null
            }
            {buyerInfoBox()}
            {accessToken && buyerAddressBox()}
            {/* Buyer Info Dialog */}
            <Dialog open={buyerInfoDialog.open} onClose={handleBuyerInfoClose} style={{ zIndex: 999 }}>
                <DialogTitle>Buyer Info</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.name')}
                                value={buyerInfoDialog.name}
                                name="name"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.email')}
                                value={buyerInfoDialog.email}
                                name="email"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('user.mobile')}
                                value={buyerInfoDialog.mobile}
                                name="mobile"
                                onChange={(event) => setBuyerInfoDialog({ ...buyerInfoDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.mobile ? true : false}
                                helperText={inputErrors?.mobile || ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleBuyerInfoClose}>{t('general.cancel')}</Button>
                    <Button className={classes.confirmButton} onClick={handleBuyerInfoSave}>{t('general.update')}</Button>
                </DialogActions>
            </Dialog>
            {/* Address Dialog */}
            <Dialog open={addressDialog.open} onClose={handleAddressClose} style={{ zIndex: 999 }}>
                <DialogTitle>
                    <Grid container spacing={2} direcion="row" justifyContent="space-between" alignItems="center">
                        <Grid item xs>
                            <Typography>{addressDialog.id ? "Edit Address" : "Add Address"}</Typography>
                        </Grid>
                        <Grid item xs container justifyContent="flex-end" alignItems="center">
                            <Button variant="contained" onClick={checkMap}> Check Map</Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Label"
                                value={addressDialog.name}
                                autoComplete="name"
                                name="name"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                            <FormHelperText>You can write your label as Home or Office.</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Recipient Name"
                                value={addressDialog.username || ""}
                                autoComplete="name"
                                name="username"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.username ? true : false}
                                helperText={inputErrors?.username || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Recipient Email"
                                value={addressDialog.email || ""}
                                autoComplete="email"
                                name="email"
                                type="email"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Recipient Mobile"
                                value={addressDialog.mobile || ""}
                                autoComplete="mobile"
                                name="mobile"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.mobile ? true : false}
                                helperText={inputErrors?.mobile || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Floor/Unit"
                                value={addressDialog.unit || ""}
                                autoComplete="unit"
                                name="unit"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.unit ? true : false}
                                helperText={inputErrors?.unit || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Street"
                                value={addressDialog.street || ""}
                                autoComplete="street"
                                name="street"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.street ? true : false}
                                helperText={inputErrors?.street || ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('address.address1')}
                                value={addressDialog.address1 ||  ""}
                                autoComplete="address-line1"
                                name="address1"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.address1 ? true : false}
                                helperText={inputErrors?.address1 || ''}
                            />
                        </Grid> */}
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('address.address2')}
                                value={addressDialog.address2 || ""}
                                autoComplete="address-line2"
                                name="address2"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.address2 ? true : false}
                                helperText={inputErrors?.address2 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.zip')}
                                value={addressDialog.zip || ""}
                                autoComplete="postal-code"
                                name="zip"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.zip ? true : false}
                                helperText={inputErrors?.zip || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.city')}
                                value={addressDialog.city || ""}
                                autoComplete="address-level2"
                                name="city"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.city ? true : false}
                                helperText={inputErrors?.city || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.state')}
                                value={addressDialog.state || ""}
                                autoComplete="address-level1"
                                name="state"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.state ? true : false}
                                helperText={inputErrors?.state || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label={t('address.country')}
                                value={addressDialog.country || ""}
                                autoComplete="country-name"
                                name="country"
                                onChange={(event) => setAddressDialog({ ...addressDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.country ? true : false}
                                helperText={inputErrors?.country || ''}
                            />
                        </Grid>
                        {
                            _.size(mapCoordinate) > 0 ?
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                        select
                                        label="Map Result"
                                        value={selectedCoordinate}
                                        onChange={(event) => setSelectedCoordinate(event.target.value)}
                                    >
                                        {
                                            _.map(mapCoordinate, (point, pointIndex) => {
                                                return <MenuItem key={pointIndex} value={pointIndex}>{point.formatted_address}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Grid>
                                : null

                        }
                        {displayMap()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleAddressClose}>{t('general.cancel')}</Button>
                    {
                        (selectedCoordinate !== '' && selectedCoordinate >= 0) || (addressDialog?.coordinate?.lat && addressDialog?.coordinate?.lon) || addressDialog.id ?
                            <Button className={classes.confirmButton} onClick={handleAddressSave}>{addressDialog.id ? t('general.update') : t('general.create')}</Button>
                            : null
                    }
                </DialogActions>
            </Dialog>
            {/*Register Dialog*/}
            <Dialog open={registerDialog.open} onClose={handleRegisterUserClose} style={{ zIndex: 999 }}>
                <DialogTitle>Register Account</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Name"
                                value={registerDialog.name}
                                autoComplete="name"
                                name="name"
                                autoFocus
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Email"
                                value={registerDialog.email}
                                autoComplete="email"
                                name="email"
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Mobile"
                                value={registerDialog.mobile}
                                autoComplete="mobile"
                                name="mobile"
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.mobile ? true : false}
                                helperText={inputErrors?.mobile || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="IC"
                                value={registerDialog.ic}
                                // autoComplete="ic"
                                name="ic"
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.ic ? true : false}
                                helperText={inputErrors?.ic || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Password"
                                value={registerDialog.password}
                                autoComplete="new-password"
                                name="password"
                                type="password"
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.password ? true : false}
                                helperText={inputErrors?.password || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Password Confirmation"
                                value={registerDialog.password_confirmation}
                                autoComplete="new-password"
                                name="password_confirmation"
                                type="password"
                                onChange={(event) => setRegisterDialog({ ...registerDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.ic ? true : false}
                                helperText={inputErrors?.ic || ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleRegisterUserClose}>
                        <Typography className={classes.buttonTextStyle} >{t('general.cancel')}</Typography>
                    </Button>
                    <Button className={classes.confirmButton} >
                        {
                            loading ?
                                <CircularProgress size={20} style={{ color: 'white' }} />
                                :
                                <Typography className={classes.buttonTextStyle} onClick={handleRegisterUserSave}>{t('general.submit')}</Typography>
                        }
                    </Button>
                </DialogActions>
            </Dialog>
            {/*Login Dialog*/}
            <Dialog open={loginDialog.open} onClose={handleLoginUserClose} style={{ zIndex: 999 }}>
                <DialogTitle>Login Account</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Email"
                                required
                                value={loginDialog.email}
                                autoComplete="email"
                                name="email"
                                autoFocus
                                onChange={(event) => setLoginDialog({ ...loginDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.email ? true : false}
                                helperText={inputErrors?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                label="Password"
                                required
                                value={loginDialog.password}
                                autoComplete="new-password"
                                name="password"
                                type="password"
                                onChange={(event) => setLoginDialog({ ...loginDialog, [event.target.name]: event.target.value })}
                                error={inputErrors?.password ? true : false}
                                helperText={inputErrors?.password || ''}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleLoginUserClose}>
                        <Typography className={classes.buttonTextStyle} >{t('general.cancel')}</Typography>
                    </Button>
                    <Button className={classes.confirmButton}>
                        {
                            loading ?
                                <CircularProgress size={20} style={{ color: 'white' }} />
                                :
                                <Typography className={classes.buttonTextStyle} onClick={() => { loginUser(loginDialog.email, loginDialog.password) }}>{t('general.submit')}</Typography>
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CustomerInfo;

const useStyles = makeStyles((theme) => ({
    buyerInfoBox: {
        backgroundColor: theme.palette.secondary.main + ' !important',
        color: theme.palette.primary.main + ' !important',
        width: "50%",
    },
    outlineBox: {
        margin: "20px !important"
    },
    addressBox: {
        padding: 16
    },
    addressItemBox: {
        backgroundColor: theme.palette.secondary.main + ' !important',
        color: theme.palette.primary.main + ' !important',
    },
    selectedAddressItemBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
        color: '#ffffff !important',
    },
    confirmButton: {
        backgroundColor: theme.palette.primary.main + '! important',
        color: '#ffffff ! important',
    },
    cancelButton: {
        color: theme.palette.primary.main + '! important',
        backgroundColor: '#ffffff ! important',
    },
    spaceBetween: {
        justifyContent: "space-between",
        display: "flex"
    },
    addNewAddress: {
        color: theme.palette.secondary.main + '! important',
    },
    buttonStyle: {
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
}))