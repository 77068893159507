// LIBRARY
import React, { memo, Suspense, useCallback, useLayoutEffect } from 'react';
import { Provider, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from 'react-toast-notifications';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';

// UI LIBRARY, STYLES, UI RELATED, IMPORT STYLE
import { ThemeProvider } from '@mui/material/styles';
import { Box, CircularProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import theme from "./theme";
import { AppHeader, AppFooter, NotFound } from '@layouts';

// IMPORT 
import { store, persistor } from '@configs/store';
import { public_restricted_routes, public_routes, private_routes } from '@configs/router';
import ScrollToTop from '@components/ScrollToTop';

const App = () => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ToastProvider placement="bottom-right" autoDismiss autoDismissTimeout={3000}>
                    <ThemeProvider theme={theme}>
                        <BrowserRouter>
                            <CssBaseline />
                            <Suspense fallback={<LoadingSpinner />}>
                                <Main />
                            </Suspense>
                        </BrowserRouter>
                    </ThemeProvider>
                </ToastProvider>
            </PersistGate>
        </Provider>
    )
}

const LoadingSpinner = memo(() => {
    return (
        <Box position="fixed" top="50%" left="50%">
            <CircularProgress disableShrink color='primary' />
        </Box>
    )
})

const Wrapper = ({ children }) => {
    const location = useLocation();
    let path = location.pathname.split('/');
    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [path[1]]);
    return children
}

const Main = () => {
    const { isLogin } = useSelector(state => state.general);

    const renderPublicRoute = useCallback(() => {
        return public_routes.map((route, index) =>
            <Route key={index} element={route.component} path={route.path} />
        )
    }, []);

    const renderAuthRestrictedRoute = useCallback(() => {
        return public_restricted_routes.map((route, index) =>
            <Route key={index} element={isLogin ? <Navigate to="/" replace={true} /> : route.component} path={route.path} />
        )
    }, [isLogin]);

    const renderPrivateRoute = useCallback(() => {
        return private_routes.map((route, index) =>
            <Route key={index} element={isLogin ? route.component : <Navigate to="/" replace={true} />} path={route.path} />
        )
    }, [isLogin]);

    return (
        <>
            <AppHeader />
            <Wrapper>
                <Routes>
                    {renderPublicRoute()}
                    {renderAuthRestrictedRoute()}
                    {renderPrivateRoute()}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Wrapper>
            <AppFooter />
            <ScrollToTop />
        </>
    )
}


export default App

