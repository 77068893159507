import React, { useEffect, useState } from 'react';

import { Box, Container, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUrl } from '@helpers/api';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import LocateUsMap from './LocateUsMap';
import LocateUsInfo from './LocateUsInfo';
import LocateUsButton from './LocateUsButton';
import HomeLocateUs from '@guestpages/Home/HomeLocateUs';

const LocateUs = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    // const [cms, setCms] = useState({});

    // useEffect(() => {
    //     getUrl(`/settings/cms/layout`, { "code": "contact-us" }).then(result => {
    //         setCms(result.data.value.data);
    //     }).catch((error) => {
    //         // addToast('', error.message || t('error.contactSupport'), 'error', '');
    //     })
    //     // eslint-disable-next-line
    // }, [])

    // const renderPage = (page) => {
    //     switch (page.section) {
    //         case "map":
    //             return (
    //                 <Container className="app-container" maxWidth="lg" key={page.section}>
    //                     <LocateUsMap />
    //                 </Container>
    //             );
    //         case "description":
    //             return (
    //                 <Container className="app-container" maxWidth="lg" key={page.section}>
    //                     <LocateUsInfo data={page} />
    //                 </Container>
    //             )
    //         case "button":
    //             return (
    //                 <Container className="app-container" maxWidth="lg" key={page.section}>
    //                     <LocateUsButton data={page} />
    //                 </Container>
    //             )
    //         default:
    //             return null
    //     }
    // }
    return (
        <Box paddingTop={4}>
            {/* {
                _.size(cms) > 0 &&
                _.map(cms, page => (
                    renderPage(page)
                ))
            } */}
            <Container className="app-container" maxWidth="lg">
            <div>
                <Typography variant="h2" className={classes.titleStyle}>Our Location</Typography>
            </div>
            <Grid container spacing={2} style={{ paddingTop:30, paddingLeft:50 , paddingRight:50 }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>Jalan Inai (Flagship Cafe)</Typography>
                    <Typography variant="h6">No. 17&17A, Jalan Inai, </Typography>
                    <Typography variant="h6">Off Jalan Imbi, 55100, </Typography>
                    <Typography variant="h6">Kuala Lumpur, Malaysia  </Typography>
                    <Typography variant="h6">Hour: 8AM - 10PM DAILY</Typography>
                    <Typography variant="h6">Phone Number: <img src="./images/locate-us/whatsapp.png" style={{ height: 17, width: 17, objectFit: 'cover' }} alt="whatapp" /> <a href="tel:0173950321" style={{ color: 'black', textDecoration: 'none' }}>017-395 0321</a></Typography>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6">Find Us:</Typography>
                        <a href="https://maps.app.goo.gl/MCRVbvRU24pdWBxZ6" target="_blank" rel="noreferrer"><img src="./images/locate-us/google-maps.png" style={{ height: 30, width: 30, objectFit: 'cover' , margin:5 }} alt="googel map" /></a>
                        <a href="https://www.waze.com/en/live-map/directions/my/wilayah-persekutuan-kuala-lumpur/kuala-lumpur/blackbixon-cafe-and-restaurant-jalan-inai?place=ChIJB6Lzswc3zDERUYLIIpe6PUk" target="_blank" rel="noreferrer"><img src="./images/locate-us/waze.png" style={{ height: 30, width: 30, objectFit: 'cover' , margin:5 }} alt="waze" /></a>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ height:250 }}>
                        <img alt="Flagship Cafe" src="./images/locate-us/flagship-cafe.jpg" style={{ height: '100%', width: '100%', objectFit: 'cover' }} loading='lazy' />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ paddingTop:40}}>
                    <div className={classes.imageContainerStyle}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.8047056726464!2d101.7185592752072!3d3.1461790327095756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc3707b3f3a207%3A0x493dba9722c88251!2sBlackbixon%20Cafe!5e0!3m2!1sen!2smy!4v1656340727351!5m2!1sen!2smy" width="100%" height="400" className={classes.mapStyle} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="Black Bixon Cafe Map"></iframe>
                    </div>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop:100, paddingLeft:50 , paddingRight:50 }}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h5" style={{ fontWeight: 'bold' }}>PJ33</Typography>
                    <Typography variant="h6">Ground floor, PJ33, No. 3, </Typography>
                    <Typography variant="h6">Jalan Prof. Khoo Kay Kim, </Typography>
                    <Typography variant="h6">Seksyen 13, 46200 Petaling Jaya  </Typography>
                    <Typography variant="h6">Hour: 8AM - 10PM DAILY</Typography>
                    <Typography variant="h6">Phone Number: <img src="./images/locate-us/whatsapp.png" style={{ height: 17, width: 17, objectFit: 'cover' }} alt="whatapp" /> <a href="tel:0125480321" style={{ color: 'black', textDecoration: 'none' }}>012-548 0321</a></Typography>
                    <div style={{ display: 'flex' }}>
                        <Typography variant="h6">Find Us:</Typography>
                        <a href="https://maps.app.goo.gl/YbiFAPDZ1U4ELrYM6" target="_blank" rel="noreferrer"><img src="./images/locate-us/google-maps.png" style={{ height: 30, width: 30, objectFit: 'cover' , margin:5 }} alt="googel map" /></a>
                        <a href="https://www.waze.com/en/live-map/directions/my/selangor/pj/blackbixon-cafe-and-restaurant-pj33?place=ChIJy4-ZecRLzDERoGLSym47ldE" target="_blank" rel="noreferrer"><img src="./images/locate-us/waze.png" style={{ height: 30, width: 30, objectFit: 'cover' , margin:5 }} alt="waze" /></a>
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div style={{ height:250 }}>
                        <img alt="Flagship Cafe" src="./images/locate-us/pj33.jpeg" style={{ height: '100%', width: '100%', objectFit: 'cover' }} loading='lazy' />
                    </div>
                </Grid>
                <Grid item xs={12} style={{ paddingTop:40}}>
                    <div className={classes.imageContainerStyle}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3983.9392647923764!2d101.63471337470324!3d3.110772096864785!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4bc479998fcb%3A0xd1953b6ecad262a0!2sBlackBixon%20Cafe%20%26%20Restaurant%20-%20PJ33!5e0!3m2!1sen!2smy!4v1704940677588!5m2!1sen!2smy" width="100%" height="400" className={classes.mapStyle} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="PJ33 Mall Map"></iframe>
                    </div>
                </Grid>
            </Grid>
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}
export default LocateUs

const useStyles = makeStyles((theme) => ({
    containerStyle: {
        position: 'relative !important',
        width: '100% !important',
        overflow: 'hidden !important',
        border: 'solid 5px',
        borderRadius: 10,
        borderColor: theme.palette.primary.main,
    },
    titleStyle: {
        textTransform: "capitalize",
        fontFamily:'OregonLdo !important',
        marginTop: 10,
        marginBottom: 10,
        textAlign: 'center',
    },
    subtitleStyle: {
        // fontFamily: 'OregonLdoRegular !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
        textAlign: 'center',
    },
    textStyle: {
        color: '#000000 !important',
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        paddingLeft: '10px !important',
        paddingRight: '10px !important',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    roundedButtonStyle: {
        marginTop: '5px !important',
        borderRadius: '20px !important',
        width: 'fit-content',
        marginBottom: '5px !important',
        backgroundColor: theme.palette.button.main + '!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main + '!important',
        },
    },
    infoStyle: {
        marginTop: 20,
        marignBottom: 20,
    },
    imageContainerStyle: {
        position: 'relative !important',
        width: '100% !important',
        overflow: 'hidden !important',
        border: 'solid 5px',
        borderRadius: 10,
        borderColor: theme.palette.primary.main,
    },
    mapStyle: {
        position: 'absolute important',
        top: 0,
        left: 0,
        width: '100 % !important',
        height: '100 % !important',
    },
}))