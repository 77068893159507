import React, { memo } from 'react';
import _ from 'lodash';

import { Box, Typography, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CoffeePackageHeader = memo(({ data }) => {
    const classes = useStyles();

    return (
        <Box paddingTop={4} >
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <div className={classes.imageContainerStyle}>
                        <img className={classes.imageStyle} alt="BlackBixon Cafe" src={_.size(data?.image) ? data?.image : "../images/coffee-package/coffee-package-header/coffee-package-header.png"} width={269} height={369}/>
                    </div>
                </Grid>
                {
                    _.size(data.text) ?
                        <Grid item xs={12} md={8} container direction="column" justifyContent="flex-end" className={classes.alignStyle}>
                            <div dangerouslySetInnerHTML={{ __html: data.text }} />
                        </Grid>
                        :
                        <Grid item xs={12} md={8} container direction="column" justifyContent="flex-end" className={classes.alignStyle}>
                            <Typography variant="h4" className={classes.titleStyle}>In Love</Typography>
                            <Typography variant="h4" className={classes.titleStyle}>With Our</Typography>
                            <Typography variant="h4" className={classes.titleStyle}>Coffee?</Typography>
                            <div style={{ paddingTop: 10, paddingBottom: 10 }} className={classes.textAlignStyle}>
                                <Typography variant="h4" className={classes.titleStyle}>Take ém</Typography>
                                <Typography variant="h4" className={classes.titleStyle}>Home!</Typography>
                            </div>
                            <div>
                                <Typography variant="h4" className={classes.uppercaseStyle}>Make your home feel like</Typography>
                                <div className={classes.boxStyle}>
                                    <Typography variant="h4" className={classes.uppercaseStyle}>a &nbsp;</Typography>
                                    <Typography variant="h4" className={classes.colorTextStyle} style={{ textTransform: 'uppercase' }}> blackbixon<sup>tm</sup> cafe</Typography>
                                </div>
                                <div className={classes.boxStyle}>
                                    <Typography variant="h4" >from only &nbsp;</Typography>
                                    <Typography variant="h4" className={classes.colorTextStyle} style={{ textTransform: 'uppercase', fontStyle: 'italic' }} >RM2.50 &nbsp;</Typography>
                                    <Typography variant="h4" >per cup</Typography>
                                </div>
                            </div>
                        </Grid>
                }
            </Grid>
        </Box>
    )
});

export default CoffeePackageHeader;

const useStyles = makeStyles((theme) => ({
    imageContainerStyle: {
        flex: 1,
        padding: 10
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'end',
    },
    imageStyle: {
        width: '100%',
        objectFit: 'contain',
        maxWidth: 400,
    },
    titleStyle: {
        // fontFamily:'OregonLdoUltraBlack !important',
        fontWeight: 'bold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '2.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem !important',
        },
    },
    uppercaseStyle: {
        textTransform: "uppercase",
        // fontFamily:'NuezeitGroteskLight !important',
    },
    colorTextStyle: {
        color: theme.palette.secondary.main,
        // fontFamily:'NuezeitGroteskLight !important',
    },
    boxStyle: {
        display: 'flex',
        justifyContent: 'flex-end !important',
        flexDirection: 'column !important',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row !important',
            justifyContent: 'flex-start !important',
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end !important',
        },
    },
    alignStyle: {
        alignItems: 'flex-end !important',
        [theme.breakpoints.up('xs')]: {
            alignItems: 'flex-start !important',
        },
        [theme.breakpoints.up('sm')]: {
            alignItems: 'flex-start !important',
        },
        [theme.breakpoints.up('md')]: {
            alignItems: 'flex-end !important',
        },
    },
    textAlignStyle: {
        textAlign: 'end',
        [theme.breakpoints.up('xs')]: {
            textAlign: 'start',
        },
        [theme.breakpoints.up('sm')]: {
            textAlign: 'start',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'end',
        },
    }
}))