import React from 'react';
import { Box, Typography, Container, Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const TncInfo = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Box marginTop={4}>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="h2" className={classes.titleStyle} style={{  textTransform: "uppercase", }}>{t('title.tnc')}</Typography>
                </Box>
            </div>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="justify">
                    <Container>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            Date: 28th JULY 2022
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            BLACKBIXON CAFE is committed to facilitating the accessibility and usability of its website,&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ color: 'blue', textDecorationLine: 'underline' }}>
                            blackbixoncafe@gmail.com
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            ,&nbsp;for all people with disabilities. BLACKBIXON CAFÉ is continually implementing the relevant portions of the World Wide Web Consortium's Web Content Accessibility Guidelines as its web accessibility standard. Our website is tested on a periodic basis with assistive technology.
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            Please be aware that our efforts are ongoing and evolve as our website evolves. If, at any time, you have specific questions or concerns about the accessibility of any particular Web page on&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ color: 'blue', textDecorationLine: 'underline' }}>
                            blackbixoncafe@gmail.com
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            ,&nbsp;please contact us at&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ color: 'blue', textDecorationLine: 'underline' }}>
                            blackbixoncafe@gmail.com
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            .&nbsp;If you do encounter an accessibility issue, please be sure to specify the issue in your email, and we will make all reasonable efforts to make that page accessible for you.
                        </Typography>
                    </Container>
                </Box>
            </div><br />
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
                    <Typography variant="h2" className={classes.titleStyle}>TERMS OF USE</Typography>
                </Box>
            </div>
            <div className={classes.containerStyle}>
                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="justify">
                    <Container>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            Date: April 1, 2015
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            This web site and the materials and information on this web site are provided by&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            ("www.blackbixoncafe.com" or "we") and may be used for informational purposes only. By using this web site, you represent that you have read and understand these terms and conditions and agree to be bound by these terms and conditions. Furthermore, by using this web site, you represent that (i) you have the capacity to be bound by these terms and conditions and (ii) if you are acting on behalf of a company or other entity, you have the authority to bind such company or entity. If you do not agree to these terms and conditions, do not use this web site or download any materials from this web site. hardrock.com may amend or revise these terms and conditions at any time. By using this web site, you agree to be bound by these terms and conditions as in effect from time to time. Please review these terms and conditions regularly as these terms and conditions may change.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle}  style={{ fontWeight: 'bold', display: 'flex' }}>
                            OWNERSHIP OF MATERIALS
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            Except as otherwise indicated, this web site and its entire contents (collectively, the "Materials"), including, but not limited to, the text, information, material, software and graphics contained on this web site, are owned by&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            and its affiliates. The Materials are protected by copyright, trademark and other intellectual property laws and treaties.&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            makes no proprietary claim to any third party names, trademarks or service marks appearing on this web site. Any third party names, trademarks, and service marks are the properties of their respective owners. Except as provided in the next sentence, the Materials may not be copied, reproduced, modified, published, uploaded, downloaded, posted, transmitted, or distributed in any way, without&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            prior written permission. You may download one (1) copy of the Materials on a single computer only for your personal, non-commercial, internal use. You may not (i) modify the Materials or use them for any commercial purpose, or any other public display, performance, sale, or rental, (ii) decompile, reverse engineer, or disassemble software materials, (iii) remove any copyright notice or other proprietary notices from the Materials, or (iv) transfer the materials to another person. By posting a message or contributing in any way to this web site, including, but not limited to, sending comments and e-mail, to this web site, you expressly grant hardrock.com the right to use in any way, copy, republish or sell your message or other contribution as part of its property.&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            reserves the right to change, update, discontinue, or restrict or prevent access to this web site or the Materials at any time without notice.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle}  style={{ fontWeight: 'bold', display: 'flex' }}>
                            USER COMMENTS
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We welcome your comments, but please note that by uploading, submitting or otherwise disclosing or distributing content of any kind including but not limited to, comments, feedback, reviews, photographs, original art, notes, messages, ideas, suggestions, or other communications (collectively Comments) at or otherwise through the Website, you:
                        </Typography><br /><br />
                        <ol style={{ paddingLeft: '20px' }}>
                            <li style={{ listStyleType: "disc", paddingLeft: '10px' }} >
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    grant to&nbsp; 
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    the perpetual, irrevocable, non-exclusive, royalty-free right to use, reproduce, display, perform, adapt, modify, distribute, make derivative works of and otherwise exploit such Comments in any form and for any purpose, including without limitation, any concepts, ideas or know-how embodied therein without restriction and without compensating you in any way;
                                </Typography>
                            </li><br />
                            <li style={{ listStyleType: "disc", paddingLeft: '10px' }} >
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    represent and warrant to &nbsp; 
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    that you own or otherwise control all rights to such Comments and that disclosure and use of such Comments by&nbsp;
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    (including without limitation, publishing content at the Website) will not infringe or violate the rights of any third party; and acknowledge that the Comments may not be treated confidentially.
                                </Typography>
                            </li>
                        </ol><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            FOR THIS REASON, WE ASK THAT YOU NOT SEND US ANY COMMENTS THAT YOU DO NOT WISH TO ASSIGN TO&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            , INCLUDING ANY CONFIDENTIAL INFORMATION OR ANY ORIGINAL CREATIVE MATERIALS SUCH AS STORIES, PRODUCT IDEAS, COMPUTER CODE, PHOTOGRAPHS, OR ORIGINAL ARTWORK.
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            While we welcome such Comments from third parties but please note that &nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            is not responsible for screening, monitoring or verifying such content, including such content's accuracy, reliability or compliance with copyright or other laws. Any opinions, statements, or other materials made available by third parties through the Website as Comments are those of such third parties and not of&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            , and&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            does not endorse any such opinions, statements, or materials.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle}  style={{ fontWeight: 'bold', display: 'flex' }}>
                            USER CONTENT
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            By sharing photos, videos, information, text, messages, files, music, sounds, messages or other content or materials (“User Content”) on third party platforms and websites such as Instagram, Twitter, Facebook, Olapic (our third party photo sharing software provider) with a hash tag designated by&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            or directly uploading User Content to the Website (“Submissions”), you are agreeing to the following:
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            As between you and us, you own all User Content. You grant &nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            a license to reproduce, display, perform, distribute, and otherwise use your User Content in connection with the Website and for other&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            marketing purposes, including but not limited to marketing campaigns via any medium including but not limited to social media and print campaigns, customer communications, store materials and signage, social media, and any other form of marketing currently known and used or not yet known or developed.&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            may display advertisements in connection with your User Content or on pages where your User Content may be viewed by you or others, and we may use your User Content to advertise and promote &nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            or the Website. Our license to your User Content is non-exclusive, meaning you may use the User Content for your own purposes or let others use your User Content for their purposes. Our license to your User Content is fully-paid and royalty free, meaning we do not owe you anything else in connection with our use of your User Content. We may exercise our rights anywhere in the world. Finally, our license is perpetual, meaning that our license lasts for an indefinite period of time.
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            You promise that: You own all rights to your User Content or, alternatively, that you have the right to give&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            the rights described above; you have paid and will pay in full any fees or other payments that may be related to the use of your User Content; and your User Content does not infringe the intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party.
                        </Typography><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            may refuse to accept or transmit User Content for any reason, as well as remove User Content from the Website for any reason. You may only make a Submission if you are 18 years of age or over. If your Submission is selected by&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            to be used, it may be displayed for other users to see, together with your name and associated third party website profile information (such as your handle and profile picture).&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            is under no obligation to display, feature or use any Submission, but may do so at its sole discretion. You further irrevocably waive any "moral rights" or other rights with respect to attribution of authorship or integrity of materials regarding any Submission that you may have under any applicable law or under any legal theory.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>
                            DISCLAIMER AND LIMITATION ON LIABILITY
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            By using this web site, you assume all responsibility and risk for the use of this web site and the Internet generally. The materials are provided "as is," without any express or implied warranty of any kind, including, without limitation, warranties or merchantability, fitness for a particular purpose, noninfringement of intellectual property, and freedom from errors, viruses, bugs, or other harmful components. In no event shall&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            be liable for any damages whatsoever (including without limitation any special, indirect, or consequential damages, and damages resulting from loss of use, data, or profits, or business interruption) arising out of the use of inability to use the materials or any hyperlinked web site, even if&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            or its affiliates have been advised of the possibility of such damages. This web site is intended to assist readers by providing data, research and editorial information on the music industry.&nbsp;
                        </Typography>  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            will not be responsible for any errors or omissions in the Materials or information provided on this Web Site, any articles or postings for hyperlinks embedded in messages, nor for any loss or damage caused by your reliance on information obtained on this web site or in a hyperlinked site. This web site provides information and advice based on our editorial content and advice of industry professionals. While&nbsp;
                        </Typography>   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            believes such information and advice to be reliable, we make no claims or representations about the accuracy, reliability, timeliness, usefulness or completeness of such information or advice. However, by nature such information is broad in scope. Your situation is unique and our information and advice may not be appropriate for your situation. You should not rely on any information or advice you obtain on this web site. Any reliance by you on any information or advice will be at your own risk. This web site provides chat rooms, bulletin boards and other means for the public to contribute information and make statements.&nbsp;
                        </Typography>      
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            does not make any endorsements of these statements nor representations that the statements are true and accurate. You should be cautious when acting on information provided by contributors to these public areas. You should not rely upon the information and opinions of the chat rooms or bulletin boards.&nbsp;
                        </Typography>     
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            suggests you use the information on this web site as a starting point for doing independent research on artists, collectibles, and other information that is the subject of this web site.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>
                            LINKS TO THIRD PARTY SITES
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            This web site contains links to third party sites. These links are provided for convenience purposes and are not under the control of&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            . or its affiliates.&nbsp;
                        </Typography>                 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            does not make any representations and warranties, express or implied, regarding the content of any of these linked sites. These links do not constitute or imply an endorsement, authorization, sponsorship or affiliation by&nbsp;
                        </Typography>   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            with respect to any third party, any third party's web site, the content of any third party's web site, or any products or services provided by a third party.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>
                            GOVERNING LAW AND JURISDICTION
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            These terms and conditions of use shall be governed by and construed in accordance with the laws of Malaysia, without giving effect to any choice of law or conflict of law provision or rule (any other jurisdiction) that would cause the application of the laws of any jurisdiction other than Malaysia. By using this web site, you consent to the jurisdiction of the Malaysian courts and the Federal Courts of Malaysia, for any action or proceeding arising under or relating to these Terms of Use, this web site or the use of, or reliance on, this web site. Contact&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ color: 'blue', textDecorationLine: 'underline' }}>
                            blackbixoncafe@gmail.com
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            &nbsp;with questions or concerns.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>
                            COPYRIGHT POLICY
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            and its affiliates (collectively “
                        </Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>www.blackbixoncafe.com</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            ” or “we”) respects the intellectual property rights of others and expect users to do the same. In appropriate circumstances and at our sole discretion, we may remove or disable access to material on our Website that may be infringing or the subject of infringing activity. Copyright of the&nbsp;
                        </Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            portal and its contents but limited to the information, text, images, graphics, sound files, video files and their arrangement, and material therein, is owned by&nbsp;
                        </Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            unless otherwise indicated. No part or parts of this portal may be modified, copied, distributed, retransmitted, broadcasted, displayed, reproduced, published, licensed, transferred, sold or commercially dealt with any manner without the express prior written consent of the Malaysian Government.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>
                            COOKIES, WEB BEACONS AND SIMILAR TECHNOLOGIES
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We and others may use a variety of technologies to collect information about your device and use of our websites and mobile applications. These technologies include cookies, web beacons, java scripts, entity tags, and HTML local storage. Most web browsers can be programmed to accept or reject the use of some or all of these technologies, although you must take additional steps to disable or control other technologies. Contact&nbsp;
                        </Typography>   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex', color: 'blue', textDecorationLine: 'underline' }}>blackbixoncafe@gmail.com</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            &nbsp;with questions or concerns.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Cookies&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Cookies are small data files that are sent from a website’s server and are stored on your device’s hard drive either for only the duration of your visit ("session cookies") or for a fixed period ("persistent cookies"). Cookies contain information that can later be read by a web server.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Web Beacons&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Web beacons are small, transparent images that are embedded in web pages, applications, and emails that are sometimes called “clear gifs," “single pixel gifs", "page tags" or “web bugs." We use web beacons to track the web pages you visit, to test the effectiveness of our marketing, and to find out if an email has been opened and acted on.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Java Scripts&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Java scripts are code snippets embedded in various parts of websites and applications that facilitate a variety of operations including accelerating the refresh speed of certain functionality or monitoring usage of various online components.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Entity Tags&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            –Entity Tags are HTTP code mechanisms that allow portions of websites to be stored or “cached" within your browser and validates these caches when the website is opened, accelerating website performance since the web server does not need to send a full response if the content has not changed.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>HTML5 Local Storage&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – HTML5 local storage allows data from websites to be stored or “cached" within your browser to store and retrieve data in HTML5 pages when the website is revisited. These and similar technologies are used for the following purposes:
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Services and Functionality&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Some of these technologies are required to allow you to access and use our websites or mobile applications and the various services and functionality we offer. Without these technologies, some of the services and functionality on our websites and mobile applications would not work properly.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Performance Monitoring&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Some of these technologies help us analyze and estimate traffic and assess the volume of use of services and functionality on websites and mobile applications. They show us how visitors and customers interact with our digital properties, whether there are any errors, the degree to which certain web pages, applications, services or functionality are accessed and used and how they are performing or operating. When these technologies are used for performance monitoring, no information that identifies you is collected.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>User Convenience&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Some of these technologies enhance the ease of use of our websites and mobile applications and the services and functionality they make available by accelerating load and refresh times and remembering information that you have provided on prior visits to our websites or when you last used a website or mobile application service or functionality.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Marketing&nbsp;</Typography> 
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – Some of these technologies are used to tailor your experience on our website, within our mobile applications, or across your applications or devices by controlling the promotions, advertisements and other marketing messages that may appear when you access or use these or other digital properties. These technologies also help us learn which services and functionality you are using and how you are accessing information about us. We may use this information to personalize your visit to a website or use of a mobile application or to send you relevant promotions and advertisements.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>What Choices Does BLACKBIXON CAFE Offer About Personal Information?</Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            offers choices for you to request to access, update or change your personal information and how we communicate with you. However,&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            reserves the right to deny your request for access or to request for further validation prior to allowing you access. Here are some of the ways you can request changes:
                        </Typography><br /><br />
                        <ol style={{ paddingLeft: '20px' }}>
                            <li style={{ listStyleType: "disc", paddingLeft: '10px' }} >
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    Follow the opt-out instructions in promotional emails we send you or contact&nbsp; 
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    data privacy officer below.
                                </Typography>
                            </li><br />
                            <li style={{ listStyleType: "disc", paddingLeft: '10px' }} >
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    Contact&nbsp; 
                                </Typography>
                                <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex', color: 'blue', textDecorationLine: 'underline' }}>blackbixoncafe@gmail.com</Typography> 
                                <Typography variant="body1" component="p" className={classes.textStyle} >
                                    &nbsp;with questions or concerns.
                                </Typography>
                            </li>
                        </ol><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            If you opt out of receiving promotional communications from us, we may still send you non-promotional communications such as emails about your accounts or our ongoing business relations.
                        </Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            You can usually choose to set your browser to warn you when a cookie is being sent or to remove or reject cookies. Each browser is a little different, so look at your browser Help menu to learn the correct way to modify your cookie settings. If you choose to remove or reject cookies, it will affect many features or Services on our Sites.
                        </Typography><br /><br />    
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            will not retain your personal information one year after termination of your account or&nbsp;
                        </Typography>  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ PRIVILAGE VIP Card&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            and such information will be erased thereafter. Information of others that you provided will be erased upon the termination of the purpose for which you provided such information.
                        </Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>How is Personal Information Secured?</Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            takes reasonable steps to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, unauthorized access, disclosure or modification of personal information.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            While we take these reasonable efforts to safeguard your personal information and information of others that you provided, no system or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Privacy Policy Updates</Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We may update this privacy policy from time to time. When we do so, we will post the new privacy policy on this page and change the date at the top of the policy.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We encourage you to check the date of our privacy policy when you visit this Site for any updates or changes. We will notify you of any modified versions of the privacy policy that might materially affect the way we use or disclose your personal information or information of others that you provided.
                        </Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Questions and Feedback</Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We welcome your questions, comments and concerns about privacy.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            Customer Relations can be contacted online, at&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex', color: 'blue', textDecorationLine: 'underline' }}>blackbixoncafe@gmail.com</Typography><br /><br />    
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Does BLACKBIXON CAFE Personal Information or Information of Others that you provided with Third Parties?</Typography><br /><br />   
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON CAFÉ&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            shares your personal information or information of others that you provided in limited ways.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When We Work Together&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may share information between and among&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>BLACKBIXON</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            , its subsidiaries, and affiliated companies for purposes of management and analysis, decision making, and other business purposes. For example, we may share your information with our subsidiaries and affiliated companies to administer our loyalty programs, process orders and requests, and expand and promote our product and service offerings.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When We Work with Service Providers&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may share your information with service providers that provide us with support services, such as credit card processing, website hosting, email and postal delivery, location mapping, product and service delivery, analytics services, or conducting academic research.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When We Work on Business Transactions&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – If we become involved with a merger or another situation involving the transfer of some or all of our business assets, we may share your information with business entities or people involved in the negotiation or transfer.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When Sharing Helps Us Protect Lawful Interests&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may disclose your information if we believe that the disclosure is required by law, if we believe that the disclosure is necessary to enforce our agreements or policies, or if we believe that the disclosure will help us protect the rights, property, or safety of Starbucks or our customers or partners.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When We Work with Marketing Service Providers&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may share your information with marketing service providers to assess, develop and provide you with promotions and special offers that may interest you, administer contests, sweepstakes and events or for other promotional purposes.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When You Give Consent&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may share information about you with other companies if you give us permission or direct us to share the information.
                        </Typography><br /><br />  
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When the Information Does Not Identify You&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We may share your information in a way that does not directly identify you. For example, we may share information about your use of our websites and mobile applications in a manner that does not identify you or may combine information about the nature or frequency of your transactions with similar information about other people and share the aggregated information for statistical analysis and other business purposes.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>When You Post on Our Websites&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – If you post information on a blog or another part of our websites, the information that you post may be seen by other visitors to our websites, including your user name.
                        </Typography><br /><br /><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>Does BLACKBIXON CAFE Allow Others to Collect Information you or Information of Others that you provided?</Typography><br />
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            When you use our websites or mobile applications, we may allow third parties to collect device and usage information and location information across your different devices through mobile software development kits,&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ textDecorationLine: 'underline' }}>
                            cookies, web beacons and other similar technologies
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            . These third parties collect this information for the following purposes and for other purposes consistent with their own privacy policies:
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>To Display Ads for Products or Services&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We allow some advertising companies to collect this information in order to display ads that are most relevant to you across your different devices and on our own and others’ websites and mobile apps.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>To Understand the Use of Our Services&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We allow certain service providers to collect this information to help us understand how our websites and mobile applications are used and to assist us in improving the content and operation of our online properties. These service providers collect aggregate statistical usage data that is not matched or linked to any individual user.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>To Make the Services of Other Companies Work on Our Websites&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            – We allow companies to use&nbsp;
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ textDecorationLine: 'underline' }}>
                            cookies, web beacons and other similar technologies
                        </Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            &nbsp;to enhance the operations of particular services and functionality of our websites and mobile applications. For example, we allow Adobe to set and enable special cookies that are necessary to deliver video content for Adobe Flash Player. These cookies are called Flash cookies.
                        </Typography><br /><br /> 
                        <Typography variant="body1" component="p" className={classes.textStyle} style={{ fontWeight: 'bold', display: 'flex' }}>To Link Your Activity on Our Websites to Your Social Networks&nbsp;</Typography>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            We have added certain features to our websites and mobile applications that allow social networks (such as Facebook, Twitter, and Google+) to track the activities of their members or collect certain information when they use our website or mobile applications. These features may include technologies called "plug-ins" and "widgets." Plug-ins create a direct link between two websites, and widgets are interactive mini-programs that provide other companies’ services on our websites. If you are concerned about the way a social network is tracking your activity, please contact the social network or review its privacy policy. For example, you can review Facebook’s data-use policy at&nbsp;
                        </Typography>
                        <Link rel="noopener noreferrer" href="https://www.facebook.com/about/privacy/" target="_blank">
                            <Typography variant="body1" component="p" className={classes.textStyle} style={{ textDecorationLine: 'underline' }}>
                                https://www.facebook.com/about/privacy/
                            </Typography>
                        </Link>
                        <Typography variant="body1" component="p" className={classes.textStyle} >
                            .
                        </Typography>
                    </Container>
                </Box>
            </div>
        </Box>
    )
}

export default TncInfo;

const useStyles = makeStyles((theme) => ({
    textStyle: {
        textAlign: 'left !important',
        display: 'inline !important',
        // fontFamily:'NuezeitGroteskLight !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.0rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    titleStyle: {
        // fontFamily:'OregonLdoBold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '3.2rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '3.5rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '4rem !important',
        },
    },
    containerStyle: {
        flex: 1,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
    },
}))