import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Container, Card, CardContent, Avatar, Typography, TextField, Button, CircularProgress, Grid} from '@mui/material';
import { PersonAddRounded } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { postUrl } from '@helpers/api';

const INITIAL_STATE = {
    name: '',
    email: '',
    mobile: '',
    ic: '',
    password: '',
    password_confirmation: '',
};

const SignUp = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [registerField, setRegisterField] = useState(INITIAL_STATE);
    const [loading, setLoading] = useState(false);

    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const navigate = useNavigate();

    const handleChange = (event) => {
        setRegisterField({ ...registerField, [event.target.name]: event.target.value });
    };

    const registerBtnClicked = () => {
        setLoading(true);
            postUrl('register', registerField).then(response => {
                setLoading(false);
                let message = response.message;
                if(!response.status) {
                    setInputErrors(response.errors);
                } else {
                    setInputErrors({});
                    setRegisterField(INITIAL_STATE);
                    setTimeout(() => {
                        navigate(`/sign-in`, { replace: true });
                    }, 3000);
                }

                if(message) {
                    addToast(message, { appearance: response.status ? 'success' : 'error' });
                }
                
            }).catch(err => {
                setLoading(false);
                addToast(JSON.stringify(err), { appearance: 'error' });
            })
    }
    
    return (
        <Container component="main" maxWidth="md">
            <Card className={classes.root} variant="elevation" elevation={3}>
                <CardContent className={classes.cardContentRoot}>
                    <div className="paper-container">
                        <Avatar className={classes.avatar}>
                            <PersonAddRounded />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            { t('auth.signUp') }
                        </Typography>
                        <div className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('user.name')}
                                    name="name"
                                    autoComplete="name"
                                    autoFocus
                                    value={registerField.name}
                                    onChange={handleChange}
                                    error={inputErrors?.name ? true : false }
                                    helperText={inputErrors?.name || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('user.email')}
                                    name="email"
                                    autoComplete="email"
                                    // autoFocus
                                    value={registerField.email}
                                    onChange={handleChange}
                                    error={inputErrors?.email ? true : false }
                                    helperText={inputErrors?.email || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('user.mobile')}
                                    name="mobile"
                                    autoComplete="tel-national"
                                    // autoFocus
                                    value={registerField.mobile}
                                    onChange={handleChange}
                                    error={inputErrors?.mobile ? true : false }
                                    helperText={inputErrors?.mobile || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label={t('user.ic')}
                                    name="ic"
                                    autoComplete="ic"
                                    // autoFocus
                                    value={registerField.ic}
                                    onChange={handleChange}
                                    error={inputErrors?.ic ? true : false }
                                    helperText={inputErrors?.ic || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    type="password"
                                    fullWidth
                                    label={t('auth.password')}
                                    name="password"
                                    autoComplete="new-password"
                                    // autoFocus
                                    value={registerField.password}
                                    onChange={handleChange}
                                    error={inputErrors?.password ? true : false }
                                    helperText={inputErrors?.password || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    type="password"
                                    fullWidth
                                    label={t('auth.password_confirmation')}
                                    name="password_confirmation"
                                    autoComplete="new-password"
                                    // autoFocus
                                    value={registerField.password_confirmation}
                                    onChange={handleChange}
                                    error={inputErrors?.password_confirmation ? true : false }
                                    helperText={inputErrors?.password_confirmation || ''}
                                    // InputLabelProps={{
                                    //     style: { fontFamily: 'OregonLdoDemibold' }
                                    // }}
                                />
                            </Grid>
                            {/* <FormControlLabel
                                control={<Checkbox value="remember" color="primary" />}
                                label={ t('auth.rememberMe') }
                            /> */}
                            <Grid item xs={12} md={12} display="flex" justifyContent="flex-end">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className={classes.submit}
                                    onClick={ registerBtnClicked }
                                    disabled={ loading ? true : false }
                                    // style={{ fontFamily:'OregonLdoRegular'}}
                                >
                                    {
                                        loading ? 
                                        <CircularProgress size={20} />
                                        : t('auth.signUp')
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </Container>
    )
}

const useStyles = makeStyles((theme) => ({
    cardContentRoot: {
        padding: theme.spacing(4)
    },
    paper: {
        // marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(4, 0, 4),
    },
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    }
}));

export default SignUp