export const ENV = "liv";
export const APP_NAME = "BLACKBIXON CAFE";
export const DEFAULT_LANGUAGE = "en";

const BACKEND_URL_LIST = {
    liv: `https://api.blackbixoncafe.com`,
    stage: `https://cafeapi.mib88.com`,
    // dev: `https://cafeapi.mib88.com`,
    dev: 'http://127.0.0.1:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://www.blackbixoncafe.com`,
    stage: `https://cafefrontend.mib88.com`,
    dev: `http://localhost:3000`,
};

export const GOOGLE_MAP_TOKEN = "AIzaSyC4O0WLAUgQN8KTSX18fzmaOfpO8XL4x4g";

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];
export const API_URL = `${BACKEND_URL}/api/`;

export const TIMEOUT = 8000;
