const chinese = {
    header: {
        language: "语言",
    },
    footer:{
        menu:"菜单",
        reservation:"预订",
        career:"职业",
        followUs:"跟进我们",
        tnc:"网站可访问性声明",
        policy:"取消和退款政策",
        faq:'FAQ',
    },
    slider:{
        sliderText1:"马来西亚第一家旗舰 Blackbixon 咖啡厅",
        sliderText2:"吃了一口，定让你回头。",
        sliderText3:"很棒的咖啡、食物和良好的氛围",
    },
    auth: {
        signIn: "登入",
        signOut: "登出",
        signUp: "注册",
        username: "用户名",
        email: "电子邮箱",
        name: "名字",
        ref: "推荐人",
        password: "密码",
        password_confirmation: "确认密码",
        securityPassword: "安全密码",
        confirmSecurityPassword: "确认安全密码",
        rememberMe: "持续登入",
        forgotPassword: "忘记密码",
        noAccountSignUp: "没有账号？注册",
        required: {
            email: "请输入邮件",
            password: "请输入密码",
        }
    },
    forgotPassword: {
        placeholder: "请输入您的电子邮箱",
        email: "电子邮箱",
        required: {
            email: "请输入您的电子邮箱"
        },
        sendSuccess: "我们已将您的重置密码链接发送给您，请检查您的收件箱并在60分钟内重置您的密码。"
    },
    resetPassword: {
        email: "电子邮箱",
        password: "新密码",
        password_confirmation: "确认密码",
        required: {
            password: "请输入您的新密码",
            password_confirmation: "请输入您的确认密码",
        },
        errors: {
            notSame: "您的密码和确认密码不匹配"
        },
        loginNow: "现在登入",
        notValid: "您的重置密码链接不符合我们的服务器凭据或已过期，请尝试再次请求重置密码链接。",
        resetSuccess: "您的密码已成功更改，您现在可以使用新密码继续登录。"
    },
    title: {
        home: "主页",
        profile: "我的账户",
        forgotPassword: "忘记密码",
        resetPassword: "重设密码",
        ourStory:"我们的故事",
        locateUs:"寻找我们",
        coffeePackage:"咖啡包装",
        contactUs:"联系我们",
        menu: "菜单",
        reservation: "预订",
        gallery: "画廊",
        latestNews: "最新消息",
        career:"职业",
        cart:"购物车",
        faq:"Blackbixon 咖啡厅 常问问题",
        tnc: "网站可访问性声明",
        policy: "取消和退款政策",
        order:"Order",
        queue: "队列",
        faqShort: "FAQ",
        cafeTitle: "BLACKBIXON CAFÉ & RESTAURANT",
    },
    user: {
        profile: "账户资料",
        username: "用户名",
        name: "姓名",
        email: "电子邮箱",
        mobile: "电话号码",
        ic: "身份证号码",
        password: "密码",
        security_password: "安全密码",
        old: "当前{{field}}",
        new: "新{{field}}",
        confirm: "重复新{{field}}",
        addresses: "地址"
    },
    address: {
        name: "姓名",
        address1: "地址 1",
        address2: "地址 2",
        zip: "邮政编码",
        state: "州",
        city: "城市",
        country: "国家",
        unit: "楼层/单位",
        street: "街道"
    },
    general: {
        goHome: "返回主页",
        notFound: "404 - 网页不存在！",
        submit: "提交",
        update: "更新",
        error: "过程出现错误。",
        copiedToClipboard: "复制到剪贴板",
        pleaseSelect: "请选择",
        purchase:"购买",
        seeMenu:"查看菜单",
        orderNowThroughDeliveries:"马上外卖订购",
        itemNotFound: "暂无商品",
        edit: "编辑",
        cancel: "取消",
        create: "新增",
        delete: "删除",
        applyHere:"立即申请",
        seeOurMenu:"查看我们的菜单",
        orderDelivery:"订单交付",
        visitUs:"访问我们",
        ourMenu:"我们的菜单",
        orderNow:"马上订购",
        dineIn:"预定用餐",
        delivery:"订购外卖",
        viewMore:"查看更多",
        viewLess:"查看更少",
        readMore:"阅读更多",
        readLess:"阅读更少",
        addToCart:"加入购物车",
        updateCart:"更新购物车",
        checkout:"结帐单",
        deleteDescription:"您确定要删除它吗？",
        confirm:"确定",
        all:"全部",
        subscribe:"订阅",
        reservation:"预订",
    },
    error:{
        noCareer:"暂无相关职业。",
        optionMax:"此选项已达到最大数量。",
        noCart:"请先至商品页面将喜欢的商品加入购物车哟~",
        maxDistance:"很抱歉，暂时只限2KM内送货",
        invalidTime:"无法在所选的时间下单。"
    },
    profile: {
        changeProfile: "更新账号",
        changePassword: "更新密码",
        changeSecurityPassword: "更新安全密码",
    },
    transaction: {
        receivedFrom: "接收自",
        transferTo: "转移至",
        transactionDate: "交易日期",
        transactionDetail: "交易细节",
        amount: "金额",
        balance: "余额",
        remark: "备注",
    },
    datatable: {
        noMatch: "对不起，暂无记录",
        next: "下一页",
        previous: "上一页",
        rowsPerPage: "每页行数",
        displayRows: "的",
    },
    home: {
        walletWeeklyBalance: "您上周的{{wallet}}余额"
    },
    contactUs:{
        subtitle:"查询和/或反馈",
        fullName:"全名",
        contactNumber:"电话",
        email:"电子邮件",
        title:"你想要咨询关于什么呢？",
        detailTitle:"留言",
        detail:"请在此留言",
        address:"地址",
        googleMap:"谷歌地图",
        waze:"位智"
    },
    coffeePackage:{
        promotionPackage:"促销配套",
        starterPackage:"入门配套",
        outrightPackage:"直接配套",
        coffeeCapsules:"咖啡胶囊"
    },
    product:{
        bbSignature:"BB 招牌",
        mustTry:"必试",
        vegetarian:"素食主义者",
        basicPrice:"基本价格",
        remark:"备注",
        remarkText:"添加您的要求",
        quantity:"数量",
        totalPrice:"总价",
        max: "最大限度是 {{value}}",
        outOfStock:"缺货"
    },
    payment: {
        success: "Payment Successful",
        pending: "Payment Pending",
        fail: "Payment Failed",
        successContent: "Your order has been added to the list~",
        pendingContent: "The system will add the order to the list as soon as the payment is completed.",
        failContent: "There was a problem with the payment, please contact customer service for more details.",
    },
    queue:{
        name:"姓名",
        position:"位置",
        size:"人数",
        email:"电子邮件",
        mobile:"手机号码",
        cancel:"撤消队列",
        refresh:"刷新状态",
        submit:"提交",    
        yes:"是的",
        no:"取消",        
        cancel_queue:"你确定要取消队列吗?",
        called:"轮到你了！请在 15 分钟内抵达我们的餐厅，否则您将需要重新排队!",
    }
};

export default chinese;