// import { lazy } from 'react';

// AUTH
import SignIn from '@authpages/SignIn';
import SignUp from '@authpages/SignUp';
import ForgotPassword from '@authpages/ForgotPassword';
import ResetPassword from '@authpages/ResetPassword';

// GUEST
import Home from '@guestpages/Home';
import LocateUs from '@guestpages/LocateUs';
import OurStory from '@guestpages/OurStory';
import Menu from '@guestpages/Menu';
import Reservation from '@guestpages/Reservation';
import Career from '@guestpages/Career';
import CoffeePackage from '@guestpages/CoffeePackage';
import ContactUs from '@guestpages/ContactUs';
import Gallery from '@guestpages/Gallery';
import LatestNews from '@guestpages/LatestNews';
import Cart from '@guestpages/Cart';
import Checkout from '@guestpages/Checkout';
import Faq from '@guestpages/Faq';
import Tnc from '@guestpages/Tnc';
import Policy from '@guestpages/Policy';
import Queue from '@guestpages/Queue';

// USER
import Profile from '@userpages/Profile';
import PaymentCallback from '@userpages/PaymentCallback';
import Order from '@userpages/Order';
import OrderDetail from '@userpages/OrderDetail';
// consider using lazy to improve first time loading performance

// AUTH
// const SignIn = lazy(() => import('@authpages/SignIn'));
// const SignUp = lazy(() => import('@authpages/SignUp'));
// const ForgotPassword = lazy(() => import('@authpages/forgot-password'));
// const ResetPassword = lazy(() => import('@authpages/reset-password'));

// GUEST

// USER
// const Home = lazy(() => import('@guestpages/Home'));
// const Transaction = lazy(() => import('@userpages/transactions'));
// const Transfer = lazy(() => import('@userpages/transfer'));
// const TransferResult = lazy(() => import('@userpages/transfer/result'));
// const Profile = lazy(() => import('@userpages/Profile'));
// const Password = lazy(() => import('@userpages/profile/password'));

// ONLY CAN ACCESS AFTER LOGIN
export const private_routes = [
    {
        path: "/profile",
        component: <Profile />
    },
    // {
    //     path: "/paymentresult/:payment_id",
    //     component: <PaymentCallback />
    // },
    // {
    //     path: "/order",
    //     component: <Order />
    // },
    // {
    //     path: "/order-detail/:order_no",
    //     component: <OrderDetail />
    // }
];

// CAN ACCESS BEFORE OR AFTER LOGIN
export const public_routes = [
    {
        path: "/",
        exact: true,
        component: <Home />
    },
    {
        path: "/locate-us",
        component: <LocateUs />
    },
    {
        path: "/our-story",
        component: <OurStory />
    },
    {
        path: "/menu/:slug/:subSlug",
        component: <Menu />
    },
    {
        path: "/menu/:slug",
        component: <Menu />
    },
    {
        path: "/menu",
        component: <Menu />
    },
    {
        path: "/reservation",
        component: <Reservation />
    },
    {
        path: "/queue",
        component: <Queue />
    },
    {
        path: "/career",
        component: <Career />
    },
    // {
    //     path: "/coffee-package",
    //     component: <CoffeePackage />
    // },
    {
        path: "/contact-us",
        component: <ContactUs />
    },
    // {
    //     path: "/gallery",
    //     component: <Gallery />
    // },
    // {
    //     path: "/latest-news",
    //     component: <LatestNews/>
    // },
    // {
    //     path:"/cart",
    //     component:<Cart />
    // },
    // {
    //     path:"/checkout",
    //     component:<Checkout />
    // },
    {
        path: "/faq",
        component: <Faq />
    },
    {
        path: "/tnc",
        component: <Tnc />
    },
    {
        path: "/policy",
        component: <Policy />
    },
];

// ONLY CAN ACCESS BEFORE LOGIN
export const public_restricted_routes = [
    {
        path: "/sign-in",
        exact: true,
        component: <SignIn />
    },
    {
        path: "/sign-up",
        component: <SignUp />
    },
    {
        path: "/forgot-password",
        component: <ForgotPassword />
    },
    {
        path: "/reset-password",
        component: <ResetPassword />
    }
];