import React from 'react';
import { Box, Container , Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import HomeCarousel from '../Home/HomeCarousel';
import HomeLocateUs from '../Home/HomeLocateUs';

const TimelineStory = () => {
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Box>
            <HomeCarousel data={[]}/>
            <Container className="app-container" maxWidth="lg">
                <Box style={{ margin: 30 }}>
                    <p className={classes.textStyle}>
                        It goes back all the way to where we first created the FIRST energy and nutritional specialty coffee as our main focus.
                    </p>
                    <p className={classes.textStyle}>
                        From sourcing to researching, experimenting and finally developing a world-class coffee experience just for you. We go to great lengths to create the best for you.
                    </p>
                    <p className={classes.textStyle}>
                        But we wanted more for you...We want to create beyond just coffee.
                    </p>
                </Box>
                <Box style={{ margin: 30 }}>
                    <Typography variant="h4" className={classes.titleStyle} style={{ color: theme.palette.primary.secondary }}>
                        OUR STORY
                    </Typography>
                    <img alt="milestones" src={`../images/our-story/milestones.png`} width="100%" />
                </Box>
                <Box style={{ margin: 30 }}>
                    <Typography variant="h4" className={classes.titleStyle}>
                        BlackBixon Café & Restaurant
                    </Typography>
                    <p className={classes.textStyle}>
                        We aim to create cherished memories during leisurely, comforting meals for you and your loved ones. We aspire to facilitate successful business dealings through a delightful gastronomical experience. We endeavor to reconnect you with long-lost childhood friends. In essence, we are dedicated to crafting a unique experience just for you.
                    </p>
                    <p className={classes.textStyle}>
                        And so, we have crafted this for you the BlackBixon Café & Restaurant, our FIRST flagship store nestled in the heart of KL City Center.
                    </p>
                </Box>
                <Box style={{ margin: "50px 0px" }}>
                    <img alt="milestones" src={`../images/our-story/shop.jpg`} width="100%" />
                </Box>
                <Box style={{ margin: 30 }}>
                    <p className={classes.textStyle}>
                        At BlackBixon Café & Restaurant, we've designed a welcoming and ambient space that fostersconnections, relaxation, and rejuvenation. This is the enchantment that BlackBixon Café can weave. Our scrumptious pastries are freshly baked every morning, our signature BlackBixon Burgers are infused with our specialty sauce, and our delectable pasta dishes and food choices will make you feel good about what you're eating.
                    </p>
                    <p className={classes.textStyle}>
                        What are you waiting for? We are open for dine-in, takeout, and delivery services.
                    </p>
                </Box>
            </Container>
            <HomeLocateUs data={[]} />
        </Box>
    )
}

export default TimelineStory

const useStyles = makeStyles((theme) => ({
    textStyle: {
        marginBottom: 20,
        textAlign: "center",
        [theme.breakpoints.up('xs')]: {
            fontSize: '.9rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem !important',
        },
    },
    titleStyle: {
        marginTop: '80px !important', 
        marginBottom: '20px !important',
        textAlign: "center",
        fontFamily:'OregonLdo !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '1.8rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem !important',
        },
    },
}))