import React, { memo , useEffect , useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import _ from 'lodash';
import { getUrl } from '@helpers/api';

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const HomeCarousel = memo(({data}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [detail , setDetail] = useState({});

    useEffect(() => {
        if(_.size(data) === 0){
            getUrl(`/settings/cms/layout`, {"code": "home-page"}).then(result => {
                let temp_list = _.find(result.data.value.data , {'section':'carousel'});
                setDetail(temp_list);
            }).catch((error) => {
                // addToast('', error.message || t('error.contactSupport'), 'error', '');
            })
        }else{
            setDetail(data)
        }
        // eslint-disable-next-line
    }, [data])

    return (
        <Box>
            <Swiper
                rewind={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: true,
                }}
                autoHeight={true}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation, Autoplay]}
                className="carousel-swiper"
            >
                {/* {_.size(detail) > 0 && _.size(detail?.images) > 0 && _.size(detail?.images[0].image) ?
                    _.map(detail.images, (image, index) => {
                        if(!_.size(image.image)) return false;
                       return <SwiperSlide key={index}>
                            <div className="slide-img">
                                <img alt="Black Bixon Cafe" src={image.image} />
                                <div className="slide-label">
                                    <Typography style={{ fontSize: 40, fontWeight: 'bold', color: "#FFF"}}><span dangerouslySetInnerHTML={{ __html: image.text }} /></Typography>
                                </div>
                            </div>
                        </SwiperSlide>
                    })
                : */}
                <>
                <SwiperSlide>
                    <div className="slide-img">
                        {/* image src:File_007.jpeg */}
                        <img alt="Black Bixon Cafe" src='../images/home/home-slides/banner1.png' loading="lazy" />
                        <div className="slide-label">
                            <Typography style={{ fontSize: 40, fontWeight: 'bold', color: "#FFF"}}>BlackBixon Café & Restaurant</Typography>
                            {/* <Link component={RouterLink} to="/menu" underline="none">
                                <Button variant="contained" className={classes.roundedButtonStyle}>
                                    <Typography className={classes.buttonTextStyle}>{t('general.visitUs')}</Typography>
                                </Button>
                            </Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-img">
                        <img alt="Black Bixon Cafe" src='../images/home/home-slides/banner2.png'  loading="lazy" />
                        <div className="slide-label">
                            <Typography style={{ fontSize: 40, fontWeight: 'bold', color: "#FFF" }}>Once You Bite , You'll Be Back</Typography>
                            {/* <Link component={RouterLink} to="/menu" underline="none">
                                <Button variant="contained" className={classes.roundedButtonStyle}>
                                    <Typography className={classes.buttonTextStyle}>{t('general.orderDelivery')}</Typography>
                                </Button>
                            </Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-img">
                        <img alt="Black Bixon Cafe" src='../images/home/home-slides/banner3.png' loading="lazy" />
                        <div className="slide-label">
                            <Typography style={{ fontSize: 40, fontWeight: 'bold', color: "#FFF" }}>Nothing Brings people together like Good Food</Typography>
                            {/* <Link component={RouterLink} to="/menu" underline="none">
                                <Button variant="contained" className={classes.roundedButtonStyle}>
                                    <Typography className={classes.buttonTextStyle}>{t('general.seeOurMenu')}</Typography>
                                </Button>
                            </Link> */}
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="slide-img">
                        <img alt="Black Bixon Cafe" src='../images/home/home-slides/banner4.png' loading="lazy" />
                        <div className="slide-label">
                            <Typography style={{ fontSize: 40, fontWeight: 'bold', color: "#FFF" }}>First Giant Croissant in Malaysia</Typography>
                        </div>
                    </div>
                </SwiperSlide>
                </>
                {/* } */}
            </Swiper>
        </Box>
    )
});

export default HomeCarousel

const useStyles = makeStyles((theme) => ({
    roundedButtonStyle: {
        borderRadius: '20px !important',
        backgroundColor: '#ffffff !important',
        color: '#000000 !important',
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        '&:hover': {
            backgroundColor: '#5E4536 !important',
            color: '#ffffff !important'
        }
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        paddingLeft: '5px !important',
        paddingRight: '5px !important',
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
}))