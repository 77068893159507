import { getUrl } from '@helpers/api';
import { Avatar, Box, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ImQuotesLeft } from 'react-icons/im';
import { useToasts } from 'react-toast-notifications';

import _ from 'lodash';
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";

const HomeReview = memo(() => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addToast } = useToasts();
    const [listing, setListing] = useState([]);

    const images = [
        {
            media_url: "../images/home/home-insta/igimg-1.jpg",
            permalink: "https://www.instagram.com/p/C03I0kspn1x/"
        },
        {
            media_url: "../images/home/home-insta/igimg-2.jpg",
            permalink: "https://www.instagram.com/p/C005e3Mpwgh/"
        },
        {
            media_url: "../images/home/home-insta/igimg-3.jpg",
            permalink: "https://www.instagram.com/p/C0vrcMWpTQd/"
        },
        {
            media_url: "../images/home/home-insta/igimg-4.jpg",
            permalink: "https://www.instagram.com/p/C0yvg7GJELy"
        },
        {
            media_url: "../images/home/home-insta/igimg-5.jpg",
            permalink: "https://www.instagram.com/p/C0sb_yapQKf/"
        },
        {
            media_url: "../images/home/home-insta/igimg-6.jpg",
            permalink: "https://www.instagram.com/p/C0llN9mpo3p/"
        },
    ];

    useEffect(() => {
        getApi();
        // eslint-disable-next-line
    }, []);

    const getApi = () => {
        getUrl(`/settings/insta-photos`).then(result => {
            if (result.status && _.size(result.data)) {
                setListing(result.data)
            }else{
                setListing(images);
            }
        }).catch((error) => {
            addToast('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    return (
        <Box display="flex" flexDirection="column" alignItems="center" paddingTop={6}>
            {/* <Typography variant="h6" className={classes.titleStyle}>Menu</Typography> */}
            <Link rel="noopener noreferrer" href="https://www.instagram.com/blackbixoncafe/" target="_blank" underline="none">
                <Typography variant="h5">Follow Us On Instagram{/* What Cafe-hoppers Say */}</Typography>
            </Link>
            <Box width="100%">
                <Swiper 
                    rewind={true}
                    autoHeight={true}
                    pagination={true} 
                    modules={[Pagination, Autoplay]} 
                    className="review-swiper"
                    slidesPerView={3}
                    spaceBetween={30}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: true,
                    }}
                >
                    {
                        _.map(listing, (image, index) => (
                            <SwiperSlide key={index}>
                                <Link rel="noopener noreferrer" href={image.permalink} target="_blank" underline="none">
                                    <img className={classes.imageStyle} alt={`Black Bixon Photo ${index}`} src={image.media_url} />
                                </Link>
                            </SwiperSlide>
                        ))
                    }
                    {/* <SwiperSlide>
                        <ReviewSlide />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ReviewSlide />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ReviewSlide />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ReviewSlide />
                    </SwiperSlide>
                    <SwiperSlide>
                        <ReviewSlide />
                    </SwiperSlide> */}
                </Swiper>
            </Box>
        </Box>
    )
});

export default HomeReview;

const ReviewSlide = ({data}) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" paddingTop={2}>
            <Box flex={1} padding={1}>
                <ReviewQuote />
            </Box>
            <Box flex={1} padding={1}>
                <img className={classes.imageStyle} alt="Black Bixon Review 1 a" src='../images/home/home-reviews/review1a.png' />
            </Box>
            <Box flex={1} padding={1}>
                <img className={classes.imageStyle} alt="Black Bixon Review 1 b" src='../images/home/home-reviews/review1b.png' />
            </Box>
        </Box>
    )
}

const ReviewQuote = () => {
    const classes = useStyles();

    return (
        <>
            <ImQuotesLeft />
            <Typography paragraph className={classes.reviewQuoteStyle}>
                "Lorem ipsum dolor lamet, consectetur adipiscing elit, sed do eiusmo tempor inci didunt ut labore et dolo."
            </Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
                <Avatar 
                    alt="Remy Sharp" 
                    src="https://mui.com/static/images/avatar/1.jpg" 
                    sx={{ width: "5vw", height: "5vw" }}
                />
                <div style={{ padding: 5}}>
                    <Typography className={classes.reviewerStyle}>@Lucyminaaas</Typography>
                    <Typography className={classes.dateReviewStyle}>Sep 22</Typography>
                </div>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        fontFamily:'OregonLdo !important',
    },
    imageStyle: {
        width: '100%'
    },
    reviewQuoteStyle: {
        fontWeight: 'bold',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.55rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
    reviewerStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.4rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.7rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem !important',
        },
    },
    dateReviewStyle: {
        [theme.breakpoints.up('xs')]: {
            fontSize: '.3rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.6rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '.8rem !important',
        },
    }
}))