import React, { memo } from 'react'
import { Link as RouterLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Box, Button, Typography, Link, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash';

const HomeMenu = memo(({data}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Box display="flex" flexDirection="column" alignItems="center" paddingTop={4}>
                <Typography variant="h6" className={classes.titleStyle}>Menu</Typography>
                {/* <Typography variant="h5" >Coffee & Dishes Our Customers Love</Typography> */}
                {/* {(_.size(data) > 0 && _.size(data.menus) > 0 && _.size(data.menus[0].image)) ?
                    <Grid container direction="row" justifyContent="center">
                        {_.map(data.menus, (image, index) => {
                                if(!_.size(image.image)) return false;
                                return <Grid item xs={4} key={index} className={classes.imageContainerStyle} style={{ marginTop: ([0,2].includes(index) ? 20 : 0) }}>
                                <Link component={RouterLink} to={image.link? image.link:"/"} underline="none">
                                    <img alt="Black Bixon Menu" className={classes.imageStyle} src={image.image} />
                                    <Typography variant="body1" className={classes.subTitleStyle} dangerouslySetInnerHTML={{ __html: image.title }}></Typography>
                                    <Typography variant="caption" paragraph className={classes.captionStyle} dangerouslySetInnerHTML={{ __html: image.text }}></Typography>
                                </Link>
                            </Grid>
                        })}
                    </Grid>
                :  */}
                <>
                <Box display="flex" flexDirection="row">
                    <div className={classes.imageContainerStyle} style={{ marginTop: 20 }}>
                        <Link component={RouterLink} to="/menu/bread-pastries" underline="none">
                            <img alt="Black Bixon Menu" className={classes.imageStyle} src="../images/home/home-menus/menu1.png" />
                            <Typography variant="body1" className={classes.subTitleStyle}>Breads & Pastries</Typography>
                            <Typography variant="caption" paragraph className={classes.captionStyle}>Freshly baked Croissant & Danish pastry for your daily loaf of carbs.</Typography>
                        </Link>
                    </div>
                    <div className={classes.imageContainerStyle}>
                        <Link component={RouterLink} to="/menu/breakfast-brunch" underline="none">
                            <img alt="Black Bixon Menu" className={classes.imageStyle} src="../images/home/home-menus/menu2.png" />
                            <Typography variant="body1" className={classes.subTitleStyle}>Breakfast & Brunch</Typography>
                            <Typography variant="caption" paragraph className={classes.captionStyle}>Available from 8am to 3pm.</Typography>
                        </Link>
                    </div>
                    <div className={classes.imageContainerStyle} style={{ marginTop: 20 }}>
                        <Link component={RouterLink} to="/menu/blackbixon-burger" underline="none">
                            <img alt="Black Bixon Menu" className={classes.imageStyle} src="../images/home/home-menus/menu3.png" />
                            <Typography variant="body1" className={classes.subTitleStyle}>BlackBixon Burger</Typography>
                            <Typography variant="caption" paragraph className={classes.captionStyle}>Our signature burger. Once go black, no turning back.</Typography>
                        </Link>
                    </div>
                </Box>
                <Box display="flex" flexDirection="row">
                    <div className={classes.imageContainerStyle}>
                        <Link component={RouterLink} to="/menu/pasta" underline="none">
                            <img alt="Black Bixon Menu" className={classes.imageStyle} src="../images/home/home-menus/menu4.png" />
                            <Typography variant="body1" className={classes.subTitleStyle}>À La Carte</Typography>
                            <Typography variant="caption" paragraph className={classes.captionStyle}>Available from 11am onwards for pasta, schnitzel, mains, dinner and more.</Typography>
                        </Link>
                    </div>
                    <div className={classes.imageContainerStyle}>
                        <Link component={RouterLink} to="/menu/beverages" underline="none">
                            <img alt="Black Bixon Menu" className={classes.imageStyle} src="../images/home/home-menus/menu5.png" />
                            <Typography variant="body1" className={classes.subTitleStyle}>Beverages</Typography>
                            <Typography variant="caption" paragraph className={classes.captionStyle}>For a heavenly wake-me-up and hearty sip.</Typography>
                        </Link>
                    </div>
                </Box>
                </>
                {/* } */}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="center" paddingTop={4}>
                <Link component={RouterLink} to="/menu" underline="none">
                    <Button variant="contained" className={classes.roundedButtonStyle}>
                        {/* <Typography className={classes.buttonTextStyle}>{data.button || t('general.ourMenu')}</Typography> */}
                        <Typography className={classes.buttonTextStyle}>{t('general.ourMenu')}</Typography>
                    </Button>
                </Link>
            </Box>
        </>
    )
});

export default HomeMenu

const useStyles = makeStyles((theme) => ({
    titleStyle: {
        textTransform: "uppercase",
        color: theme.palette.primary.main,
        fontFamily: 'OregonLdoRegular !important',
    },
    imageContainerStyle: { 
        flex: 1, 
        padding: 10
    },
    imageStyle: {
        width: '100%', 
        objectFit: 'contain',
    },
    subTitleStyle: {
        // fontFamily: 'OregonLdoDemibold !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem !important',
        },
    },
    captionStyle: {
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.5rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.7rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem !important',
        },
    },
    roundedButtonStyle: {
        borderRadius: '20px !important',
        backgroundColor: theme.palette.button.main+'!important',
        '&:hover': {
            backgroundColor: theme.palette.primary.main+'!important',
        },
        [theme.breakpoints.up('sm')]: {
            padding: '10px !important',
        },
        paddingLeft:'5px !important',
        paddingRight:'5px !important',
    },
    buttonTextStyle: {
        textTransform: "capitalize",
        // fontFamily: 'OregonLdoRegular !important',
        [theme.breakpoints.up('xs')]: {
            fontSize: '.85rem !important',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem !important',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important',
        },
    },
}))